/*
 * Copyright Innov'ATM all rights reserved.
 * This software is the property of Innov'ATM and may not be used in any manner except under a
 * license agreement signed with Innov'ATM.
 */

import { FormattedMessage } from 'react-intl';
import { Button, styled } from '@mui/material';
import React, { useCallback, useMemo } from 'react';
import { GanttPeriodChangeDialogProps } from './GanttPeriodChangeDialogActions';
import { ButtonProps } from '@mui/material/Button';
import { theme } from '../../../../../theme';
import { DatePicker } from '../../../../../components/form/DatePicker/DatePicker';
import { subDays } from 'date-fns';
import { useCurrentUserClient } from '../../../../../contexts/AuthContext.selectors';

interface GanttPeriodChangeDialogContentProps {
    formFrom: number;
    setFormFrom: (from: number) => void;
    formTo: number;
    setFormTo: (to: number) => void;
    onClickSetTactical: () => void;
    today: Date;
}

export function GanttPeriodChangeDialogContent({
    formFrom,
    setFormFrom,
    formTo,
    setFormTo,
    onClickSetTactical,
    today,
}: GanttPeriodChangeDialogContentProps) {
    const clientHistoricalViewMaxDaysAgo = useCurrentUserClient()?.historicalViewMaxDaysAgo;
    const minDate = useMemo(
        () => subDays(today, clientHistoricalViewMaxDaysAgo | 30),
        [today, clientHistoricalViewMaxDaysAgo],
    );
    const maxDate = useMemo(() => subDays(today, 1), [today]);
    const defaultToValue = useMemo(() => (formTo > today.getTime() ? today.getTime() : formTo), [formTo, today]);
    const handleChangeFormFrom = useCallback(
        (date: Date | null) => {
            setFormFrom(date ? date.getTime() : 0);
        },
        [setFormFrom],
    );
    const handleChangeFormTo = useCallback(
        (date: Date | null) => {
            setFormTo(date ? date.getTime() : 0);
        },
        [setFormTo],
    );

    return (
        <>
            <Button {...SetTacticalButtonProps} onClick={onClickSetTactical}>
                <FormattedMessage id="dispatcher.ganttPeriod.dialogSetTactical" />
            </Button>
            <StyledMiddleText>
                <div>
                    <FormattedMessage id={'dispatcher.ganttPeriod.dialogMiddleTextTop'} />
                </div>
                <div>
                    <FormattedMessage id={'dispatcher.ganttPeriod.dialogMiddleTextBottom'} />
                </div>
            </StyledMiddleText>
            <StyledCalendarContainer>
                <StyledCalendarDatePicker
                    value={formFrom}
                    onChange={handleChangeFormFrom}
                    minDate={minDate}
                    maxDate={maxDate}
                    label={<FormattedMessage id={'from'} />}
                    required={true}
                />
                <StyledCalendarDatePicker
                    value={defaultToValue}
                    onChange={handleChangeFormTo}
                    minDate={minDate}
                    maxDate={maxDate}
                    label={<FormattedMessage id={'to'} />}
                    required={true}
                />
            </StyledCalendarContainer>
        </>
    );
}

const SetTacticalButtonProps: Partial<ButtonProps> = {
    ...GanttPeriodChangeDialogProps,
    sx: {
        ...GanttPeriodChangeDialogProps.sx,
        [`&.Mui-disabled`]: {
            backgroundColor: theme.palette.blue.lightest,
        },
        marginBottom: '15px',
    },
    variant: 'contained',
};

const StyledMiddleText = styled('div')`
    font-size: 18px;
    text-align: center;
    padding-bottom: 20px;
`;

const StyledCalendarContainer = styled('div')`
    display: flex;
    gap: 20px;
    & .MuiInputLabel-root {
        color: ${theme.palette.blue.darkest} !important;
    }
`;

const StyledCalendarDatePicker = styled(DatePicker)`
    & .MuiInputLabel-formControl {
        color: ${theme.palette.blue.darkest} !important;
    }
    & .MuiInputBase-input {
        color: ${theme.palette.blue.darkest} !important;
    }
    & .MuiButtonBase-root {
        color: ${theme.palette.blue.darkest} !important;
    }
    & .MuiInputBase-root {
        background-color: #f1f7fb !important;
    }
`;

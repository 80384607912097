import React, { memo, useEffect, useMemo, useRef, useState } from 'react';
import {
    StyledAirlineBox,
    StyledAirportBox,
    StyledBusDisplayContentArrContainer,
    StyledLeftSideContainer,
    StyledRightSideContainer,
} from './BusDisplayContentArr.style';
import { FlightInfoDto } from '../../../../backend/gen';
import {
    ArrivalInfoMode,
    checkIfAirlineLogoExists,
    cycleObject,
    generateArrivalInformation,
    LanguageMode,
} from '../busDisplay.utils';
import { useBoolean } from '../../../../hooks/useBoolean';

export const BusDisplayContentArr = memo(function BusDisplayContentArr({ flightInfo }: { flightInfo: FlightInfoDto }) {
    const airlineLogoExists = useBoolean(false);
    useEffect(() => {
        checkIfAirlineLogoExists(
            flightInfo.airline?.icao?.toLowerCase(),
            () => airlineLogoExists.setTrue(),
            () => airlineLogoExists.setFalse(),
        );
    }, [airlineLogoExists, flightInfo.airline?.icao]);

    const [currentLanguage, setCurrentLanguage] = useState<LanguageMode>(LanguageMode.English);
    const [infoToShow, setInfoToShow] = useState<ArrivalInfoMode>(ArrivalInfoMode.DEFAULT);
    const [cycleCount, setCycleCount] = useState(1);
    const keysToSkip = useMemo(() => {
        if (!flightInfo.controlled && flightInfo.oversizedBelt === null) {
            return [ArrivalInfoMode.CONTROLLED, ArrivalInfoMode.OVERSIZED];
        } else if (!flightInfo.controlled) {
            return [ArrivalInfoMode.CONTROLLED];
        } else if (flightInfo.oversizedBelt === null) {
            return [ArrivalInfoMode.OVERSIZED];
        }
        return [];
    }, [flightInfo.controlled, flightInfo.oversizedBelt]);
    const infoCycleTimerRef = useRef<NodeJS.Timer>();
    useEffect(() => {
        infoCycleTimerRef.current = setInterval(() => {
            setInfoToShow(
                cycleObject({
                    obj: ArrivalInfoMode,
                    current: infoToShow,
                    skipKeys: keysToSkip,
                }),
            );
            if (cycleCount === Object.keys(ArrivalInfoMode).length - keysToSkip.length) {
                setCurrentLanguage(cycleObject({ obj: LanguageMode, current: currentLanguage }));
                setCycleCount(1);
            } else {
                setCycleCount(cycleCount + 1);
            }
        }, 5000);
        return () => {
            clearInterval(infoCycleTimerRef.current);
        };
    }, [currentLanguage, cycleCount, flightInfo.controlled, flightInfo.oversizedBelt, infoToShow, keysToSkip]);

    return (
        <StyledBusDisplayContentArrContainer>
            <StyledLeftSideContainer>
                <StyledAirlineBox>
                    {airlineLogoExists.value && (
                        <img
                            src={`images/airlines/${flightInfo.airline?.icao?.toLowerCase()}.png`}
                            alt="airline-logo"
                        />
                    )}
                    <span>{flightInfo.flightNumber}</span>
                </StyledAirlineBox>
                <StyledAirportBox>{flightInfo.adep[currentLanguage]}</StyledAirportBox>
            </StyledLeftSideContainer>
            <StyledRightSideContainer>
                {generateArrivalInformation({
                    language: currentLanguage,
                    belt: flightInfo.belt,
                    oversizedBelt: infoToShow === ArrivalInfoMode.OVERSIZED && flightInfo.oversizedBelt,
                    controlled: infoToShow === ArrivalInfoMode.CONTROLLED && flightInfo.controlled,
                })}
            </StyledRightSideContainer>
        </StyledBusDisplayContentArrContainer>
    );
});

import React, { memo } from 'react';
import { Route, Routes } from 'react-router-dom';
import {
    ROUTE_BUS,
    ROUTE_DISPATCHER,
    ROUTE_DRIVER,
    ROUTE_DRIVER_MISSIONS,
    ROUTE_FIRSTCONNECTION,
    ROUTE_FORGOTPASSWORD,
    ROUTE_HELP,
    ROUTE_HOME,
    ROUTE_LOGIN,
    ROUTE_RESETPASSWORD,
    ROUTE_SETTINGS,
    ROUTE_SIGNUP,
    ROUTE_UNLOCKACCOUNT,
} from '../routes';
import { AuthContext } from '../contexts/AuthContext.selectors';
import { useSyncUserLocale } from '@innovatm/js-iam';
import { HelpPage } from './Help/HelpPage';
import { SignupFirstConnection } from './Signup/SignupFirstConnection';
import { SignupRoute } from './Signup/SignupRoute';
import { LoginRoute } from './Login/LoginRoute';
import { RequireAuth } from '../components/routing/RequireAuth/RequireAuth';
import { SettingsPage } from './Settings/SettingsPage/SettingsPage';
import { UserRedirectRoute } from './UserRedirectRoute';
import { DispatcherPage } from './Dispatcher/DispatcherPage/DispatcherPage';
import { DriverPage } from './Driver/DriverBusesPage/DriverPage';
import { BusDisplay } from './Bus/BusDisplayPage/BusDisplay';
import { DriverMissionsPage } from './Driver/DriverMissionsPage/DriverMissionsPage';
import { ForgotPasswordRoute } from './ForgotPassword/ForgotPasswordRoute';
import { ResetPasswordRoute } from './ResetPassword/ResetPasswordRoute';
import { UnlockAccountRoute } from './UnlockAccount/UnlockAccountRoute';

export const App = memo(function App() {
    // Update IntlContext locale as soon as the language selected by the user changes (server side)
    useSyncUserLocale(AuthContext);

    return (
        <>
            <Routes>
                <Route
                    path={ROUTE_HOME}
                    element={
                        <RequireAuth>
                            <UserRedirectRoute />
                        </RequireAuth>
                    }
                />
                <Route path={ROUTE_LOGIN} element={<LoginRoute />} />
                <Route path={ROUTE_SIGNUP} element={<SignupRoute />} />
                <Route path={ROUTE_FIRSTCONNECTION} element={<SignupFirstConnection />} />
                <Route path={ROUTE_FORGOTPASSWORD} element={<ForgotPasswordRoute />} />
                <Route path={ROUTE_RESETPASSWORD} element={<ResetPasswordRoute />} />
                <Route path={ROUTE_UNLOCKACCOUNT} element={<UnlockAccountRoute />} />
                <Route path={ROUTE_BUS} element={<BusDisplay />} />
                <Route
                    path={ROUTE_DISPATCHER}
                    element={
                        <RequireAuth>
                            <DispatcherPage />
                        </RequireAuth>
                    }
                />
                <Route
                    path={ROUTE_DRIVER}
                    element={
                        <RequireAuth>
                            <DriverPage />
                        </RequireAuth>
                    }
                />
                <Route
                    path={ROUTE_DRIVER_MISSIONS}
                    element={
                        <RequireAuth>
                            <DriverMissionsPage />
                        </RequireAuth>
                    }
                />
                <Route
                    path={ROUTE_SETTINGS}
                    element={
                        <RequireAuth>
                            <SettingsPage />
                        </RequireAuth>
                    }
                />
                <Route
                    path={ROUTE_HELP}
                    element={
                        <RequireAuth>
                            <HelpPage />
                        </RequireAuth>
                    }
                />
            </Routes>
        </>
    );
});

import React, { memo } from 'react';
import {
    Header,
    LeftComponentsHeader,
    MiddleComponentsHeader,
    RightComponentsHeader,
} from '../navigation/Header/Header';
import { Nav } from '../navigation/Nav/Nav';
import { Clock } from '../navigation/ToggleUTCLocal/Clock';
import { GanttPeriod } from '../../views/Dispatcher/DispatcherPage/GanttPeriod/GanttPeriod';
import { ExportMissionsHeaderButton } from '../../views/Dispatcher/DispatcherPage/ExportMissions/ExportMissionsHeaderButton';

export const HeaderComponent = memo(function HeaderComponent({
    isDispatcher,
    onClickClock,
    setGanttPeriodDialogOpen,
    from,
    to,
}: {
    isDispatcher: boolean;
    onClickClock?: () => void;
    setGanttPeriodDialogOpen?: () => void;
    from?: number;
    to?: number;
}) {
    return (
        <Header>
            <LeftComponentsHeader>
                <Nav />
                {isDispatcher && <ExportMissionsHeaderButton from={from} to={to} />}
            </LeftComponentsHeader>
            <MiddleComponentsHeader>
                {isDispatcher && (
                    <GanttPeriod setGanttPeriodDialogOpen={setGanttPeriodDialogOpen} from={from} to={to} />
                )}
            </MiddleComponentsHeader>
            <RightComponentsHeader>
                <Clock onClick={onClickClock} />
            </RightComponentsHeader>
        </Header>
    );
});

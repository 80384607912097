import { Box, styled } from '@mui/material';

export const StyledBusDisplayContainer = styled(Box)`
    width: 1920px;
    height: 480px;
    position: relative;
    border: 1px solid red;
    font-family: 'Helvetica Neue', sans-serif;
`;

export const StyledBusDisplayContentContainer = styled(Box)`
    height: 310px;
    background-color: #1d569f;
`;

export const StyledNoActiveFlight = styled(Box)`
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    font-size: 125px;
`;

import { Box, styled } from '@mui/material';
import * as React from 'react';
import { memo } from 'react';
import { MenuButton } from './MenuButton';

const StyledNav = styled(Box)`
    display: flex;
    flex-shrink: 0;
    align-items: center;
`;

export const Nav = memo(function Nav({ children }: { children?: React.ReactNode }) {
    return (
        <StyledNav>
            <MenuButton />

            {children}
        </StyledNav>
    );
});

import { Box, MenuItem, styled } from '@mui/material';

export const StyledToursMenuItem = styled(MenuItem)`
    display: flex;
    gap: 10px;
    justify-content: space-between;
    :hover {
        background-color: white !important;
        cursor: default !important;
    }
`;

export const StyledToursMenuItemControls = styled(Box)`
    display: flex;
    gap: 5px;
    div {
        padding: 0 2px;
        border-radius: 50%;
    }
    div:hover {
        cursor: pointer;
        background-color: rgba(0, 110, 187, 0.1);
    }
`;

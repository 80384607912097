import {
    Menu,
    menuClasses,
    menuItemClasses,
    MenuProps,
    paperClasses,
    styled,
    SvgIcon,
    svgIconClasses,
    Theme,
    ThemeProvider,
} from '@mui/material';
import React, { memo, useCallback, useState } from 'react';
import { lightTheme } from '../../../theme';
import { createContext, useContextSelector } from 'use-context-selector';
import useKeypress from '../../../hooks/useKeyPress';
import { SxProps } from '@mui/system';

const StyledMenu = styled(Menu)(({ theme }) => ({
    [`& .${paperClasses.root}`]: {
        background: theme.palette.background.paper,
        borderRadius: '20px',
        minWidth: '260px',
        height: 'fit-content',
        boxShadow: theme.shadows[24],
        [`& .${menuClasses.list}`]: {
            padding: '0px',
            fontFamily: 'Roboto',
            fontWeight: 'bold',
            fontSize: 18,
        },
        [`& .${menuItemClasses.root}`]: {
            paddingTop: theme.spacing(3),
            paddingBottom: theme.spacing(3),
            paddingLeft: theme.spacing(3),
            paddingRight: theme.spacing(3),
            height: '40px',
            fontSize: 18,
            [`& .${svgIconClasses.root}`]: {
                fontSize: 18,
            },
            '&:active': {
                backgroundColor: 'rgba(0,110,187,0.1)',
            },
        },
    },
}));

export const PopoverMenuIconArrowDown = memo(({ reversed }: { reversed: boolean }) => {
    return (
        <SvgIcon viewBox="0 0 16 16" sx={{ transform: `rotate(${reversed ? '180deg' : '0deg'})` }}>
            <g id="icon/Icon_ArrowDown" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g id="Icon_ArrowDown">
                    <polygon
                        id="Path"
                        fill="#B6D2E5"
                        points="3.13666667 4.26266667 8 9.126 12.8633333 4.26266667 14.364 5.76333333 8 12.1273333 1.636 5.76333333"
                    />
                    <polygon id="Path" points="0 0 16 0 16 16 0 16" />
                </g>
            </g>
        </SvgIcon>
    );
});

export const defaultMenuProps: Partial<MenuProps> = {
    anchorOrigin: {
        vertical: 'bottom',
        horizontal: 'right',
    },
    transformOrigin: {
        vertical: 'top',
        horizontal: 'right',
    },
};

export interface PopoverMenuInjectedProps {
    close: () => void;
    opened: boolean;
}

export const PopoverMenuContext = createContext<PopoverMenuInjectedProps>({
    close: () => {
        /**/
    },
    opened: false,
} as any);

export const usePopoverValue = <Key extends keyof PopoverMenuInjectedProps>(key: Key): PopoverMenuInjectedProps[Key] =>
    useContextSelector(PopoverMenuContext, state => state[key]);

export const PopoverMenu = memo(function PopoverMenu({
    renderButton,
    disableBackdropClose,
    autoClose,
    children,
    menuProps,
    menuStyles,
    onClose,
    disablePortal = true,
}: {
    renderButton: (
        props: { onClick: (e: React.MouseEvent<HTMLElement>) => void } & PopoverMenuInjectedProps,
    ) => React.ReactNode;
    autoClose?: boolean;
    disableBackdropClose?: boolean;
    children?: React.ReactNode | ((options: PopoverMenuInjectedProps) => React.ReactNode);
    menuProps?: Partial<MenuProps>;
    menuStyles?: Partial<SxProps<Theme>>;
    onClose?: () => void;
    disablePortal?: boolean;
}) {
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);

    const handleClick = useCallback((event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    }, []);

    const handleClose = useCallback(() => {
        setAnchorEl(null);
        onClose?.();
    }, [onClose]);

    useKeypress('Escape', open && handleClose);

    const state: PopoverMenuInjectedProps = { close: handleClose, opened: open };

    return (
        <PopoverMenuContext.Provider value={state}>
            {renderButton({ onClick: handleClick, ...state })}
            {children && (
                <ThemeProvider theme={lightTheme}>
                    <StyledMenu
                        disablePortal={disablePortal}
                        elevation={0}
                        {...defaultMenuProps}
                        anchorEl={anchorEl}
                        open={open}
                        onClose={disableBackdropClose ? undefined : handleClose}
                        onClick={autoClose ? handleClose : undefined}
                        {...menuProps}
                        sx={menuStyles}
                    >
                        {typeof children === 'function' ? children(state) : children}
                    </StyledMenu>
                </ThemeProvider>
            )}
        </PopoverMenuContext.Provider>
    );
});

import { IcaoIata } from '../../../../../../components/IcaoIata';
import { FlightSlotDto } from '../../../../../../backend/gen';
import { Box } from '@mui/material';
import React, { memo } from 'react';
import { ReactComponent as AirlineSeatReclineExtraIcon } from '../../../../../../assets/icons/icon-passenger-light-bus.svg';
import { ReactComponent as AircraftIcon } from '../../../../../../assets/icons/icon-plane-light-bus.svg';
import { getFlowColor } from '../../../../../../utils/data.utils';
import { StyledAircraftPaxInfoBox, StyledAircraftPaxInfoContainer } from './StripAircraftPaxInfos.style';

export const StripAircraftPaxInfos = memo(({ flight }: { flight: FlightSlotDto }) => {
    const iconFillColor = getFlowColor(flight.flow);

    return (
        <StyledAircraftPaxInfoContainer>
            <StyledAircraftPaxInfoBox>
                <AircraftIcon fill={iconFillColor} width={'45px'} height={'45px'} />
                <Box>{<IcaoIata {...flight?.acType} /> || '-'}</Box>
            </StyledAircraftPaxInfoBox>
            <StyledAircraftPaxInfoBox>
                <AirlineSeatReclineExtraIcon fill={iconFillColor} width={'45px'} height={'45px'} />
                <Box>{flight?.pax || '-'}</Box>
            </StyledAircraftPaxInfoBox>
        </StyledAircraftPaxInfoContainer>
    );
});

import { Box, styled } from '@mui/material';
import { theme } from '../../../../../../../theme';
import { ReactComponent as InfoIcon } from '../../../../../../../assets/icons/Icon-Information.svg';

export const StyledUnavailabilityPeriodInfo = styled(Box)`
    font-size: 12px;
    margin-top: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 5px;
    &.invalid {
        color: ${theme.palette.error.main};
        svg {
            stroke: ${theme.palette.error.main};
        }
    }
`;

export const StyledInfoIcon = styled(InfoIcon)`
    stroke: gray;
    width: 30px;
    height: 30px;
`;

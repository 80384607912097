import React, { memo, useCallback, useEffect } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { toast } from 'react-toastify';
import { errorToastConfig, successToastConfig } from '../../utils/constants';
import { ROUTE_FORGOTPASSWORD, ROUTE_LOGIN } from '../../routes';
import { LoginPageLink, ResetPasswordPage } from '@innovatm/js-iam';
import { AuthContext } from '../../contexts/AuthContext.selectors';
import { Box, styled } from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';

const logoImg = require('../../assets/images/InnovATM_AirportKeeper-Web-dark.png');

export const StyledRoot = styled(Box)`
    display: flex;
    overflow: hidden;
    height: 100%;
`;
export const StyledLeftSide = styled(Box)`
    width: 50%;
    background: #106ebb;
`;
export const StyledRightSide = styled(Box)`
    display: flex;
    justify-content: left;
    align-items: center;
    width: 50%;
    padding-left: 4%;
    background: #0c456c;
`;

const StyledLogo = styled(Box)`
    display: flex;
    align-items: center;
    height: 100%;
    justify-content: flex-end;
    img {
        margin: auto 12% auto auto;
        width: 370px;
    }
`;

export const ResetPasswordRoute = memo(function ResetPasswordRoute() {
    const intl = useIntl();
    const navigate = useNavigate();

    const { search } = useLocation();
    const token = React.useMemo(() => {
        const params = new URLSearchParams(search);
        return params.get('token');
    }, [search]);

    useEffect(() => {
        if (token === null) {
            navigate(ROUTE_LOGIN);
        }
    }, [token, navigate]);

    const handleResetPasswordErrors = useCallback(error => {
        toast.error(<FormattedMessage id={'pages.reset-password.error'} />, {
            ...errorToastConfig,
            toastId: 'authError',
        });
    }, []);

    const handleResetPasswordSuccess = useCallback(() => {
        toast.success(<FormattedMessage id={'pages.reset-password.success'} />, { ...successToastConfig });
        setTimeout(() => {
            navigate(ROUTE_LOGIN);
        }, 5000);
    }, [navigate]);

    return (
        <StyledRoot>
            <StyledLeftSide>
                <StyledLogo>
                    <img alt={'Logo'} src={logoImg} />
                </StyledLogo>
            </StyledLeftSide>
            <StyledRightSide>
                <ResetPasswordPage
                    authContext={AuthContext as any}
                    token={token || 'null'}
                    onSuccess={handleResetPasswordSuccess}
                    onError={handleResetPasswordErrors}
                    links={
                        <>
                            <LoginPageLink to={ROUTE_LOGIN}>
                                {intl.formatMessage({ id: 'pages.reset-password.back-to-login' })}
                            </LoginPageLink>
                            <LoginPageLink to={ROUTE_FORGOTPASSWORD}>
                                {intl.formatMessage({ id: 'pages.reset-password.links-forgotpassword' })}
                            </LoginPageLink>
                        </>
                    }
                />
            </StyledRightSide>
        </StyledRoot>
    );
});

/*
 * ******************************************************************************
 * Copyright Innov'ATM all rights reserved. This software is the property of
 * Innov'ATM and may not be used in any manner except under a license agreement
 * signed with Innov'ATM.
 * *******************************************************************************
 */
import { Box, styled } from '@mui/material';
import { memo } from 'react';
import { FormattedTime } from '../../../../components/time/FormattedTime';
import { ganttPxPerMs } from './gantt.constants';
import { HALF_HOUR } from '../../../../utils/constants';

export const GanttTimelineBox = styled(Box)`
    display: flex;
    width: fit-content;
    align-items: center;
    padding-top: 5px;
    padding-bottom: 2px;
    pointer-events: none;
    user-select: none;
    position: relative;
    z-index: 2;
`;
export const GanttTimelineStepBox = styled(Box)`
    display: flex;
    align-items: center;
    width: ${HALF_HOUR * ganttPxPerMs}px;
    flex-shrink: 0;
`;
export const GanttTimelineStepLineBox = styled(Box)`
    height: 0;
    flex: 1;
    border-top: 1px dashed ${({ theme }) => theme.palette.blue.lightest};
    margin-left: 5px;
    margin-right: 20px;
`;
export const GanttTimelineStepLabelBox = styled(Box)`
    font-variant-numeric: tabular-nums; // all number are the same width
    color: ${({ theme }) => theme.palette.blue.lightest};
    font-family: Roboto, serif;
    font-size: 12px;
    font-weight: bold;
    line-height: 14px;
    letter-spacing: 0;
    width: 16px;
    flex-shrink: 0;
    display: flex;
    justify-content: flex-end;
    overflow: visible;
`;

export const GanttTimeline = memo(({ from, to }: { from: number; to: number }) => {
    const halfHours = Array(Math.ceil((to - from) / HALF_HOUR) + 1)
        .fill(null)
        .map((_, i) => from + i * HALF_HOUR);

    return (
        <GanttTimelineBox>
            {halfHours.map((halfHour, i) => {
                const isLastStep = i === halfHours.length - 1;
                return (
                    <GanttTimelineStepBox key={halfHour} sx={isLastStep ? { width: 0 } : undefined}>
                        <GanttTimelineStepLabelBox>
                            <FormattedTime>{halfHour}</FormattedTime>
                        </GanttTimelineStepLabelBox>
                        {!isLastStep && <GanttTimelineStepLineBox />}
                    </GanttTimelineStepBox>
                );
            })}
        </GanttTimelineBox>
    );
});

import { Box, Typography } from '@mui/material';
import React, { memo, useCallback, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import ChevronLeft from '@mui/icons-material/ChevronLeft';
import { Document, Page as ReactPdfPage, pdfjs } from 'react-pdf';
import { Locales, useIntlValue } from '@innovatm/js-iam';
import ChevronRight from '@mui/icons-material/ChevronRight';
import Download from '@mui/icons-material/Download';
import { Nav } from '../../components/navigation/Nav/Nav';
import { PageTitle } from '../../components/navigation/Nav/PageTitle';
import { Header, LeftComponentsHeader } from '../../components/navigation/Header/Header';
import { RoundButton } from '../../components/Button/RoundButton/RoundButton';

// @workaround Explains why react-pdf doesn't work without /pdf.worker.js in the public folder
pdfjs.GlobalWorkerOptions.workerSrc = '/pdf.worker.js';

const pdfFilePaths: Record<Locales, string> = {
    [Locales.fr]: '/helpPdf/BUS_quick_guide_fr.pdf',
    [Locales.en]: '/helpPdf/BUS_quick_guide_eng.pdf',
};

export const HelpPage = memo(() => {
    const [currentPage, setCurrentPage] = useState(1);
    const [total, setTotal] = useState<number | null>(null);
    const locale = useIntlValue('locale');

    const pdfFilePath = pdfFilePaths[locale];

    const download = useCallback((filePath: string) => {
        window.open(filePath, '_blank');
    }, []);

    const onDocumentLoadSuccess = useCallback(({ numPages }: any) => setTotal(numPages), []);

    return (
        <Box display="flex" flexDirection="column" alignItems="center" width="100%" height="100%" overflow="hidden">
            <Header>
                <LeftComponentsHeader>
                    <Nav>
                        <PageTitle>
                            <FormattedMessage id="nav.title.help" />
                        </PageTitle>
                    </Nav>
                </LeftComponentsHeader>
            </Header>
            <Box display={'flex'} height={'100%'} width={'100%'} flexDirection={'column'} overflow={'auto'}>
                <Box display={'flex'} justifyContent={'center'} alignItems={'center'} m={2} flexShrink={0}>
                    <RoundButton
                        color="secondary"
                        size="medium"
                        variant="contained"
                        onClick={() => download(pdfFilePath)}
                        startIcon={<Download />}
                    >
                        <FormattedMessage id="help.download.quick-guide" />
                    </RoundButton>
                    &emsp;
                    <RoundButton
                        color="secondary"
                        size="medium"
                        variant="contained"
                        onClick={() => setCurrentPage(p => p - 1)}
                        disabled={currentPage === 1 || !total}
                        startIcon={<ChevronLeft />}
                    >
                        <FormattedMessage id={'help.previous'} />
                    </RoundButton>
                    &emsp;
                    <Typography variant={'body1'} color={'primary.contrastText'}>
                        <FormattedMessage id="help.pagination" values={{ current: currentPage, total: total || '-' }} />
                    </Typography>
                    &emsp;
                    <RoundButton
                        color="secondary"
                        size="medium"
                        variant="contained"
                        onClick={() => setCurrentPage(p => p + 1)}
                        disabled={currentPage === total || !total}
                        endIcon={<ChevronRight />}
                    >
                        <FormattedMessage id="help.next" />
                    </RoundButton>
                    &emsp;
                </Box>
                <Box display={'flex'} alignItems={'center'} justifyContent={'center'} m={1}>
                    <Document file={pdfFilePath} onLoadSuccess={onDocumentLoadSuccess}>
                        <ReactPdfPage pageNumber={currentPage} width={1024} />
                    </Document>
                </Box>
            </Box>
        </Box>
    );
});

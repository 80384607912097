import { Box, styled } from '@mui/material';
import { useInterval } from '../../../hooks/useInterval';
import React, { memo, useState } from 'react';
import { useCurrentUserClientTimezone } from '../../../contexts/AuthContext.selectors';
import { TimeDisplay } from './TimeDisplays/TimeDisplay';

export const Clock = memo(function Clock({ onClick }: { onClick?: () => void }) {
    const timezone = useCurrentUserClientTimezone();

    const [time, setTime] = useState(Date.now());
    useInterval(() => setTime(Date.now()), 60000);

    return (
        <StyledTimeContainer onClick={onClick}>
            <TimeDisplay time={time} blink={true} timezone={timezone} />
        </StyledTimeContainer>
    );
});

export const StyledTimeContainer = styled(Box)`
    height: 50px;
    min-width: 100px;
    border-radius: 25px;
    background-color: #18537b;
    color: #ffffff;
    padding: 7px 7px 6px;
    text-align: center;
    font-family: 'Saira Condensed', sans-serif;
    font-weight: bold;
    font-size: 18px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: ${({ onClick }) => (onClick ? 'pointer' : '')};
`;

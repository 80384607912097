import React, { memo, useEffect } from 'react';
import { useCurrentUser } from '../contexts/AuthContext.selectors';
import { useNavigate } from 'react-router-dom';
import { ROUTE_DISPATCHER, ROUTE_DRIVER } from '../routes';
import { ProfileType } from '../hooks/useAuthorizeProfile';

export const UserRedirectRoute = memo(function UserRedirectRoute() {
    const currentUser = useCurrentUser();
    const navigate = useNavigate();

    useEffect(() => {
        if (currentUser && currentUser.profile && currentUser.profile.name) {
            if (currentUser.profile.name === ProfileType.DISPATCHER) {
                navigate(ROUTE_DISPATCHER);
            } else if (currentUser.profile.name === ProfileType.DRIVER) {
                navigate(ROUTE_DRIVER);
            }
        }
    }, [currentUser, currentUser.profile.name, navigate]);

    return <div>Already logged in as unrecognized user</div>;
});

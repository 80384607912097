import { Box, styled } from '@mui/material';
import { headerHeightInPx } from '../../../utils/constants';
import { theme } from '../../../theme';

export const StyledDriverPageContainer = styled(Box)`
    height: calc(100% - ${headerHeightInPx}px);
    color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: 27px;
    font-weight: bold;
    gap: 50px;
`;

export const StyledBusListBox = styled(Box)`
    display: inline-grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 60px 100px;
`;

export const StyledBusBox = styled(Box)`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 175px;
    height: 140px;
    border-radius: 10px;
    background-color: #006ebb;
    cursor: pointer;
    font-size: 21px;
    &.unavailable {
        cursor: default;
        opacity: 0.4;
        border: 1px solid ${theme.palette.blue.lightest};
        background: repeating-linear-gradient(
            -45deg,
            ${theme.palette.blue.lightest},
            ${theme.palette.blue.lightest} 2px,
            #006ebb 2px,
            #006ebb 12px
        );
    }
`;

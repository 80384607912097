/**
 * ******************************************************************************
 * Copyright Innov'ATM all rights reserved. This software is the property of
 * Innov'ATM and may not be used in any manner except under a license agreement
 * signed with Innov'ATM.
 * *******************************************************************************
 */

export const ganttPxPerMs = 0.00006; // how many px is a millisecond
export const ganttTitleWidth = 195;
export const ganttContentPaddingLeft = 20;
export const ganttFlightRowHeight = 105;
export const ganttFlightRowMargin = 25;

import { autocompleteClasses, Box, formControlClasses, styled } from '@mui/material';

export const StyledCreateMissionFormContainer = styled(Box)`
    display: flex;
    flex-direction: column;
`;

export const StyledCreateMissionFormSubmitButtonContainer = styled(Box)`
    margin-top: 10px;
    text-align: center;
`;

const createMissionAutocompleteListboxStyles = {
    backgroundColor: 'white',
    color: 'black',
    [`& .${autocompleteClasses.noOptions}`]: {
        color: 'black',
    },
    [`& .${autocompleteClasses.listbox}`]: {
        padding: 0,
    },
    [`& .${autocompleteClasses.listbox} > .${autocompleteClasses.option}`]: {
        borderBottom: '1px solid #dedede',
    },
    [`& .${autocompleteClasses.listbox} > .${autocompleteClasses.option}:last-child`]: {
        borderBottom: 'none',
    },
    [`& .${autocompleteClasses.listbox} > .${autocompleteClasses.option}.${autocompleteClasses.focused}`]: {
        backgroundColor: '#dedede',
    },
};

export const createMissionAutocompleteComponentProps = {
    paper: {
        sx: createMissionAutocompleteListboxStyles,
    },
};

export const createMissionAutocompleteTextFieldStyles = {
    sx: {
        minHeight: 0,
        [`& .${formControlClasses.root}`]: {
            minHeight: 0,
        },
    },
};

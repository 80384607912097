import React, { memo } from 'react';
import { StyledCustomMenuButton, StyledDot } from './CustomMenuButton.style';

export const CustomMenuButton = memo(function CustomMenuButton({
    onClick,
}: {
    onClick: (e: React.MouseEvent<HTMLElement, MouseEvent>) => void;
}) {
    return (
        <StyledCustomMenuButton onClick={onClick}>
            <StyledDot />
            <StyledDot />
            <StyledDot />
        </StyledCustomMenuButton>
    );
});

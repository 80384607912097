import React, { memo } from 'react';
import {
    Dialog,
    DialogActions,
    DialogActionsProps,
    DialogContent,
    DialogContentProps,
    DialogTitle,
    DialogTitleProps,
} from '@mui/material';
import { DialogProps } from '@mui/material/Dialog';

export const CustomDialog = memo(function CustomDialog({
    open,
    title,
    content,
    actions,
    contentAndActionsCombined,
    customDialogProps,
    customTitleProps,
    customContentProps,
    customActionsProps,
}: {
    open: boolean;
    title?: React.ReactNode;
    content?: React.ReactNode;
    actions?: React.ReactNode;
    contentAndActionsCombined?: React.ReactNode;
    customDialogProps?: Partial<DialogProps>;
    customTitleProps?: Partial<DialogTitleProps>;
    customContentProps?: Partial<DialogContentProps>;
    customActionsProps?: Partial<DialogActionsProps>;
}) {
    // Can pass component containing both DialogContent & DialogActions at the same time using
    // contentAndActionsCombinedAsChildren prop instead of individual content & actions props
    // This allows state management INSIDE dialog component, this can be used to
    // prevent state persisting after closing dialog

    return (
        <Dialog open={open} {...customDialogProps}>
            {title && <DialogTitle {...customTitleProps}>{title}</DialogTitle>}
            {contentAndActionsCombined ? (
                contentAndActionsCombined
            ) : (
                <>
                    {content && <DialogContent {...customContentProps}>{content}</DialogContent>}
                    {actions && <DialogActions {...customActionsProps}>{actions}</DialogActions>}
                </>
            )}
        </Dialog>
    );
});

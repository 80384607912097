import React, { memo } from 'react';
import { FlightFlow } from '../../../../../../backend/gen';
import { FormattedTime } from '../../../../../../components/time/FormattedTime';
import { FormattedMessage } from 'react-intl';
import { getFlowColor } from '../../../../../../utils/data.utils';
import { StyledStripFlow, StyledStripTime, StyledStripTimeFlowContainer } from './StripTimeFlowInfo.style';

export const StripTimeFlow = memo(function StripTimeFlow({ time, flow }: { time: number; flow: FlightFlow }) {
    const flowColor = getFlowColor(flow);
    return (
        <StyledStripTimeFlowContainer color={flowColor}>
            <StyledStripTime>
                <FormattedTime>{time}</FormattedTime>
            </StyledStripTime>
            <StyledStripFlow>
                {flow === FlightFlow.ARR ? <FormattedMessage id="arrival" /> : <FormattedMessage id="departure" />}{' '}
            </StyledStripFlow>
        </StyledStripTimeFlowContainer>
    );
});

import React from 'react';
import { FormattedMessage } from 'react-intl';
import { ROUTE_LOGIN } from '../../routes';
import { Box, styled, Typography } from '@mui/material';
import { SignupPageLink } from '@innovatm/js-iam';

const StyledBox = styled(Box)`
    color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: auto;
`;

export const SignupSuccessPage = () => {
    return (
        <StyledBox>
            <Typography variant={'h1'} mb={1}>
                <FormattedMessage id="pages.signup-success-page.confirm-signup" />
            </Typography>
            <Typography variant={'body1'}>
                <FormattedMessage id="pages.signup-success-page.confirmation-email" />
            </Typography>
            <SignupPageLink to={ROUTE_LOGIN}>
                <FormattedMessage id="pages.signup-success-page.login" />
            </SignupPageLink>
        </StyledBox>
    );
};

import { Box, styled } from '@mui/material';

export const StyledStripBusHeaderContainer = styled(Box)`
    width: 360px;
    color: white;
    display: flex;
    justify-content: space-between;
    font-size: 18px;
`;

export const StyledStripBusHeaderFlightNumber = styled(Box)`
    font-weight: bold;
`;

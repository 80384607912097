import React, { memo, useCallback, useMemo } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { CodeMode, LanguageSetting, useMutationPatchUpdateUserOnFirstConnection } from '../../backend/gen';
import { Form, Formik } from 'formik';
import { SettingsForm } from '../../components/Settings/SettingsForm';
import { Box, styled, Typography } from '@mui/material';
import { FormattedMessage, useIntl } from 'react-intl';
import { ROUTE_LOGIN } from '../../routes';
import { toast } from 'react-toastify';
import { errorToastConfig, passwordPolicy, successToastConfig } from '../../utils/constants';
import jwtDecode from 'jwt-decode';
import { UserFormData } from '../../components/Settings/Settings';

const StyledSettingsContainer = styled(Box)`
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    overflow: scroll;
`;

const StyledSettingsForm = styled(Box)`
    color: white;
    margin-top: 100px;
    width: 950px;
`;

export const SignupFirstConnection = memo(function SignupFirstConnection() {
    const [searchParams] = useSearchParams();
    const navigate = useNavigate();
    const { formatMessage } = useIntl();
    const token = useMemo(() => searchParams.get('token') || '', [searchParams]);
    const decodedData = useMemo(
        () =>
            (token &&
                jwtDecode<{
                    profileName: string;
                    firstName: string;
                    lastName: string;
                    password: string;
                    sub: string;
                    application: string;
                    phone: string;
                    exp: number;
                    email: string;
                }>(token)) ||
            null,
        [token],
    );

    const { mutateAsync: setPasswordFirstConnection, isLoading } = useMutationPatchUpdateUserOnFirstConnection();

    const onSubmit = useCallback(
        values => {
            setPasswordFirstConnection({
                token,
                userSettingsUpdateDto: {
                    password: values.password,
                    settings: { language: values.language },
                },
            })
                .then(() => {
                    // Success
                    navigate(ROUTE_LOGIN);
                    toast(<FormattedMessage id={'settings.firstConnection.updateSuccessful'} />, successToastConfig);
                })
                .catch(() => {
                    // Fail
                    toast(<FormattedMessage id={'settings.firstConnection.updateFailed'} />, errorToastConfig);
                });
        },
        [navigate, setPasswordFirstConnection, token],
    );

    const validate = useCallback(
        values => {
            const errors: any = {};
            if (values.password.length && !values.password.match(passwordPolicy)) {
                errors.password = formatMessage({ id: 'settings.passwordPolicy' });
            }
            if (values.password.length && values.password !== values.confirmPassword) {
                errors.confirmPassword = formatMessage({ id: 'settings.passwordAreNotTheSame' });
            }
            return errors;
        },
        [formatMessage],
    );

    const initialValues: UserFormData = {
        password: '',
        confirmPassword: '',
        language: LanguageSetting.FRENCH,
        codeMode: CodeMode.IATA,
    };

    return (
        <>
            {token.length <= 0 || !decodedData ? (
                <StyledSettingsContainer alignItems={'center'} color={'white'}>
                    <Typography variant={'h1'}>
                        {formatMessage({ id: 'settings.firstConnection.invalidToken' })}
                    </Typography>
                </StyledSettingsContainer>
            ) : (
                <StyledSettingsContainer>
                    <StyledSettingsForm>
                        <Formik
                            enableReinitialize={true}
                            initialValues={initialValues}
                            onSubmit={onSubmit}
                            validate={validate}
                        >
                            <Form>
                                <SettingsForm prefilledUserData={decodedData} isLoading={isLoading} />
                            </Form>
                        </Formik>
                    </StyledSettingsForm>
                </StyledSettingsContainer>
            )}
        </>
    );
});

import { FormHelperText, Input, styled } from '@mui/material';

export const StyledDropoffFormInput = styled(Input)`
    width: 360px;
    height: 50px;
    border-radius: 5px;
    padding: 10px;
    background-color: #f1f7fb;
`;

export const StyledDropoffFormHelperText = styled(FormHelperText)`
    font-family: Roboto, sans-serif;
    font-size: 11px;
    font-weight: bold;
    letter-spacing: 0;
    color: #006ebb;
`;

import { Box, styled } from '@mui/material';
import { StyledStripSegment } from '../../StripBus.style';

export const StyledAircraftPaxInfoContainer = styled(StyledStripSegment)`
    width: 115px;
    height: 115px;
`;

export const StyledAircraftPaxInfoBox = styled(Box)`
    display: flex;
    align-items: center;
    gap: 5px;
    font-size: 18px;
`;

import * as React from 'react';
import { forwardRef, memo } from 'react';
import {
    FormControl,
    formControlClasses,
    FormControlProps,
    formHelperTextClasses,
    inputBaseClasses,
    inputClasses,
    InputLabel,
    inputLabelClasses,
    InputLabelProps,
    styled,
    TextField as MuiTextField,
    TextFieldProps,
} from '@mui/material';
import classNames from 'classnames';
import { FormattedMessage } from 'react-intl';

export const StyledInputLabel = styled(InputLabel)`
    width: 100%;
    display: inline-flex;
    justify-content: space-between;
`;

export const StyledTextFieldFormControl = styled(FormControl)`
    min-height: 100px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    color: ${({ theme }) => theme.palette.primary.contrastText};

    & .${inputLabelClasses.root} {
        height: 20px;
        transform-origin: top left;
        transform: initial;
        margin-bottom: 5px;
        position: relative;
        color: inherit;
        font-size: 14px;
        letter-spacing: 0;
        line-height: 18px;
        align-items: center;
        overflow: visible;

        &.${inputLabelClasses.focused} {
            font-weight: bold;
        }
    }

    & .${formControlClasses.root} {
        min-height: 75px;
        width: 100%;
    }
    &.disabled > .${inputLabelClasses.root} {
        opacity: 0.5;
    }
`;

export const StyledMuiTextField = styled(MuiTextField)`
    & > .${inputBaseClasses.root} {
        min-height: 50px;
        border-radius: 10px;
        background-color: ${({ theme }) => theme.palette.secondary.light};
        margin: 0;
        padding: 0;

        &:before,
        &:after {
            content: none;
        }
        input,
        textarea {
            padding: 15px;
            color: ${({ theme }) => theme.palette.primary.contrastText};
            border-radius: 10px;
        }
    }
    & .${formHelperTextClasses.root} {
        height: 20px;
        margin: 5px 0 0;
    }

    .disabled & .${inputClasses.root} {
        opacity: 0.5;
    }
`;

export const TextField = memo(
    forwardRef(function TextField(
        {
            label,
            name,
            labelProps,
            formControlProps,
            ...rest
        }: {
            label?: string | React.ReactNode;
            name: string;
            labelProps?: Partial<InputLabelProps>;
            formControlProps?: Partial<FormControlProps>;
        } & TextFieldProps,
        ref: React.RefObject<HTMLDivElement>,
    ) {
        return (
            <StyledTextFieldFormControl
                variant="standard"
                className={classNames({
                    disabled: rest.disabled,
                    multiline: rest.multiline,
                })}
                {...formControlProps}
            >
                {label && (
                    <StyledInputLabel shrink htmlFor={name} {...labelProps}>
                        <span>{label}</span>
                        {!rest.required && (
                            <span>
                                (<FormattedMessage id="form.optional" />)
                            </span>
                        )}
                    </StyledInputLabel>
                )}
                <StyledMuiTextField ref={ref} variant="filled" name={name} {...rest} />
            </StyledTextFieldFormControl>
        );
    }),
);

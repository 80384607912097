/*
 * Copyright Innov'ATM all rights reserved.
 * This software is the property of Innov'ATM and may not be used in any manner except under a
 * license agreement signed with Innov'ATM.
 */

import { useEffect, useMemo, useState } from 'react';
import { timestampToPx } from '../DispatcherPage.utils';
import { ganttContentPaddingLeft, ganttPxPerMs, ganttTitleWidth } from '../Gantt/gantt.constants';

export const useCurrentTime = (from: number) => {
    const [now, setNow] = useState(Date.now());

    useEffect(() => {
        const intervalId = setInterval(() => setNow(Date.now()), 1000);
        return () => clearInterval(intervalId);
    }, []);

    const currentTimeInPx = useMemo(
        () => timestampToPx(now, from, ganttPxPerMs, ganttTitleWidth, ganttContentPaddingLeft),
        [now, from],
    );

    return { now, currentTimeInPx };
};

/*
 * Copyright Innov'ATM all rights reserved.
 * This software is the property of Innov'ATM and may not be used in any manner except under a
 * license agreement signed with Innov'ATM.
 */

import { BusVacationDto } from '../../../../../backend/gen';
import { bounded } from '../gantt.utils';
import { ganttFlightRowHeight, ganttPxPerMs } from '../gantt.constants';
import { useCallback, useMemo } from 'react';
import { Box, styled } from '@mui/material';
import { theme } from '../../../../../theme';
import { ReactComponent as DriverIcon } from '../../../../../assets/icons/icon-driver.svg';
import { useSetUi } from '../../../../../contexts/UiContext';

export function BusVacationGanttRow({
    vacation,
    ganttFrom,
    ganttTo,
    onClickOpenDefineVacationDialog,
}: {
    vacation: BusVacationDto;
    ganttFrom: number;
    ganttTo: number;
    onClickOpenDefineVacationDialog: () => void;
}) {
    const setSelectedVacationId = useSetUi('selectedVacationId');

    const onClickHandleSelectVacation = useCallback(() => {
        setSelectedVacationId(vacation.id);
        onClickOpenDefineVacationDialog();
    }, [setSelectedVacationId, vacation.id, onClickOpenDefineVacationDialog]);

    const vacationStartTimeInPxFromLeft = useMemo(() => {
        return (bounded(vacation.from, ganttFrom, ganttTo) - ganttFrom) * ganttPxPerMs;
    }, [vacation.from, ganttFrom, ganttTo]);
    const vacationEndTimeInPxFromLeft = useMemo(() => {
        return (bounded(vacation.to, ganttFrom, ganttTo) - ganttFrom) * ganttPxPerMs;
    }, [vacation.to, ganttFrom, ganttTo]);
    const vacationWidthInPx = useMemo(() => {
        return vacationEndTimeInPxFromLeft - vacationStartTimeInPxFromLeft;
    }, [vacationEndTimeInPxFromLeft, vacationStartTimeInPxFromLeft]);

    return (
        <StyledBusVacationGanttRowContainer onClick={onClickHandleSelectVacation}>
            <StyledBusVacationGanttRow width={`${vacationWidthInPx}px`} left={`${vacationStartTimeInPxFromLeft}px`} />
            <StyledBusVacationDriverName id={'vacationDriver'} left={`${vacationStartTimeInPxFromLeft}px`}>
                <StyledDriverIcon />
                {vacation.driver}
            </StyledBusVacationDriverName>
            <StyledBusVacationTopBorder
                id={'vacationBorder'}
                width={`${vacationWidthInPx}px`}
                left={`${vacationStartTimeInPxFromLeft}px`}
            />
        </StyledBusVacationGanttRowContainer>
    );
}

const StyledBusVacationGanttRowContainer = styled('span')`
    #vacationDriver:hover + #vacationBorder {
        display: block;
    }
`;

const StyledBusVacationGanttRow = styled(Box)`
    background-color: ${theme.palette.primary.main};
    min-height: ${ganttFlightRowHeight}px;
    position: absolute;
    top: -31px;
    height: calc(100% + 66px);
    z-index: 1;
    pointer-events: none;
`;

const StyledBusVacationDriverName = styled(Box)`
    position: absolute;
    z-index: 6;
    top: 0;
    display: flex;
    align-items: end;
    font-size: 12px;
    height: 30px;
    border-left: 1px solid #d5dde3;
    cursor: pointer;
`;

const StyledDriverIcon = styled(DriverIcon)`
    width: 20px;
    height: 20px;
`;

const StyledBusVacationTopBorder = styled(Box)`
    position: absolute;
    top: -31px;
    height: 5px;
    background-color: #d5dde3;
    z-index: 6;
    display: none;
`;

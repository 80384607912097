/*
 * Copyright Innov'ATM all rights reserved.
 * This software is the property of Innov'ATM and may not be used in any manner except under a
 * license agreement signed with Innov'ATM.
 */

import { endOfDay, startOfDay } from 'date-fns';
import { useState } from 'react';
import { useBoolean } from '../../../../hooks/useBoolean';
import { useSetUi } from '../../../../contexts/UiContext';

export const useGanttPeriod = () => {
    const tacticalFrom = startOfDay(Date.now()).getTime() - 3600 * 1000 * 2;
    const tacticalTo = endOfDay(Date.now() + 3600 * 1000 * 24).getTime();

    const setIsTactical = useSetUi('isTacticalView');

    const [from, setFrom] = useState(tacticalFrom);
    const [to, setTo] = useState(tacticalTo);
    const ganttPeriodDialogIsOpen = useBoolean(false);

    const setTactical = () => {
        setFrom(tacticalFrom);
        setTo(tacticalTo);
        setIsTactical(true);
    };

    const setCustom = (from: number, to: number) => {
        setFrom(from);
        setTo(to);
        setIsTactical(false);
    };

    return {
        from,
        to,
        setTactical,
        setCustom,
        ganttPeriodDialogIsOpen,
    };
};

/*
 * Copyright Innov'ATM all rights reserved.
 * This software is the property of Innov'ATM and may not be used in any manner except under a
 * license agreement signed with Innov'ATM.
 */

import { BusVacationDto, DispatcherBusDto } from '../../../../../backend/gen';
import React, { useState } from 'react';
import { BusDefineVacationDialogContent } from './BusDefineVacationDialogContent';
import { PeriodType } from '../BusUnavailabilityPeriodDialog/BusUnavailabilityPeriodDialog';
import { BusDefineVacationDialogActions } from './BusDefineVacationDialogActions';
import { Box, styled } from '@mui/material';
import { theme } from '../../../../../theme';
import { FormattedMessage } from 'react-intl';
import { ReactComponent as InfoIcon } from '../../../../../assets/icons/Icon-Information.svg';

export function BusDefineVacationDialogContentAndActionsContainer({
    bus,
    closeDialog,
    vacation,
}: {
    bus: DispatcherBusDto;
    closeDialog: () => void;
    vacation?: BusVacationDto;
}) {
    const [period, setPeriod] = useState<PeriodType>({
        start: vacation?.from || null,
        end: vacation?.to || null,
    });
    const [name, setName] = useState<string>(vacation?.driver || '');
    const [comment, setComment] = useState<string>(vacation?.comment || '');

    const fieldsMissing = !period.start || !period.end || !name;

    return (
        <>
            <BusDefineVacationDialogContent
                period={period}
                setPeriod={setPeriod}
                name={name}
                setName={setName}
                comment={comment}
                setComment={setComment}
            />
            <BusDefineVacationDialogActions
                closeDialog={closeDialog}
                bus={bus}
                period={period}
                name={name}
                comment={comment}
                vacation={vacation}
            />
            {fieldsMissing && (
                <StyledFormErrorInfo className="invalid">
                    <StyledInfoIcon />
                    <FormattedMessage id={'dispatcher.busDefineVacation.formIncomplete'} />
                </StyledFormErrorInfo>
            )}
        </>
    );
}

const StyledFormErrorInfo = styled(Box)`
    font-size: 12px;
    margin-top: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 5px;
    &.invalid {
        color: ${theme.palette.error.main};
        svg {
            stroke: ${theme.palette.error.main};
        }
    }
`;

const StyledInfoIcon = styled(InfoIcon)`
    stroke: gray;
    width: 30px;
    height: 30px;
`;

import React, { memo } from 'react';
import { FlightFlow } from '../../../../backend/gen';
import { StyledBusDisplayFooterContainer } from './BusDisplayFooter.style';

export const BusDisplayFooter = memo(function BusDisplayFooter({ flow }: { flow?: FlightFlow }) {
    return (
        <StyledBusDisplayFooterContainer>
            {flow && (
                <span>
                    {flow === FlightFlow.ARR ? 'bienvenue. willkommen. welcome' : 'à bientôt. bis bald. see you soon.'}
                </span>
            )}
        </StyledBusDisplayFooterContainer>
    );
});

import React, { memo } from 'react';
import { ganttPxPerMs } from '../gantt.constants';
import { bounded } from '../gantt.utils';
import { StyledBusUnavailabilityPeriodGanttRow } from './BusUnavailabilityPeriodGanttRow.style';

export const BusUnavailabilityPeriodGanttRow = memo(function BusUnavailabilityPeriodGanttRow({
    unavailabilityStartTime,
    unavailabilityEndTime,
    ganttFrom,
    ganttTo,
}: {
    unavailabilityStartTime: number;
    unavailabilityEndTime: number;
    ganttFrom: number;
    ganttTo: number;
}) {
    const unavailabilityPeriodStartTimeInPxFromLeft =
        (bounded(unavailabilityStartTime, ganttFrom, ganttTo) - ganttFrom) * ganttPxPerMs;
    const unavailabilityPeriodEndTimeInPxFromLeft =
        (bounded(unavailabilityEndTime, ganttFrom, ganttTo) - ganttFrom) * ganttPxPerMs;
    const unavailabilityPeriodWidthInPx =
        unavailabilityPeriodEndTimeInPxFromLeft - unavailabilityPeriodStartTimeInPxFromLeft;

    return (
        <StyledBusUnavailabilityPeriodGanttRow
            width={`${unavailabilityPeriodWidthInPx}px`}
            left={`${unavailabilityPeriodStartTimeInPxFromLeft}px`}
        />
    );
});

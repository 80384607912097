import { Box, styled } from '@mui/material';

export const StyledBlink = styled('span')`
    animation: blink 2s infinite;
`;

export const StyledBusDisplayHeaderContainer = styled(Box)`
    display: flex;
    justify-content: space-between;
    padding: 0 60px;
    background-color: white;
    height: 100px;
`;

export const StyledBusDisplayTimeContainer = styled(Box)`
    display: flex;
    align-items: center;
    color: #215ca3;
    font-size: 55px;
`;

export const StyledEapLogoContainer = styled(Box)`
    display: flex;
    align-items: center;
`;

/*
 * Copyright Innov'ATM all rights reserved.
 * This software is the property of Innov'ATM and may not be used in any manner except under a
 * license agreement signed with Innov'ATM.
 */

import React, { memo, useMemo } from 'react';
import { FlightFlow, MissionStepDto } from '../../../../../backend/gen';
import { Locales, useIntlValue } from '@innovatm/js-iam';
import {
    StyledArrStepCompleteIcon,
    StyledDepStepCompleteIcon,
    StyledStepCompletionTime,
    StyledStepContainer,
    StyledStepPendingCheckbox,
    StyledToursContainer,
} from './StepsPreview.style';
import { getFlowColor } from '../../../../../utils/data.utils';
import { FormattedTime } from '../../../../../components/time/FormattedTime';

export const StepsPreview = memo(function StepsPreview({
    flow,
    steps,
    nbTours,
}: {
    flow: FlightFlow;
    steps: MissionStepDto[];
    nbTours: number;
}) {
    const locale = useIntlValue('locale');
    const flowColor = getFlowColor(flow);
    const stepCompleteIcon = flow === FlightFlow.ARR ? <StyledArrStepCompleteIcon /> : <StyledDepStepCompleteIcon />;
    const tours = splitStepsIntoTours(steps, nbTours);
    const stepsPerTour = Math.ceil(steps.length / nbTours);

    const currentStep = useMemo(() => {
        const indexOfFirstIncompleteStep = steps.findIndex(step => step.done === false);
        const tourIndex = Math.floor(indexOfFirstIncompleteStep / stepsPerTour);
        const stepIndexInTour = indexOfFirstIncompleteStep % stepsPerTour;
        return tourIndex * stepsPerTour + (stepIndexInTour === 0 ? 0 : stepIndexInTour - 1);
    }, [steps, stepsPerTour]);

    return (
        <StyledToursContainer>
            {tours.map((tour, tourNumber) => {
                return (
                    <div key={tourNumber}>
                        {tour.map((step, stepNumberInTour) => {
                            const stepTime = step.done ? step.actualDoneTime : step.estimatedDoneTime;
                            const stepTextColor =
                                tourNumber * stepsPerTour + stepNumberInTour === currentStep && step.done
                                    ? flowColor
                                    : '';
                            return (
                                <StyledStepContainer
                                    key={stepNumberInTour}
                                    stepdone={step.done}
                                    steptextcolor={stepTextColor}
                                >
                                    {step.done ? stepCompleteIcon : <StyledStepPendingCheckbox />}
                                    <StyledStepCompletionTime>
                                        {stepTime ? (
                                            <FormattedTime>{stepTime}</FormattedTime>
                                        ) : (
                                            <span
                                                style={{
                                                    padding: '0 7px 0 6px',
                                                }}
                                            >
                                                --:--
                                            </span>
                                        )}
                                    </StyledStepCompletionTime>
                                    <span>{locale === Locales.en ? step.descriptionEng : step.descriptionFr}</span>
                                </StyledStepContainer>
                            );
                        })}
                    </div>
                );
            })}
        </StyledToursContainer>
    );
});

function splitStepsIntoTours(steps: MissionStepDto[], nbTours: number): MissionStepDto[][] {
    const stepsPerTour = Math.ceil(steps.length / nbTours);
    const tours: MissionStepDto[][] = [];

    for (let i = 0; i < nbTours; i++) {
        const start = i * stepsPerTour;
        const end = start + stepsPerTour;
        tours.push(steps.slice(start, end));
    }

    return tours;
}

/* eslint-disable @typescript-eslint/no-unused-vars */
/**
 * Copyright Innov'ATM all rights reserved. This software is the property of Innov'ATM and may not be used in any
 * manner except under a license agreement signed with Innov'ATM.
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { DispatcherGanttDto } from '../models';
import { DispatcherMissionSlotDto } from '../models';
import { UnavailabilityUpdateDto } from '../models';
import { VacationUpdateDto } from '../models';
import { Configuration, ConfigurationParameters } from '../configuration';
import { BASE_PATH, BaseAPI, RequestArgs } from '../base';
import { AxiosInstance, AxiosPromise, AxiosResponse, AxiosError, AxiosRequestConfig } from 'axios';
import { useQuery, UseMutationOptions, useMutation, UseQueryOptions } from 'react-query';
import {
    assertParamExists,
    createRequestFunction,
    DUMMY_BASE_URL,
    serializeDataIfNeeded,
    setSearchParams,
    toPathString,
    globalAxios,
    setBearerAuthToObject,
} from '../common';
import { useAppAuthValue } from '../../utils';
export const DispatcherRestControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        allocateBus: async (flight: string, bus: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'flight' is not null or undefined
            assertParamExists('allocateBus', 'flight', flight);
            // verify required parameter 'bus' is not null or undefined
            assertParamExists('allocateBus', 'bus', bus);
            const localVarPath = `/dispatcher/allocate`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = {
                method: 'POST',
                ...baseOptions,
                ...options,
            };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration);
            if (flight !== undefined) {
                localVarQueryParameter['flight'] = flight;
            }
            if (bus !== undefined) {
                localVarQueryParameter['bus'] = bus;
            }

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            const headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        considerFlightForBusAllocation: async (flight: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'flight' is not null or undefined
            assertParamExists('considerFlightForBusAllocation', 'flight', flight);
            const localVarPath = `/dispatcher/consider`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = {
                method: 'POST',
                ...baseOptions,
                ...options,
            };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration);
            if (flight !== undefined) {
                localVarQueryParameter['flight'] = flight;
            }

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            const headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        declareBusUnavailability: async (
            unavailabilityUpdateDto: UnavailabilityUpdateDto,
            options: any = {},
        ): Promise<RequestArgs> => {
            // verify required parameter 'unavailabilityUpdateDto' is not null or undefined
            assertParamExists('declareBusUnavailability', 'unavailabilityUpdateDto', unavailabilityUpdateDto);
            const localVarPath = `/dispatcher/unavailability`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = {
                method: 'POST',
                ...baseOptions,
                ...options,
            };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration);

            localVarHeaderParameter['Content-Type'] = 'application/json';
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            const headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };
            localVarRequestOptions.data = serializeDataIfNeeded(
                unavailabilityUpdateDto,
                localVarRequestOptions,
                configuration,
            );
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        declareBusVacation: async (vacationUpdateDto: VacationUpdateDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'vacationUpdateDto' is not null or undefined
            assertParamExists('declareBusVacation', 'vacationUpdateDto', vacationUpdateDto);
            const localVarPath = `/dispatcher/vacation`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = {
                method: 'POST',
                ...baseOptions,
                ...options,
            };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration);

            localVarHeaderParameter['Content-Type'] = 'application/json';
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            const headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };
            localVarRequestOptions.data = serializeDataIfNeeded(
                vacationUpdateDto,
                localVarRequestOptions,
                configuration,
            );
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        discardFlightFromBusAllocation: async (flight: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'flight' is not null or undefined
            assertParamExists('discardFlightFromBusAllocation', 'flight', flight);
            const localVarPath = `/dispatcher/discard`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = {
                method: 'DELETE',
                ...baseOptions,
                ...options,
            };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration);
            if (flight !== undefined) {
                localVarQueryParameter['flight'] = flight;
            }

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            const headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        duplicateToBus: async (mission: string, bus: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'mission' is not null or undefined
            assertParamExists('duplicateToBus', 'mission', mission);
            // verify required parameter 'bus' is not null or undefined
            assertParamExists('duplicateToBus', 'bus', bus);
            const localVarPath = `/dispatcher/duplicate`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = {
                method: 'POST',
                ...baseOptions,
                ...options,
            };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration);
            if (mission !== undefined) {
                localVarQueryParameter['mission'] = mission;
            }
            if (bus !== undefined) {
                localVarQueryParameter['bus'] = bus;
            }

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            const headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        exportMissions: async (from: number, to: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'from' is not null or undefined
            assertParamExists('exportMissions', 'from', from);
            // verify required parameter 'to' is not null or undefined
            assertParamExists('exportMissions', 'to', to);
            const localVarPath = `/dispatcher/export`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = {
                method: 'GET',
                ...baseOptions,
                ...options,
            };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration);
            if (from !== undefined) {
                localVarQueryParameter['from'] = from;
            }
            if (to !== undefined) {
                localVarQueryParameter['to'] = to;
            }

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            const headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        getAllMissions: async (from?: number, to?: number, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/dispatcher/missions`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = {
                method: 'GET',
                ...baseOptions,
                ...options,
            };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration);
            if (from !== undefined) {
                localVarQueryParameter['from'] = from;
            }
            if (to !== undefined) {
                localVarQueryParameter['to'] = to;
            }

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            const headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        reallocateBus: async (mission: string, bus: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'mission' is not null or undefined
            assertParamExists('reallocateBus', 'mission', mission);
            // verify required parameter 'bus' is not null or undefined
            assertParamExists('reallocateBus', 'bus', bus);
            const localVarPath = `/dispatcher/reallocate`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = {
                method: 'POST',
                ...baseOptions,
                ...options,
            };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration);
            if (mission !== undefined) {
                localVarQueryParameter['mission'] = mission;
            }
            if (bus !== undefined) {
                localVarQueryParameter['bus'] = bus;
            }

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            const headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        removeBusUnavailability: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('removeBusUnavailability', 'id', id);
            const localVarPath = `/dispatcher/unavailability`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = {
                method: 'DELETE',
                ...baseOptions,
                ...options,
            };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration);
            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            const headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        removeBusVacation: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('removeBusVacation', 'id', id);
            const localVarPath = `/dispatcher/vacation`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = {
                method: 'DELETE',
                ...baseOptions,
                ...options,
            };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration);
            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            const headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        unallocateBus: async (mission: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'mission' is not null or undefined
            assertParamExists('unallocateBus', 'mission', mission);
            const localVarPath = `/dispatcher/unallocate`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = {
                method: 'DELETE',
                ...baseOptions,
                ...options,
            };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration);
            if (mission !== undefined) {
                localVarQueryParameter['mission'] = mission;
            }

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            const headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        updateDropOffLocation: async (
            flight: string,
            dropOffLocation: string,
            options: any = {},
        ): Promise<RequestArgs> => {
            // verify required parameter 'flight' is not null or undefined
            assertParamExists('updateDropOffLocation', 'flight', flight);
            // verify required parameter 'dropOffLocation' is not null or undefined
            assertParamExists('updateDropOffLocation', 'dropOffLocation', dropOffLocation);
            const localVarPath = `/dispatcher/dropOffLocation`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = {
                method: 'POST',
                ...baseOptions,
                ...options,
            };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration);
            if (flight !== undefined) {
                localVarQueryParameter['flight'] = flight;
            }
            if (dropOffLocation !== undefined) {
                localVarQueryParameter['dropOffLocation'] = dropOffLocation;
            }

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            const headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        updateNumberOfTours: async (mission: string, tours: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'mission' is not null or undefined
            assertParamExists('updateNumberOfTours', 'mission', mission);
            // verify required parameter 'tours' is not null or undefined
            assertParamExists('updateNumberOfTours', 'tours', tours);
            const localVarPath = `/dispatcher/nbtours`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = {
                method: 'PATCH',
                ...baseOptions,
                ...options,
            };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration);
            if (mission !== undefined) {
                localVarQueryParameter['mission'] = mission;
            }
            if (tours !== undefined) {
                localVarQueryParameter['tours'] = tours;
            }

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            const headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    };
};
export const DispatcherRestControllerApiFp = function (configuration?: Configuration) {
    const localVarAxiosParamCreator = DispatcherRestControllerApiAxiosParamCreator(configuration);
    return {
        /**
         *
         * @param {string} flight
         * @param {string} bus
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async allocateBus(
            flight: string,
            bus: string,
            options?: any,
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DispatcherGanttDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.allocateBus(flight, bus, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {string} flight
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async considerFlightForBusAllocation(
            flight: string,
            options?: any,
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DispatcherGanttDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.considerFlightForBusAllocation(flight, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {UnavailabilityUpdateDto} unavailabilityUpdateDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async declareBusUnavailability(
            unavailabilityUpdateDto: UnavailabilityUpdateDto,
            options?: any,
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DispatcherGanttDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.declareBusUnavailability(
                unavailabilityUpdateDto,
                options,
            );
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {VacationUpdateDto} vacationUpdateDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async declareBusVacation(
            vacationUpdateDto: VacationUpdateDto,
            options?: any,
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DispatcherGanttDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.declareBusVacation(vacationUpdateDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {string} flight
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async discardFlightFromBusAllocation(
            flight: string,
            options?: any,
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DispatcherGanttDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.discardFlightFromBusAllocation(flight, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {string} mission
         * @param {string} bus
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async duplicateToBus(
            mission: string,
            bus: string,
            options?: any,
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DispatcherGanttDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.duplicateToBus(mission, bus, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {number} from
         * @param {number} to
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async exportMissions(
            from: number,
            to: number,
            options?: any,
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.exportMissions(from, to, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {number} [from]
         * @param {number} [to]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAllMissions(
            from?: number,
            to?: number,
            options?: any,
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DispatcherGanttDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAllMissions(from, to, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {string} mission
         * @param {string} bus
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async reallocateBus(
            mission: string,
            bus: string,
            options?: any,
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DispatcherGanttDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.reallocateBus(mission, bus, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {number} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async removeBusUnavailability(
            id: number,
            options?: any,
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DispatcherGanttDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.removeBusUnavailability(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {number} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async removeBusVacation(
            id: number,
            options?: any,
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DispatcherGanttDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.removeBusVacation(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {string} mission
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async unallocateBus(
            mission: string,
            options?: any,
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DispatcherGanttDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.unallocateBus(mission, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {string} flight
         * @param {string} dropOffLocation
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateDropOffLocation(
            flight: string,
            dropOffLocation: string,
            options?: any,
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DispatcherGanttDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateDropOffLocation(
                flight,
                dropOffLocation,
                options,
            );
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {string} mission
         * @param {number} tours
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateNumberOfTours(
            mission: string,
            tours: number,
            options?: any,
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DispatcherMissionSlotDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateNumberOfTours(mission, tours, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    };
};

export const DispatcherRestControllerApiFactory = function (
    configuration?: Configuration,
    basePath?: string,
    axios?: AxiosInstance,
) {
    const localVarFp = DispatcherRestControllerApiFp(configuration);
    return {
        allocateBus(flight: string, bus: string, options?: any): AxiosPromise<DispatcherGanttDto> {
            return localVarFp.allocateBus(flight, bus, options).then(request => request(axios, basePath));
        },
        considerFlightForBusAllocation(flight: string, options?: any): AxiosPromise<DispatcherGanttDto> {
            return localVarFp.considerFlightForBusAllocation(flight, options).then(request => request(axios, basePath));
        },
        declareBusUnavailability(
            unavailabilityUpdateDto: UnavailabilityUpdateDto,
            options?: any,
        ): AxiosPromise<DispatcherGanttDto> {
            return localVarFp
                .declareBusUnavailability(unavailabilityUpdateDto, options)
                .then(request => request(axios, basePath));
        },
        declareBusVacation(vacationUpdateDto: VacationUpdateDto, options?: any): AxiosPromise<DispatcherGanttDto> {
            return localVarFp.declareBusVacation(vacationUpdateDto, options).then(request => request(axios, basePath));
        },
        discardFlightFromBusAllocation(flight: string, options?: any): AxiosPromise<DispatcherGanttDto> {
            return localVarFp.discardFlightFromBusAllocation(flight, options).then(request => request(axios, basePath));
        },
        duplicateToBus(mission: string, bus: string, options?: any): AxiosPromise<DispatcherGanttDto> {
            return localVarFp.duplicateToBus(mission, bus, options).then(request => request(axios, basePath));
        },
        exportMissions(from: number, to: number, options?: any): AxiosPromise<any> {
            return localVarFp.exportMissions(from, to, options).then(request => request(axios, basePath));
        },
        getAllMissions(from?: number, to?: number, options?: any): AxiosPromise<DispatcherGanttDto> {
            return localVarFp.getAllMissions(from, to, options).then(request => request(axios, basePath));
        },
        reallocateBus(mission: string, bus: string, options?: any): AxiosPromise<DispatcherGanttDto> {
            return localVarFp.reallocateBus(mission, bus, options).then(request => request(axios, basePath));
        },
        removeBusUnavailability(id: number, options?: any): AxiosPromise<DispatcherGanttDto> {
            return localVarFp.removeBusUnavailability(id, options).then(request => request(axios, basePath));
        },
        removeBusVacation(id: number, options?: any): AxiosPromise<DispatcherGanttDto> {
            return localVarFp.removeBusVacation(id, options).then(request => request(axios, basePath));
        },
        unallocateBus(mission: string, options?: any): AxiosPromise<DispatcherGanttDto> {
            return localVarFp.unallocateBus(mission, options).then(request => request(axios, basePath));
        },
        updateDropOffLocation(
            flight: string,
            dropOffLocation: string,
            options?: any,
        ): AxiosPromise<DispatcherGanttDto> {
            return localVarFp
                .updateDropOffLocation(flight, dropOffLocation, options)
                .then(request => request(axios, basePath));
        },
        updateNumberOfTours(mission: string, tours: number, options?: any): AxiosPromise<DispatcherMissionSlotDto> {
            return localVarFp.updateNumberOfTours(mission, tours, options).then(request => request(axios, basePath));
        },
    };
};
export interface DispatcherRestControllerApiAllocateBusRequest {
    readonly flight: string;

    readonly bus: string;
}

export interface DispatcherRestControllerApiConsiderFlightForBusAllocationRequest {
    readonly flight: string;
}

export interface DispatcherRestControllerApiDeclareBusUnavailabilityRequest {
    readonly unavailabilityUpdateDto: UnavailabilityUpdateDto;
}

export interface DispatcherRestControllerApiDeclareBusVacationRequest {
    readonly vacationUpdateDto: VacationUpdateDto;
}

export interface DispatcherRestControllerApiDiscardFlightFromBusAllocationRequest {
    readonly flight: string;
}

export interface DispatcherRestControllerApiDuplicateToBusRequest {
    readonly mission: string;

    readonly bus: string;
}

export interface DispatcherRestControllerApiExportMissionsRequest {
    readonly from: number;

    readonly to: number;
}

export interface DispatcherRestControllerApiGetAllMissionsRequest {
    readonly from?: number;

    readonly to?: number;
}

export interface DispatcherRestControllerApiReallocateBusRequest {
    readonly mission: string;

    readonly bus: string;
}

export interface DispatcherRestControllerApiRemoveBusUnavailabilityRequest {
    readonly id: number;
}

export interface DispatcherRestControllerApiRemoveBusVacationRequest {
    readonly id: number;
}

export interface DispatcherRestControllerApiUnallocateBusRequest {
    readonly mission: string;
}

export interface DispatcherRestControllerApiUpdateDropOffLocationRequest {
    readonly flight: string;

    readonly dropOffLocation: string;
}

export interface DispatcherRestControllerApiUpdateNumberOfToursRequest {
    readonly mission: string;

    readonly tours: number;
}

export class DispatcherRestControllerApi extends BaseAPI {
    public allocateBus(requestParameters: DispatcherRestControllerApiAllocateBusRequest, options?: any) {
        return DispatcherRestControllerApiFp(this.configuration)
            .allocateBus(requestParameters.flight, requestParameters.bus, options)
            .then(request => request(this.axios, this.basePath));
    }
    public considerFlightForBusAllocation(
        requestParameters: DispatcherRestControllerApiConsiderFlightForBusAllocationRequest,
        options?: any,
    ) {
        return DispatcherRestControllerApiFp(this.configuration)
            .considerFlightForBusAllocation(requestParameters.flight, options)
            .then(request => request(this.axios, this.basePath));
    }
    public declareBusUnavailability(
        requestParameters: DispatcherRestControllerApiDeclareBusUnavailabilityRequest,
        options?: any,
    ) {
        return DispatcherRestControllerApiFp(this.configuration)
            .declareBusUnavailability(requestParameters.unavailabilityUpdateDto, options)
            .then(request => request(this.axios, this.basePath));
    }
    public declareBusVacation(requestParameters: DispatcherRestControllerApiDeclareBusVacationRequest, options?: any) {
        return DispatcherRestControllerApiFp(this.configuration)
            .declareBusVacation(requestParameters.vacationUpdateDto, options)
            .then(request => request(this.axios, this.basePath));
    }
    public discardFlightFromBusAllocation(
        requestParameters: DispatcherRestControllerApiDiscardFlightFromBusAllocationRequest,
        options?: any,
    ) {
        return DispatcherRestControllerApiFp(this.configuration)
            .discardFlightFromBusAllocation(requestParameters.flight, options)
            .then(request => request(this.axios, this.basePath));
    }
    public duplicateToBus(requestParameters: DispatcherRestControllerApiDuplicateToBusRequest, options?: any) {
        return DispatcherRestControllerApiFp(this.configuration)
            .duplicateToBus(requestParameters.mission, requestParameters.bus, options)
            .then(request => request(this.axios, this.basePath));
    }
    public exportMissions(requestParameters: DispatcherRestControllerApiExportMissionsRequest, options?: any) {
        return DispatcherRestControllerApiFp(this.configuration)
            .exportMissions(requestParameters.from, requestParameters.to, options)
            .then(request => request(this.axios, this.basePath));
    }
    public getAllMissions(requestParameters: DispatcherRestControllerApiGetAllMissionsRequest = {}, options?: any) {
        return DispatcherRestControllerApiFp(this.configuration)
            .getAllMissions(requestParameters.from, requestParameters.to, options)
            .then(request => request(this.axios, this.basePath));
    }
    public reallocateBus(requestParameters: DispatcherRestControllerApiReallocateBusRequest, options?: any) {
        return DispatcherRestControllerApiFp(this.configuration)
            .reallocateBus(requestParameters.mission, requestParameters.bus, options)
            .then(request => request(this.axios, this.basePath));
    }
    public removeBusUnavailability(
        requestParameters: DispatcherRestControllerApiRemoveBusUnavailabilityRequest,
        options?: any,
    ) {
        return DispatcherRestControllerApiFp(this.configuration)
            .removeBusUnavailability(requestParameters.id, options)
            .then(request => request(this.axios, this.basePath));
    }
    public removeBusVacation(requestParameters: DispatcherRestControllerApiRemoveBusVacationRequest, options?: any) {
        return DispatcherRestControllerApiFp(this.configuration)
            .removeBusVacation(requestParameters.id, options)
            .then(request => request(this.axios, this.basePath));
    }
    public unallocateBus(requestParameters: DispatcherRestControllerApiUnallocateBusRequest, options?: any) {
        return DispatcherRestControllerApiFp(this.configuration)
            .unallocateBus(requestParameters.mission, options)
            .then(request => request(this.axios, this.basePath));
    }
    public updateDropOffLocation(
        requestParameters: DispatcherRestControllerApiUpdateDropOffLocationRequest,
        options?: any,
    ) {
        return DispatcherRestControllerApiFp(this.configuration)
            .updateDropOffLocation(requestParameters.flight, requestParameters.dropOffLocation, options)
            .then(request => request(this.axios, this.basePath));
    }
    public updateNumberOfTours(
        requestParameters: DispatcherRestControllerApiUpdateNumberOfToursRequest,
        options?: any,
    ) {
        return DispatcherRestControllerApiFp(this.configuration)
            .updateNumberOfTours(requestParameters.mission, requestParameters.tours, options)
            .then(request => request(this.axios, this.basePath));
    }
}

// custom hook generation here
export type PostAllocateBusResponse = AxiosResponse<DispatcherGanttDto>;
export const KeyPostAllocateBus = 'PostAllocateBus';
export const useMutationPostAllocateBus = (
    options?: UseMutationOptions<PostAllocateBusResponse, AxiosError, DispatcherRestControllerApiAllocateBusRequest>,
    axiosConfig?: AxiosRequestConfig,
) => {
    const accessToken = useAppAuthValue('authToken') || undefined;

    const res = useMutation<PostAllocateBusResponse, AxiosError, DispatcherRestControllerApiAllocateBusRequest>(
        (args: DispatcherRestControllerApiAllocateBusRequest) =>
            new DispatcherRestControllerApi(new Configuration({ accessToken })).allocateBus(args, axiosConfig),
        options,
    );
    return { ...res, data: res.data?.data };
};
export const useQueryPostAllocateBus = (
    args: DispatcherRestControllerApiAllocateBusRequest,
    options?: UseQueryOptions<PostAllocateBusResponse, AxiosError>,
    axiosConfig?: AxiosRequestConfig,
    conf?: ConfigurationParameters,
) => {
    const accessToken = useAppAuthValue('authToken') || undefined;

    const res = useQuery<PostAllocateBusResponse, AxiosError>(
        [KeyPostAllocateBus, args, options && options.queryKey, accessToken],
        () =>
            new DispatcherRestControllerApi(new Configuration({ accessToken, ...conf })).allocateBus(args, axiosConfig),
        options,
    );
    return { ...res, data: res.data?.data };
};
export type PostConsiderFlightForBusAllocationResponse = AxiosResponse<DispatcherGanttDto>;
export const KeyPostConsiderFlightForBusAllocation = 'PostConsiderFlightForBusAllocation';
export const useMutationPostConsiderFlightForBusAllocation = (
    options?: UseMutationOptions<
        PostConsiderFlightForBusAllocationResponse,
        AxiosError,
        DispatcherRestControllerApiConsiderFlightForBusAllocationRequest
    >,
    axiosConfig?: AxiosRequestConfig,
) => {
    const accessToken = useAppAuthValue('authToken') || undefined;

    const res = useMutation<
        PostConsiderFlightForBusAllocationResponse,
        AxiosError,
        DispatcherRestControllerApiConsiderFlightForBusAllocationRequest
    >(
        (args: DispatcherRestControllerApiConsiderFlightForBusAllocationRequest) =>
            new DispatcherRestControllerApi(new Configuration({ accessToken })).considerFlightForBusAllocation(
                args,
                axiosConfig,
            ),
        options,
    );
    return { ...res, data: res.data?.data };
};
export const useQueryPostConsiderFlightForBusAllocation = (
    args: DispatcherRestControllerApiConsiderFlightForBusAllocationRequest,
    options?: UseQueryOptions<PostConsiderFlightForBusAllocationResponse, AxiosError>,
    axiosConfig?: AxiosRequestConfig,
    conf?: ConfigurationParameters,
) => {
    const accessToken = useAppAuthValue('authToken') || undefined;

    const res = useQuery<PostConsiderFlightForBusAllocationResponse, AxiosError>(
        [KeyPostConsiderFlightForBusAllocation, args, options && options.queryKey, accessToken],
        () =>
            new DispatcherRestControllerApi(new Configuration({ accessToken, ...conf })).considerFlightForBusAllocation(
                args,
                axiosConfig,
            ),
        options,
    );
    return { ...res, data: res.data?.data };
};
export type PostDeclareBusUnavailabilityResponse = AxiosResponse<DispatcherGanttDto>;
export const KeyPostDeclareBusUnavailability = 'PostDeclareBusUnavailability';
export const useMutationPostDeclareBusUnavailability = (
    options?: UseMutationOptions<
        PostDeclareBusUnavailabilityResponse,
        AxiosError,
        DispatcherRestControllerApiDeclareBusUnavailabilityRequest
    >,
    axiosConfig?: AxiosRequestConfig,
) => {
    const accessToken = useAppAuthValue('authToken') || undefined;

    const res = useMutation<
        PostDeclareBusUnavailabilityResponse,
        AxiosError,
        DispatcherRestControllerApiDeclareBusUnavailabilityRequest
    >(
        (args: DispatcherRestControllerApiDeclareBusUnavailabilityRequest) =>
            new DispatcherRestControllerApi(new Configuration({ accessToken })).declareBusUnavailability(
                args,
                axiosConfig,
            ),
        options,
    );
    return { ...res, data: res.data?.data };
};
export const useQueryPostDeclareBusUnavailability = (
    args: DispatcherRestControllerApiDeclareBusUnavailabilityRequest,
    options?: UseQueryOptions<PostDeclareBusUnavailabilityResponse, AxiosError>,
    axiosConfig?: AxiosRequestConfig,
    conf?: ConfigurationParameters,
) => {
    const accessToken = useAppAuthValue('authToken') || undefined;

    const res = useQuery<PostDeclareBusUnavailabilityResponse, AxiosError>(
        [KeyPostDeclareBusUnavailability, args, options && options.queryKey, accessToken],
        () =>
            new DispatcherRestControllerApi(new Configuration({ accessToken, ...conf })).declareBusUnavailability(
                args,
                axiosConfig,
            ),
        options,
    );
    return { ...res, data: res.data?.data };
};
export type PostDeclareBusVacationResponse = AxiosResponse<DispatcherGanttDto>;
export const KeyPostDeclareBusVacation = 'PostDeclareBusVacation';
export const useMutationPostDeclareBusVacation = (
    options?: UseMutationOptions<
        PostDeclareBusVacationResponse,
        AxiosError,
        DispatcherRestControllerApiDeclareBusVacationRequest
    >,
    axiosConfig?: AxiosRequestConfig,
) => {
    const accessToken = useAppAuthValue('authToken') || undefined;

    const res = useMutation<
        PostDeclareBusVacationResponse,
        AxiosError,
        DispatcherRestControllerApiDeclareBusVacationRequest
    >(
        (args: DispatcherRestControllerApiDeclareBusVacationRequest) =>
            new DispatcherRestControllerApi(new Configuration({ accessToken })).declareBusVacation(args, axiosConfig),
        options,
    );
    return { ...res, data: res.data?.data };
};
export const useQueryPostDeclareBusVacation = (
    args: DispatcherRestControllerApiDeclareBusVacationRequest,
    options?: UseQueryOptions<PostDeclareBusVacationResponse, AxiosError>,
    axiosConfig?: AxiosRequestConfig,
    conf?: ConfigurationParameters,
) => {
    const accessToken = useAppAuthValue('authToken') || undefined;

    const res = useQuery<PostDeclareBusVacationResponse, AxiosError>(
        [KeyPostDeclareBusVacation, args, options && options.queryKey, accessToken],
        () =>
            new DispatcherRestControllerApi(new Configuration({ accessToken, ...conf })).declareBusVacation(
                args,
                axiosConfig,
            ),
        options,
    );
    return { ...res, data: res.data?.data };
};
export type DeleteDiscardFlightFromBusAllocationResponse = AxiosResponse<DispatcherGanttDto>;
export const KeyDeleteDiscardFlightFromBusAllocation = 'DeleteDiscardFlightFromBusAllocation';
export const useMutationDeleteDiscardFlightFromBusAllocation = (
    options?: UseMutationOptions<
        DeleteDiscardFlightFromBusAllocationResponse,
        AxiosError,
        DispatcherRestControllerApiDiscardFlightFromBusAllocationRequest
    >,
    axiosConfig?: AxiosRequestConfig,
) => {
    const accessToken = useAppAuthValue('authToken') || undefined;

    const res = useMutation<
        DeleteDiscardFlightFromBusAllocationResponse,
        AxiosError,
        DispatcherRestControllerApiDiscardFlightFromBusAllocationRequest
    >(
        (args: DispatcherRestControllerApiDiscardFlightFromBusAllocationRequest) =>
            new DispatcherRestControllerApi(new Configuration({ accessToken })).discardFlightFromBusAllocation(
                args,
                axiosConfig,
            ),
        options,
    );
    return { ...res, data: res.data?.data };
};
export const useQueryDeleteDiscardFlightFromBusAllocation = (
    args: DispatcherRestControllerApiDiscardFlightFromBusAllocationRequest,
    options?: UseQueryOptions<DeleteDiscardFlightFromBusAllocationResponse, AxiosError>,
    axiosConfig?: AxiosRequestConfig,
    conf?: ConfigurationParameters,
) => {
    const accessToken = useAppAuthValue('authToken') || undefined;

    const res = useQuery<DeleteDiscardFlightFromBusAllocationResponse, AxiosError>(
        [KeyDeleteDiscardFlightFromBusAllocation, args, options && options.queryKey, accessToken],
        () =>
            new DispatcherRestControllerApi(new Configuration({ accessToken, ...conf })).discardFlightFromBusAllocation(
                args,
                axiosConfig,
            ),
        options,
    );
    return { ...res, data: res.data?.data };
};
export type PostDuplicateToBusResponse = AxiosResponse<DispatcherGanttDto>;
export const KeyPostDuplicateToBus = 'PostDuplicateToBus';
export const useMutationPostDuplicateToBus = (
    options?: UseMutationOptions<
        PostDuplicateToBusResponse,
        AxiosError,
        DispatcherRestControllerApiDuplicateToBusRequest
    >,
    axiosConfig?: AxiosRequestConfig,
) => {
    const accessToken = useAppAuthValue('authToken') || undefined;

    const res = useMutation<PostDuplicateToBusResponse, AxiosError, DispatcherRestControllerApiDuplicateToBusRequest>(
        (args: DispatcherRestControllerApiDuplicateToBusRequest) =>
            new DispatcherRestControllerApi(new Configuration({ accessToken })).duplicateToBus(args, axiosConfig),
        options,
    );
    return { ...res, data: res.data?.data };
};
export const useQueryPostDuplicateToBus = (
    args: DispatcherRestControllerApiDuplicateToBusRequest,
    options?: UseQueryOptions<PostDuplicateToBusResponse, AxiosError>,
    axiosConfig?: AxiosRequestConfig,
    conf?: ConfigurationParameters,
) => {
    const accessToken = useAppAuthValue('authToken') || undefined;

    const res = useQuery<PostDuplicateToBusResponse, AxiosError>(
        [KeyPostDuplicateToBus, args, options && options.queryKey, accessToken],
        () =>
            new DispatcherRestControllerApi(new Configuration({ accessToken, ...conf })).duplicateToBus(
                args,
                axiosConfig,
            ),
        options,
    );
    return { ...res, data: res.data?.data };
};
export type GetExportMissionsResponse = AxiosResponse<any>;
export const KeyGetExportMissions = 'GetExportMissions';
export const useMutationGetExportMissions = (
    options?: UseMutationOptions<
        GetExportMissionsResponse,
        AxiosError,
        DispatcherRestControllerApiExportMissionsRequest
    >,
    axiosConfig?: AxiosRequestConfig,
) => {
    const accessToken = useAppAuthValue('authToken') || undefined;

    const res = useMutation<GetExportMissionsResponse, AxiosError, DispatcherRestControllerApiExportMissionsRequest>(
        (args: DispatcherRestControllerApiExportMissionsRequest) =>
            new DispatcherRestControllerApi(new Configuration({ accessToken })).exportMissions(args, axiosConfig),
        options,
    );
    return { ...res, data: res.data?.data };
};
export const useQueryGetExportMissions = (
    args: DispatcherRestControllerApiExportMissionsRequest,
    options?: UseQueryOptions<GetExportMissionsResponse, AxiosError>,
    axiosConfig?: AxiosRequestConfig,
    conf?: ConfigurationParameters,
) => {
    const accessToken = useAppAuthValue('authToken') || undefined;

    const res = useQuery<GetExportMissionsResponse, AxiosError>(
        [KeyGetExportMissions, args, options && options.queryKey, accessToken],
        () =>
            new DispatcherRestControllerApi(new Configuration({ accessToken, ...conf })).exportMissions(
                args,
                axiosConfig,
            ),
        options,
    );
    return { ...res, data: res.data?.data };
};
export type GetGetAllMissionsResponse = AxiosResponse<DispatcherGanttDto>;
export const KeyGetGetAllMissions = 'GetGetAllMissions';
export const useMutationGetGetAllMissions = (
    options?: UseMutationOptions<
        GetGetAllMissionsResponse,
        AxiosError,
        DispatcherRestControllerApiGetAllMissionsRequest
    >,
    axiosConfig?: AxiosRequestConfig,
) => {
    const accessToken = useAppAuthValue('authToken') || undefined;

    const res = useMutation<GetGetAllMissionsResponse, AxiosError, DispatcherRestControllerApiGetAllMissionsRequest>(
        (args: DispatcherRestControllerApiGetAllMissionsRequest) =>
            new DispatcherRestControllerApi(new Configuration({ accessToken })).getAllMissions(args, axiosConfig),
        options,
    );
    return { ...res, data: res.data?.data };
};
export const useQueryGetGetAllMissions = (
    args: DispatcherRestControllerApiGetAllMissionsRequest,
    options?: UseQueryOptions<GetGetAllMissionsResponse, AxiosError>,
    axiosConfig?: AxiosRequestConfig,
    conf?: ConfigurationParameters,
) => {
    const accessToken = useAppAuthValue('authToken') || undefined;

    const res = useQuery<GetGetAllMissionsResponse, AxiosError>(
        [KeyGetGetAllMissions, args, options && options.queryKey, accessToken],
        () =>
            new DispatcherRestControllerApi(new Configuration({ accessToken, ...conf })).getAllMissions(
                args,
                axiosConfig,
            ),
        options,
    );
    return { ...res, data: res.data?.data };
};
export type PostReallocateBusResponse = AxiosResponse<DispatcherGanttDto>;
export const KeyPostReallocateBus = 'PostReallocateBus';
export const useMutationPostReallocateBus = (
    options?: UseMutationOptions<
        PostReallocateBusResponse,
        AxiosError,
        DispatcherRestControllerApiReallocateBusRequest
    >,
    axiosConfig?: AxiosRequestConfig,
) => {
    const accessToken = useAppAuthValue('authToken') || undefined;

    const res = useMutation<PostReallocateBusResponse, AxiosError, DispatcherRestControllerApiReallocateBusRequest>(
        (args: DispatcherRestControllerApiReallocateBusRequest) =>
            new DispatcherRestControllerApi(new Configuration({ accessToken })).reallocateBus(args, axiosConfig),
        options,
    );
    return { ...res, data: res.data?.data };
};
export const useQueryPostReallocateBus = (
    args: DispatcherRestControllerApiReallocateBusRequest,
    options?: UseQueryOptions<PostReallocateBusResponse, AxiosError>,
    axiosConfig?: AxiosRequestConfig,
    conf?: ConfigurationParameters,
) => {
    const accessToken = useAppAuthValue('authToken') || undefined;

    const res = useQuery<PostReallocateBusResponse, AxiosError>(
        [KeyPostReallocateBus, args, options && options.queryKey, accessToken],
        () =>
            new DispatcherRestControllerApi(new Configuration({ accessToken, ...conf })).reallocateBus(
                args,
                axiosConfig,
            ),
        options,
    );
    return { ...res, data: res.data?.data };
};
export type DeleteRemoveBusUnavailabilityResponse = AxiosResponse<DispatcherGanttDto>;
export const KeyDeleteRemoveBusUnavailability = 'DeleteRemoveBusUnavailability';
export const useMutationDeleteRemoveBusUnavailability = (
    options?: UseMutationOptions<
        DeleteRemoveBusUnavailabilityResponse,
        AxiosError,
        DispatcherRestControllerApiRemoveBusUnavailabilityRequest
    >,
    axiosConfig?: AxiosRequestConfig,
) => {
    const accessToken = useAppAuthValue('authToken') || undefined;

    const res = useMutation<
        DeleteRemoveBusUnavailabilityResponse,
        AxiosError,
        DispatcherRestControllerApiRemoveBusUnavailabilityRequest
    >(
        (args: DispatcherRestControllerApiRemoveBusUnavailabilityRequest) =>
            new DispatcherRestControllerApi(new Configuration({ accessToken })).removeBusUnavailability(
                args,
                axiosConfig,
            ),
        options,
    );
    return { ...res, data: res.data?.data };
};
export const useQueryDeleteRemoveBusUnavailability = (
    args: DispatcherRestControllerApiRemoveBusUnavailabilityRequest,
    options?: UseQueryOptions<DeleteRemoveBusUnavailabilityResponse, AxiosError>,
    axiosConfig?: AxiosRequestConfig,
    conf?: ConfigurationParameters,
) => {
    const accessToken = useAppAuthValue('authToken') || undefined;

    const res = useQuery<DeleteRemoveBusUnavailabilityResponse, AxiosError>(
        [KeyDeleteRemoveBusUnavailability, args, options && options.queryKey, accessToken],
        () =>
            new DispatcherRestControllerApi(new Configuration({ accessToken, ...conf })).removeBusUnavailability(
                args,
                axiosConfig,
            ),
        options,
    );
    return { ...res, data: res.data?.data };
};
export type DeleteRemoveBusVacationResponse = AxiosResponse<DispatcherGanttDto>;
export const KeyDeleteRemoveBusVacation = 'DeleteRemoveBusVacation';
export const useMutationDeleteRemoveBusVacation = (
    options?: UseMutationOptions<
        DeleteRemoveBusVacationResponse,
        AxiosError,
        DispatcherRestControllerApiRemoveBusVacationRequest
    >,
    axiosConfig?: AxiosRequestConfig,
) => {
    const accessToken = useAppAuthValue('authToken') || undefined;

    const res = useMutation<
        DeleteRemoveBusVacationResponse,
        AxiosError,
        DispatcherRestControllerApiRemoveBusVacationRequest
    >(
        (args: DispatcherRestControllerApiRemoveBusVacationRequest) =>
            new DispatcherRestControllerApi(new Configuration({ accessToken })).removeBusVacation(args, axiosConfig),
        options,
    );
    return { ...res, data: res.data?.data };
};
export const useQueryDeleteRemoveBusVacation = (
    args: DispatcherRestControllerApiRemoveBusVacationRequest,
    options?: UseQueryOptions<DeleteRemoveBusVacationResponse, AxiosError>,
    axiosConfig?: AxiosRequestConfig,
    conf?: ConfigurationParameters,
) => {
    const accessToken = useAppAuthValue('authToken') || undefined;

    const res = useQuery<DeleteRemoveBusVacationResponse, AxiosError>(
        [KeyDeleteRemoveBusVacation, args, options && options.queryKey, accessToken],
        () =>
            new DispatcherRestControllerApi(new Configuration({ accessToken, ...conf })).removeBusVacation(
                args,
                axiosConfig,
            ),
        options,
    );
    return { ...res, data: res.data?.data };
};
export type DeleteUnallocateBusResponse = AxiosResponse<DispatcherGanttDto>;
export const KeyDeleteUnallocateBus = 'DeleteUnallocateBus';
export const useMutationDeleteUnallocateBus = (
    options?: UseMutationOptions<
        DeleteUnallocateBusResponse,
        AxiosError,
        DispatcherRestControllerApiUnallocateBusRequest
    >,
    axiosConfig?: AxiosRequestConfig,
) => {
    const accessToken = useAppAuthValue('authToken') || undefined;

    const res = useMutation<DeleteUnallocateBusResponse, AxiosError, DispatcherRestControllerApiUnallocateBusRequest>(
        (args: DispatcherRestControllerApiUnallocateBusRequest) =>
            new DispatcherRestControllerApi(new Configuration({ accessToken })).unallocateBus(args, axiosConfig),
        options,
    );
    return { ...res, data: res.data?.data };
};
export const useQueryDeleteUnallocateBus = (
    args: DispatcherRestControllerApiUnallocateBusRequest,
    options?: UseQueryOptions<DeleteUnallocateBusResponse, AxiosError>,
    axiosConfig?: AxiosRequestConfig,
    conf?: ConfigurationParameters,
) => {
    const accessToken = useAppAuthValue('authToken') || undefined;

    const res = useQuery<DeleteUnallocateBusResponse, AxiosError>(
        [KeyDeleteUnallocateBus, args, options && options.queryKey, accessToken],
        () =>
            new DispatcherRestControllerApi(new Configuration({ accessToken, ...conf })).unallocateBus(
                args,
                axiosConfig,
            ),
        options,
    );
    return { ...res, data: res.data?.data };
};
export type PostUpdateDropOffLocationResponse = AxiosResponse<DispatcherGanttDto>;
export const KeyPostUpdateDropOffLocation = 'PostUpdateDropOffLocation';
export const useMutationPostUpdateDropOffLocation = (
    options?: UseMutationOptions<
        PostUpdateDropOffLocationResponse,
        AxiosError,
        DispatcherRestControllerApiUpdateDropOffLocationRequest
    >,
    axiosConfig?: AxiosRequestConfig,
) => {
    const accessToken = useAppAuthValue('authToken') || undefined;

    const res = useMutation<
        PostUpdateDropOffLocationResponse,
        AxiosError,
        DispatcherRestControllerApiUpdateDropOffLocationRequest
    >(
        (args: DispatcherRestControllerApiUpdateDropOffLocationRequest) =>
            new DispatcherRestControllerApi(new Configuration({ accessToken })).updateDropOffLocation(
                args,
                axiosConfig,
            ),
        options,
    );
    return { ...res, data: res.data?.data };
};
export const useQueryPostUpdateDropOffLocation = (
    args: DispatcherRestControllerApiUpdateDropOffLocationRequest,
    options?: UseQueryOptions<PostUpdateDropOffLocationResponse, AxiosError>,
    axiosConfig?: AxiosRequestConfig,
    conf?: ConfigurationParameters,
) => {
    const accessToken = useAppAuthValue('authToken') || undefined;

    const res = useQuery<PostUpdateDropOffLocationResponse, AxiosError>(
        [KeyPostUpdateDropOffLocation, args, options && options.queryKey, accessToken],
        () =>
            new DispatcherRestControllerApi(new Configuration({ accessToken, ...conf })).updateDropOffLocation(
                args,
                axiosConfig,
            ),
        options,
    );
    return { ...res, data: res.data?.data };
};
export type PatchUpdateNumberOfToursResponse = AxiosResponse<DispatcherMissionSlotDto>;
export const KeyPatchUpdateNumberOfTours = 'PatchUpdateNumberOfTours';
export const useMutationPatchUpdateNumberOfTours = (
    options?: UseMutationOptions<
        PatchUpdateNumberOfToursResponse,
        AxiosError,
        DispatcherRestControllerApiUpdateNumberOfToursRequest
    >,
    axiosConfig?: AxiosRequestConfig,
) => {
    const accessToken = useAppAuthValue('authToken') || undefined;

    const res = useMutation<
        PatchUpdateNumberOfToursResponse,
        AxiosError,
        DispatcherRestControllerApiUpdateNumberOfToursRequest
    >(
        (args: DispatcherRestControllerApiUpdateNumberOfToursRequest) =>
            new DispatcherRestControllerApi(new Configuration({ accessToken })).updateNumberOfTours(args, axiosConfig),
        options,
    );
    return { ...res, data: res.data?.data };
};
export const useQueryPatchUpdateNumberOfTours = (
    args: DispatcherRestControllerApiUpdateNumberOfToursRequest,
    options?: UseQueryOptions<PatchUpdateNumberOfToursResponse, AxiosError>,
    axiosConfig?: AxiosRequestConfig,
    conf?: ConfigurationParameters,
) => {
    const accessToken = useAppAuthValue('authToken') || undefined;

    const res = useQuery<PatchUpdateNumberOfToursResponse, AxiosError>(
        [KeyPatchUpdateNumberOfTours, args, options && options.queryKey, accessToken],
        () =>
            new DispatcherRestControllerApi(new Configuration({ accessToken, ...conf })).updateNumberOfTours(
                args,
                axiosConfig,
            ),
        options,
    );
    return { ...res, data: res.data?.data };
};

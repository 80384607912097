/*
 * Copyright Innov'ATM all rights reserved.
 * This software is the property of Innov'ATM and may not be used in any manner except under a
 * license agreement signed with Innov'ATM.
 */

import { differenceInMilliseconds } from 'date-fns';

export function pxToTimestamp(
    leftPx: number,
    from: number,
    ganttPxPerMs: number,
    ganttTitleWidth: number,
    ganttContentPaddingLeft: number,
) {
    return from + (leftPx - ganttTitleWidth - ganttContentPaddingLeft) / ganttPxPerMs;
}

export function timestampToPx(
    timestamp: number,
    from: number,
    ganttPxPerMs: number,
    ganttTitleWidth: number,
    ganttContentPaddingLeft: number,
) {
    return (
        differenceInMilliseconds(timestamp, from) * ganttPxPerMs +
        ganttTitleWidth +
        ganttContentPaddingLeft
    ).toFixed(0);
}

export function calculateGanttWidth(
    from: number,
    to: number,
    ganttPxPerMs: number,
    ganttTitleWidth: number,
    ganttContentPaddingLeft: number,
) {
    return differenceInMilliseconds(to, from) * ganttPxPerMs + ganttTitleWidth + ganttContentPaddingLeft;
}

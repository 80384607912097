import { Box, styled } from '@mui/material';

export const StyledCurrentMissionFlightInfoContainer = styled(Box)`
    height: 510px;
    width: 250px;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-right: 1px dashed #14517b;
`;

export const StyledTime = styled(Box)`
    font-size: 55px;
    line-height: 65px;
    font-weight: bold;
`;

export const StyledFlow = styled(Box)`
    font-size: 32px;
    line-height: 37px;
    text-transform: uppercase;
`;

export const StyledLandingObt = styled(Box)`
    font-size: 18px;
    line-height: 21px;
    text-transform: uppercase;
    margin-bottom: 20px;
`;

export const StyledLandingObtBadge = styled(Box)`
    min-width: 135px;
    min-height: 45px;
    padding: 0 20px;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    font-size: 19px;
    font-weight: bold;
    margin-bottom: 65px;
`;

export const StyledFlightNumber = styled(Box)`
    color: black;
    font-size: 28px;
    font-weight: bold;
`;

export const StyledIcaoIata = styled(Box)`
    color: black;
    font-size: 28px;
    margin-bottom: 22px;
`;

export const StyledAircraftPaxInfos = styled(Box)`
    border-top: 1px dashed #14517b;
    width: 145px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-top: 22px;
`;

export const StyledInfoBox = styled(Box)`
    display: flex;
    align-items: center;
    gap: 4px;
    font-size: 25px;
    color: black;
`;

/*
 * Copyright Innov'ATM all rights reserved.
 * This software is the property of Innov'ATM and may not be used in any manner except under a
 * license agreement signed with Innov'ATM.
 */

import React, { memo } from 'react';
import { DispatcherMissionSlotDto, FlightFlow, FlightSlotDto, MissionState } from '../../../../backend/gen';
import {
    StyledAboveStripContainer,
    StyledAcPaxInfo,
    StyledAcPaxInfos,
    StyledContainer,
    StyledDirections,
    StyledMissionStepsContainer,
    StyledStripFlightBox,
    StyledStripFlightTooltip,
    StyledStripFlow,
    StyledStripFooter,
    StyledStripFooterContainer,
    StyledStripTimeBadge,
    zoneTooltipContentsStyles,
} from './StripFlight.style';
import { FormattedTime } from '../../../../components/time/FormattedTime';
import { Box, Zoom } from '@mui/material';
import { ReactComponent as AirlineSeatReclineExtraIcon } from '../../../../assets/icons/icon-passenger-light-bus.svg';
import { ReactComponent as AircraftIcon } from '../../../../assets/icons/icon-plane-light-bus.svg';
import { StepsIndicator } from './StripFlightMissionSteps/StepsIndicator';
import { useBoolean } from '../../../../hooks/useBoolean';
import { StripFlightMenu } from './StripFlightMenu/StripFlightMenu';
import { StepsPreview } from './StripFlightMissionSteps/StepsPreview';
import { getFlowColor } from '../../../../utils/data.utils';
import { SpecifyDropoffLocationDialog } from '../Gantt/SpecifyDropoffLocationDialog/SpecifyDropoffLocationDialog';
import { useUiValue } from '../../../../contexts/UiContext';

export const StripFlight = memo(function StripFlight({ flight }: { flight: FlightSlotDto | DispatcherMissionSlotDto }) {
    const isTactical = useUiValue('isTacticalView');
    const hasMission = 'state' in flight;
    const showMenuButton = useBoolean(false);
    const flowColor = getFlowColor(flight.flow);
    const isMissionTerminated = hasMission && flight.state === MissionState.TERMINATED;
    const specifyDropoffLocationDialogOpen = useBoolean(false);

    return (
        <StyledContainer>
            <SpecifyDropoffLocationDialog
                flightId={flight.flightId}
                dropoffLocation={flight.dropOffLocation}
                open={specifyDropoffLocationDialogOpen.value}
                closeDialog={specifyDropoffLocationDialogOpen.setFalse}
            />
            <Box
                onMouseEnter={!isTactical ? null : showMenuButton.setTrue}
                onMouseLeave={!isTactical ? null : showMenuButton.setFalse}
            >
                <StyledAboveStripContainer>
                    <StyledStripTimeBadge bgcolor={flowColor} color={isMissionTerminated ? '#cccccc' : 'white'}>
                        <FormattedTime>{flight.refTime}</FormattedTime>
                    </StyledStripTimeBadge>
                    {showMenuButton.value && <StyledStripFlow color={flowColor}>{flight.flow}</StyledStripFlow>}
                    <StripFlightMenu
                        showMenuButton={showMenuButton}
                        flightId={flight.flightId}
                        flightFlow={flight.flow}
                        flightNbTours={hasMission ? flight.nbTours : null}
                        missionId={hasMission ? flight.missionId : null}
                        flightIsManual={flight.manual}
                        onClickOpenSpecifyDropoffLocationDialog={specifyDropoffLocationDialogOpen.setTrue}
                        isMissionTerminated={isMissionTerminated}
                    />
                </StyledAboveStripContainer>
                <StyledStripFlightBox
                    sx={{
                        backgroundColor: isMissionTerminated ? '#cccccc' : 'white',
                        outline:
                            hasMission && flight.state === MissionState.IN_PROGRESS ? `3px solid ${flowColor}` : 'none',
                    }}
                >
                    <StyledDirections>
                        {flight.flow === FlightFlow.ARR ? (
                            <>
                                <Box>
                                    Pkg <b>{flight.parking}</b>
                                </Box>
                                <Box>→</Box>
                                <StyledStripFlightTooltip
                                    title={flight?.dropOffLocation || ''}
                                    TransitionComponent={Zoom}
                                    placement={'bottom'}
                                    arrow
                                >
                                    <Box sx={flight.dropOffLocation?.length ? zoneTooltipContentsStyles : undefined}>
                                        Zone <b>{flight.zone}</b>
                                    </Box>
                                </StyledStripFlightTooltip>
                            </>
                        ) : (
                            <>
                                <Box>
                                    Gate <b>{flight.gate}</b>
                                </Box>
                                <Box>→</Box>
                                <Box>
                                    Pkg <b>{flight.parking}</b>
                                </Box>
                            </>
                        )}
                    </StyledDirections>
                    <StyledAcPaxInfos>
                        <StyledAcPaxInfo>
                            <AircraftIcon fill={flowColor} width={'24px'} height={'24px'} />
                            {flight.acType.iata}
                        </StyledAcPaxInfo>
                        <StyledAcPaxInfo>
                            <AirlineSeatReclineExtraIcon fill={flowColor} width={'24px'} height={'24px'} />
                            {flight.pax}
                        </StyledAcPaxInfo>
                    </StyledAcPaxInfos>
                </StyledStripFlightBox>
                <StyledStripFooterContainer sx={{ opacity: isMissionTerminated ? '50%' : '100%' }}>
                    <StyledStripFooter>
                        <Box>{flight.flightNumber}</Box>
                        <Box>{flight.adepAdes.iata}</Box>
                    </StyledStripFooter>
                </StyledStripFooterContainer>
            </Box>
            {hasMission && (
                <StyledStripFlightTooltip
                    title={<StepsPreview steps={flight.steps} flow={flight.flow} nbTours={flight.nbTours} />}
                    followCursor={true}
                    TransitionComponent={Zoom}
                >
                    <StyledMissionStepsContainer>
                        {[...Array(flight.nbTours)].map((_, i) => {
                            return (
                                <StepsIndicator
                                    key={i}
                                    tour={i + 1}
                                    nbTours={flight.nbTours}
                                    stepsTotal={flight.nbStepsTotal}
                                    stepsDone={flight.nbStepsDone}
                                    flow={flight.flow}
                                />
                            );
                        })}
                    </StyledMissionStepsContainer>
                </StyledStripFlightTooltip>
            )}
        </StyledContainer>
    );
});

import { Box, styled } from '@mui/material';

export const StyledCustomMenuButton = styled(Box)`
    height: 20px;
    width: 32px;
    border-radius: 10px;
    margin-right: 10px;
    background-color: white;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    padding: 0 2px;
    cursor: pointer;
    z-index: 2;
`;

export const StyledDot = styled(Box)`
    height: 4px;
    width: 4px;
    border-radius: 50%;
    background-color: black;
`;

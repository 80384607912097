import { menuClasses, menuItemClasses, paperClasses } from '@mui/material';

// These styles can override default PopoverMenu component styles
export const CustomMenuStyles = {
    [`& .${paperClasses.root}`]: {
        marginLeft: '15px',
        borderRadius: '5px',
        minWidth: '0',
        padding: '10px',
        [`& .${menuClasses.list}`]: {
            display: 'flex',
            flexDirection: 'column',
            gap: '10px',
        },
        [`& .${menuItemClasses.root}`]: {
            padding: '0 10px 0 10px',
            borderRadius: '5px',
            height: '30px',
            fontSize: '14px',
            letterSpacing: '0.8px',
            '&:hover': {
                fontWeight: 'bold',
                backgroundColor: 'rgba(0,110,187,0.1)',
                letterSpacing: '0.67px',
            },
        },
    },
};

import { AuthContextProvider } from './contexts/AuthContextProvider';
import { CssBaseline, styled, ThemeProvider } from '@mui/material';
import { ConfirmProvider } from 'material-ui-confirm';
import React from 'react';
import { QueryClient, QueryClientProvider } from 'react-query';
import { BrowserRouter } from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.min.css';
import { defaultConfirmProps } from './utils/constants';
import { theme } from './theme';
import { App } from './views/App';
import { ToastContainer } from 'react-toastify';
import { UiContextProvider } from './contexts/UiContext';
import { IntlContextProvider, IntlState, Locales } from '@innovatm/js-iam';
import en from './locales/en.json';
import fr from './locales/fr.json';
import { DateFnsProvider } from './contexts/DateFnsProvider';

const messagesMap: Record<Locales, Record<string, any>> = {
    [Locales.fr]: fr,
    [Locales.en]: en,
};
const intlConfig: Partial<IntlState> = {
    translations: messagesMap,
};

const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            // avoid retry when 404 or already retried twice
            retry: (retryCount, error) => retryCount < 3 && ![404, 403].includes((error as any)?.response?.status),
            staleTime: 0,
            cacheTime: 5 * 60 * 1000,
            refetchOnWindowFocus: false,
            refetchInterval: false,
            refetchOnMount: false,
        },
    },
});

const StyledToastContainer = styled(ToastContainer)`
    min-width: 320px;
    width: auto;
`;

export const Root = () => {
    return (
        <BrowserRouter>
            <QueryClientProvider client={queryClient}>
                <AuthContextProvider>
                    <IntlContextProvider config={intlConfig} textComponent="span">
                        <DateFnsProvider>
                            <ThemeProvider theme={theme}>
                                <UiContextProvider>
                                    <ConfirmProvider defaultOptions={defaultConfirmProps}>
                                        <CssBaseline />
                                        <App />
                                        <StyledToastContainer />
                                    </ConfirmProvider>
                                </UiContextProvider>
                            </ThemeProvider>
                        </DateFnsProvider>
                    </IntlContextProvider>
                </AuthContextProvider>
            </QueryClientProvider>
        </BrowserRouter>
    );
};

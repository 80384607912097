import React, { memo, useCallback } from 'react';
import { toast } from 'react-toastify';
import { errorToastConfig } from '../../utils/constants';
import { object, string } from 'yup';
import { FormattedMessage, useIntl } from 'react-intl';
import { ROUTE_HOME, ROUTE_LOGIN } from '../../routes';
import { SignupPage, SignupPageLink } from '@innovatm/js-iam';
import { AuthContext } from '../../contexts/AuthContext.selectors';
import { SignupSuccessPage } from './SignupSuccessPage';
import { isPossiblePhoneNumber } from 'react-phone-number-input';
import { Box, styled } from '@mui/material';

const logoImg = require('../../assets/images/InnovATM_AirportKeeper-Web-dark.png');

export const StyledRoot = styled(Box)`
    display: flex;
    overflow: hidden;
    height: 100%;
`;
export const StyledLeftSide = styled(Box)`
    width: 50%;
    background: #106ebb;
`;
export const StyledRightSide = styled(Box)`
    display: flex;
    justify-content: left;
    align-items: center;
    width: 50%;
    padding-left: 4%;
    background: #0c456c;
`;

const StyledLogo = styled(Box)`
    display: flex;
    align-items: center;
    height: 100%;
    justify-content: flex-end;
    img {
        margin: auto 12% auto auto;
        width: 370px;
    }
`;

export const SignupRoute = memo(function SignupRoute() {
    const intl = useIntl();

    const signupValidationSchema = object().shape({
        firstName: string().required(intl.formatMessage({ id: 'form.required' })),
        lastName: string().required(intl.formatMessage({ id: 'form.required' })),
        email: string()
            .email(intl.formatMessage({ id: 'pages.signup-page.invalid-email' }))
            .required(intl.formatMessage({ id: 'form.required' })),
        phone: string()
            .test('isPossibleNumber', intl.formatMessage({ id: 'pages.signup-page.invalid-phone' }), value =>
                value ? isPossiblePhoneNumber(value) : true,
            )
            .required(intl.formatMessage({ id: 'form.required' })),
        airport: string()
            .length(3, intl.formatMessage({ id: 'pages.signup-page.invalid-airport' }))
            .required(intl.formatMessage({ id: 'form.required' })),
        timeZoneId: string()
            .required(intl.formatMessage({ id: 'form.required' }))
            .nullable(),
    });

    const handleSignupErrors = useCallback(e => {
        toast.error(<FormattedMessage id={`pages.signup-page.signup-error.${e.response.data.errorKey}`} />, {
            ...errorToastConfig,
            toastId: 'signupError',
        });
    }, []);

    return (
        <StyledRoot>
            <StyledLeftSide>
                <StyledLogo>
                    <img alt={'Logo'} src={logoImg} />
                </StyledLogo>
            </StyledLeftSide>
            <StyledRightSide>
                <SignupPage
                    authContext={AuthContext as any}
                    validationSchema={signupValidationSchema}
                    alreadyLoggedInRedirectRoute={ROUTE_HOME}
                    onError={handleSignupErrors}
                    showOnSignupSuccess={<SignupSuccessPage />}
                    signupLabel={intl.formatMessage({ id: 'pages.signup-page.signup-label' })}
                    airportIataLabel={intl.formatMessage({ id: 'pages.signup-page.airport-iata-label' })}
                    emailLabel={intl.formatMessage({ id: 'pages.signup-page.email-label' })}
                    firstNameLabel={intl.formatMessage({ id: 'pages.signup-page.first-name-label' })}
                    lastNameLabel={intl.formatMessage({ id: 'pages.signup-page.last-name-label' })}
                    phoneLabel={intl.formatMessage({ id: 'pages.signup-page.phone-label' })}
                    timezoneLabel={intl.formatMessage({ id: 'pages.signup-page.timezone-label' })}
                    termsAgreementLabel={intl.formatMessage({ id: 'pages.signup-page.terms-agreement-label' })}
                    phoneDefaultCountry={'FR'}
                    links={
                        <SignupPageLink to={ROUTE_LOGIN}>
                            {intl.formatMessage({ id: 'pages.signup-page.links-login' })}
                        </SignupPageLink>
                    }
                    sx={{
                        '.Mui-checked': {
                            color: '#106ebb !important',
                        },
                    }}
                />
            </StyledRightSide>
        </StyledRoot>
    );
});

import React, { memo } from 'react';
import { FormattedMessage } from 'react-intl';
import { CustomDialog } from '../../../../../components/CustomDialog/CustomDialog';
import {
    SpecifyDropoffLocationDialogDialogProps,
    SpecifyDropoffLocationDialogTitleProps,
} from './SpecifyDropoffLocationDialog.style';
import { SpecifyDropoffLocationDialogContentAndActionsContainer } from './SpecifyDropoffLocationDialogContentAndActionsContainer/SpecifyDropoffLocationDialogContentAndActionsContainer';

export const SpecifyDropoffLocationDialog = memo(function SpecifyDropoffLocationDialog({
    flightId,
    dropoffLocation,
    open,
    closeDialog,
}: {
    flightId: string;
    dropoffLocation?: string | null;
    open: boolean;
    closeDialog: () => void;
}) {
    return (
        <CustomDialog
            open={open}
            customDialogProps={SpecifyDropoffLocationDialogDialogProps}
            title={<FormattedMessage id={'stripFlight.menu.specifyDropOff'} />}
            customTitleProps={SpecifyDropoffLocationDialogTitleProps}
            contentAndActionsCombined={
                <SpecifyDropoffLocationDialogContentAndActionsContainer
                    flightId={flightId}
                    dropoffLocation={dropoffLocation}
                    closeDialog={closeDialog}
                />
            }
        ></CustomDialog>
    );
});

/* eslint-disable @typescript-eslint/no-unused-vars */
/**
 * Copyright Innov'ATM all rights reserved. This software is the property of Innov'ATM and may not be used in any
 * manner except under a license agreement signed with Innov'ATM.
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { DriverBusDto } from '../models';
import { DriverMissionSlotDto } from '../models';
import { DriverMissionsDto } from '../models';
import { Configuration, ConfigurationParameters } from '../configuration';
import { BASE_PATH, BaseAPI, RequestArgs } from '../base';
import { AxiosInstance, AxiosPromise, AxiosResponse, AxiosError, AxiosRequestConfig } from 'axios';
import { useQuery, UseMutationOptions, useMutation, UseQueryOptions } from 'react-query';
import {
    assertParamExists,
    createRequestFunction,
    DUMMY_BASE_URL,
    serializeDataIfNeeded,
    setSearchParams,
    toPathString,
    globalAxios,
    setBearerAuthToObject,
} from '../common';
import { useAppAuthValue } from '../../utils';
export const DriverRestControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        confirmNextMissionStep: async (mission: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'mission' is not null or undefined
            assertParamExists('confirmNextMissionStep', 'mission', mission);
            const localVarPath = `/driver/step/confirm`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = {
                method: 'PATCH',
                ...baseOptions,
                ...options,
            };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration);
            if (mission !== undefined) {
                localVarQueryParameter['mission'] = mission;
            }

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            const headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        discardCurrentMission: async (mission: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'mission' is not null or undefined
            assertParamExists('discardCurrentMission', 'mission', mission);
            const localVarPath = `/driver/mission/discard`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = {
                method: 'PATCH',
                ...baseOptions,
                ...options,
            };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration);
            if (mission !== undefined) {
                localVarQueryParameter['mission'] = mission;
            }

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            const headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        getAllBuses: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/driver/buses`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = {
                method: 'GET',
                ...baseOptions,
                ...options,
            };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration);

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            const headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        getNextMissions: async (bus: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'bus' is not null or undefined
            assertParamExists('getNextMissions', 'bus', bus);
            const localVarPath = `/driver/missions`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = {
                method: 'GET',
                ...baseOptions,
                ...options,
            };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration);
            if (bus !== undefined) {
                localVarQueryParameter['bus'] = bus;
            }

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            const headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        undoCurrentMissionStep: async (mission: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'mission' is not null or undefined
            assertParamExists('undoCurrentMissionStep', 'mission', mission);
            const localVarPath = `/driver/step/undo`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = {
                method: 'PATCH',
                ...baseOptions,
                ...options,
            };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration);
            if (mission !== undefined) {
                localVarQueryParameter['mission'] = mission;
            }

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            const headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    };
};
export const DriverRestControllerApiFp = function (configuration?: Configuration) {
    const localVarAxiosParamCreator = DriverRestControllerApiAxiosParamCreator(configuration);
    return {
        /**
         *
         * @param {string} mission
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async confirmNextMissionStep(
            mission: string,
            options?: any,
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DriverMissionSlotDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.confirmNextMissionStep(mission, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {string} mission
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async discardCurrentMission(
            mission: string,
            options?: any,
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DriverMissionSlotDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.discardCurrentMission(mission, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAllBuses(
            options?: any,
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<DriverBusDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAllBuses(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {string} bus
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getNextMissions(
            bus: string,
            options?: any,
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DriverMissionsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getNextMissions(bus, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {string} mission
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async undoCurrentMissionStep(
            mission: string,
            options?: any,
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DriverMissionSlotDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.undoCurrentMissionStep(mission, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    };
};

export const DriverRestControllerApiFactory = function (
    configuration?: Configuration,
    basePath?: string,
    axios?: AxiosInstance,
) {
    const localVarFp = DriverRestControllerApiFp(configuration);
    return {
        confirmNextMissionStep(mission: string, options?: any): AxiosPromise<DriverMissionSlotDto> {
            return localVarFp.confirmNextMissionStep(mission, options).then(request => request(axios, basePath));
        },
        discardCurrentMission(mission: string, options?: any): AxiosPromise<DriverMissionSlotDto> {
            return localVarFp.discardCurrentMission(mission, options).then(request => request(axios, basePath));
        },
        getAllBuses(options?: any): AxiosPromise<Array<DriverBusDto>> {
            return localVarFp.getAllBuses(options).then(request => request(axios, basePath));
        },
        getNextMissions(bus: string, options?: any): AxiosPromise<DriverMissionsDto> {
            return localVarFp.getNextMissions(bus, options).then(request => request(axios, basePath));
        },
        undoCurrentMissionStep(mission: string, options?: any): AxiosPromise<DriverMissionSlotDto> {
            return localVarFp.undoCurrentMissionStep(mission, options).then(request => request(axios, basePath));
        },
    };
};
export interface DriverRestControllerApiConfirmNextMissionStepRequest {
    readonly mission: string;
}

export interface DriverRestControllerApiDiscardCurrentMissionRequest {
    readonly mission: string;
}

export interface DriverRestControllerApiGetNextMissionsRequest {
    readonly bus: string;
}

export interface DriverRestControllerApiUndoCurrentMissionStepRequest {
    readonly mission: string;
}

export class DriverRestControllerApi extends BaseAPI {
    public confirmNextMissionStep(
        requestParameters: DriverRestControllerApiConfirmNextMissionStepRequest,
        options?: any,
    ) {
        return DriverRestControllerApiFp(this.configuration)
            .confirmNextMissionStep(requestParameters.mission, options)
            .then(request => request(this.axios, this.basePath));
    }
    public discardCurrentMission(
        requestParameters: DriverRestControllerApiDiscardCurrentMissionRequest,
        options?: any,
    ) {
        return DriverRestControllerApiFp(this.configuration)
            .discardCurrentMission(requestParameters.mission, options)
            .then(request => request(this.axios, this.basePath));
    }
    public getAllBuses(options?: any) {
        return DriverRestControllerApiFp(this.configuration)
            .getAllBuses(options)
            .then(request => request(this.axios, this.basePath));
    }
    public getNextMissions(requestParameters: DriverRestControllerApiGetNextMissionsRequest, options?: any) {
        return DriverRestControllerApiFp(this.configuration)
            .getNextMissions(requestParameters.bus, options)
            .then(request => request(this.axios, this.basePath));
    }
    public undoCurrentMissionStep(
        requestParameters: DriverRestControllerApiUndoCurrentMissionStepRequest,
        options?: any,
    ) {
        return DriverRestControllerApiFp(this.configuration)
            .undoCurrentMissionStep(requestParameters.mission, options)
            .then(request => request(this.axios, this.basePath));
    }
}

// custom hook generation here
export type PatchConfirmNextMissionStepResponse = AxiosResponse<DriverMissionSlotDto>;
export const KeyPatchConfirmNextMissionStep = 'PatchConfirmNextMissionStep';
export const useMutationPatchConfirmNextMissionStep = (
    options?: UseMutationOptions<
        PatchConfirmNextMissionStepResponse,
        AxiosError,
        DriverRestControllerApiConfirmNextMissionStepRequest
    >,
    axiosConfig?: AxiosRequestConfig,
) => {
    const accessToken = useAppAuthValue('authToken') || undefined;

    const res = useMutation<
        PatchConfirmNextMissionStepResponse,
        AxiosError,
        DriverRestControllerApiConfirmNextMissionStepRequest
    >(
        (args: DriverRestControllerApiConfirmNextMissionStepRequest) =>
            new DriverRestControllerApi(new Configuration({ accessToken })).confirmNextMissionStep(args, axiosConfig),
        options,
    );
    return { ...res, data: res.data?.data };
};
export const useQueryPatchConfirmNextMissionStep = (
    args: DriverRestControllerApiConfirmNextMissionStepRequest,
    options?: UseQueryOptions<PatchConfirmNextMissionStepResponse, AxiosError>,
    axiosConfig?: AxiosRequestConfig,
    conf?: ConfigurationParameters,
) => {
    const accessToken = useAppAuthValue('authToken') || undefined;

    const res = useQuery<PatchConfirmNextMissionStepResponse, AxiosError>(
        [KeyPatchConfirmNextMissionStep, args, options && options.queryKey, accessToken],
        () =>
            new DriverRestControllerApi(new Configuration({ accessToken, ...conf })).confirmNextMissionStep(
                args,
                axiosConfig,
            ),
        options,
    );
    return { ...res, data: res.data?.data };
};
export type PatchDiscardCurrentMissionResponse = AxiosResponse<DriverMissionSlotDto>;
export const KeyPatchDiscardCurrentMission = 'PatchDiscardCurrentMission';
export const useMutationPatchDiscardCurrentMission = (
    options?: UseMutationOptions<
        PatchDiscardCurrentMissionResponse,
        AxiosError,
        DriverRestControllerApiDiscardCurrentMissionRequest
    >,
    axiosConfig?: AxiosRequestConfig,
) => {
    const accessToken = useAppAuthValue('authToken') || undefined;

    const res = useMutation<
        PatchDiscardCurrentMissionResponse,
        AxiosError,
        DriverRestControllerApiDiscardCurrentMissionRequest
    >(
        (args: DriverRestControllerApiDiscardCurrentMissionRequest) =>
            new DriverRestControllerApi(new Configuration({ accessToken })).discardCurrentMission(args, axiosConfig),
        options,
    );
    return { ...res, data: res.data?.data };
};
export const useQueryPatchDiscardCurrentMission = (
    args: DriverRestControllerApiDiscardCurrentMissionRequest,
    options?: UseQueryOptions<PatchDiscardCurrentMissionResponse, AxiosError>,
    axiosConfig?: AxiosRequestConfig,
    conf?: ConfigurationParameters,
) => {
    const accessToken = useAppAuthValue('authToken') || undefined;

    const res = useQuery<PatchDiscardCurrentMissionResponse, AxiosError>(
        [KeyPatchDiscardCurrentMission, args, options && options.queryKey, accessToken],
        () =>
            new DriverRestControllerApi(new Configuration({ accessToken, ...conf })).discardCurrentMission(
                args,
                axiosConfig,
            ),
        options,
    );
    return { ...res, data: res.data?.data };
};
export type GetGetAllBusesResponse = AxiosResponse<Array<DriverBusDto>>;
export const KeyGetGetAllBuses = 'GetGetAllBuses';
export const useMutationGetGetAllBuses = (
    options?: UseMutationOptions<GetGetAllBusesResponse, AxiosError>,
    axiosConfig?: AxiosRequestConfig,
) => {
    const accessToken = useAppAuthValue('authToken') || undefined;

    const res = useMutation<GetGetAllBusesResponse, AxiosError>(
        () => new DriverRestControllerApi(new Configuration({ accessToken })).getAllBuses(axiosConfig),
        options,
    );
    return { ...res, data: res.data?.data };
};
export const useQueryGetGetAllBuses = (
    options?: UseQueryOptions<GetGetAllBusesResponse, AxiosError>,
    axiosConfig?: AxiosRequestConfig,
    conf?: ConfigurationParameters,
) => {
    const accessToken = useAppAuthValue('authToken') || undefined;

    const res = useQuery<GetGetAllBusesResponse, AxiosError>(
        [KeyGetGetAllBuses, options && options.queryKey, accessToken],
        () => new DriverRestControllerApi(new Configuration({ accessToken, ...conf })).getAllBuses(axiosConfig),
        options,
    );
    return { ...res, data: res.data?.data };
};
export type GetGetNextMissionsResponse = AxiosResponse<DriverMissionsDto>;
export const KeyGetGetNextMissions = 'GetGetNextMissions';
export const useMutationGetGetNextMissions = (
    options?: UseMutationOptions<GetGetNextMissionsResponse, AxiosError, DriverRestControllerApiGetNextMissionsRequest>,
    axiosConfig?: AxiosRequestConfig,
) => {
    const accessToken = useAppAuthValue('authToken') || undefined;

    const res = useMutation<GetGetNextMissionsResponse, AxiosError, DriverRestControllerApiGetNextMissionsRequest>(
        (args: DriverRestControllerApiGetNextMissionsRequest) =>
            new DriverRestControllerApi(new Configuration({ accessToken })).getNextMissions(args, axiosConfig),
        options,
    );
    return { ...res, data: res.data?.data };
};
export const useQueryGetGetNextMissions = (
    args: DriverRestControllerApiGetNextMissionsRequest,
    options?: UseQueryOptions<GetGetNextMissionsResponse, AxiosError>,
    axiosConfig?: AxiosRequestConfig,
    conf?: ConfigurationParameters,
) => {
    const accessToken = useAppAuthValue('authToken') || undefined;

    const res = useQuery<GetGetNextMissionsResponse, AxiosError>(
        [KeyGetGetNextMissions, args, options && options.queryKey, accessToken],
        () =>
            new DriverRestControllerApi(new Configuration({ accessToken, ...conf })).getNextMissions(args, axiosConfig),
        options,
    );
    return { ...res, data: res.data?.data };
};
export type PatchUndoCurrentMissionStepResponse = AxiosResponse<DriverMissionSlotDto>;
export const KeyPatchUndoCurrentMissionStep = 'PatchUndoCurrentMissionStep';
export const useMutationPatchUndoCurrentMissionStep = (
    options?: UseMutationOptions<
        PatchUndoCurrentMissionStepResponse,
        AxiosError,
        DriverRestControllerApiUndoCurrentMissionStepRequest
    >,
    axiosConfig?: AxiosRequestConfig,
) => {
    const accessToken = useAppAuthValue('authToken') || undefined;

    const res = useMutation<
        PatchUndoCurrentMissionStepResponse,
        AxiosError,
        DriverRestControllerApiUndoCurrentMissionStepRequest
    >(
        (args: DriverRestControllerApiUndoCurrentMissionStepRequest) =>
            new DriverRestControllerApi(new Configuration({ accessToken })).undoCurrentMissionStep(args, axiosConfig),
        options,
    );
    return { ...res, data: res.data?.data };
};
export const useQueryPatchUndoCurrentMissionStep = (
    args: DriverRestControllerApiUndoCurrentMissionStepRequest,
    options?: UseQueryOptions<PatchUndoCurrentMissionStepResponse, AxiosError>,
    axiosConfig?: AxiosRequestConfig,
    conf?: ConfigurationParameters,
) => {
    const accessToken = useAppAuthValue('authToken') || undefined;

    const res = useQuery<PatchUndoCurrentMissionStepResponse, AxiosError>(
        [KeyPatchUndoCurrentMissionStep, args, options && options.queryKey, accessToken],
        () =>
            new DriverRestControllerApi(new Configuration({ accessToken, ...conf })).undoCurrentMissionStep(
                args,
                axiosConfig,
            ),
        options,
    );
    return { ...res, data: res.data?.data };
};

import React, { memo, useCallback } from 'react';
import { SquareButton } from '../../../../../components/Button/SquareButton/SquareButton';
import { FormattedMessage } from 'react-intl';
import ChevronLeft from '@mui/icons-material/ChevronLeft';
import ChevronRight from '@mui/icons-material/ChevronRight';
import { MissionStepDto } from '../../../../../backend/gen';
import { StyledStepButtonsBox } from './DriverCurrentMissionControls.style';
import { useConfirmDialog } from '../../../../../components/confirm/useConfirmDialog';
import {
    driverDialogCancellationButtonProps,
    driverDialogConfirmationButtonProps,
    driverDialogDialogProps,
    driverDialogTitleProps,
} from '../../../driver.utils';

export const DriverCurrentMissionControls = memo(function DriverCurrentMissionControls({
    currentMissionSteps,
    onClickGoToNextStep,
    onClickGoToPreviousStep,
}: {
    currentMissionSteps: Array<MissionStepDto>;
    onClickGoToNextStep: () => void;
    onClickGoToPreviousStep: () => void;
}) {
    const openCompleteMissionDialog = useConfirmDialog();
    const onClickOpenCompleteMissionDialog = useCallback(() => {
        openCompleteMissionDialog({
            title: <FormattedMessage id={'currentMission.complete.title'} />,
            content: <FormattedMessage id={'currentMission.complete.content'} />,
            titleProps: driverDialogTitleProps,
            dialogProps: driverDialogDialogProps,
            cancellationButtonProps: driverDialogCancellationButtonProps,
            confirmationButtonProps: driverDialogConfirmationButtonProps,
        }).then(onClickGoToNextStep);
    }, [onClickGoToNextStep, openCompleteMissionDialog]);

    if (currentMissionSteps[currentMissionSteps.length - 1].done) return null;

    return (
        <>
            {/* If first step has done===false, mission must be accepted */}
            {/* If last step has done===true, mission is terminated */}
            {/* Else, mission is in-progress -> show controls */}
            {!currentMissionSteps[0].done ? (
                <StyledStepButtonsBox>
                    <SquareButton color="primary" size="large" variant="contained" onClick={onClickGoToNextStep}>
                        <FormattedMessage id="currentMission.accept" />
                    </SquareButton>
                </StyledStepButtonsBox>
            ) : (
                <StyledStepButtonsBox>
                    <SquareButton
                        color="primary"
                        size="large"
                        variant="text"
                        startIcon={<ChevronLeft />}
                        onClick={onClickGoToPreviousStep}
                    >
                        <FormattedMessage id="currentMission.previous" />
                    </SquareButton>
                    <SquareButton
                        color="primary"
                        size="large"
                        variant="contained"
                        endIcon={<ChevronRight />}
                        onClick={
                            currentMissionSteps[currentMissionSteps.length - 2].done
                                ? onClickOpenCompleteMissionDialog
                                : onClickGoToNextStep
                        }
                    >
                        <FormattedMessage
                            id={
                                currentMissionSteps[currentMissionSteps.length - 2].done
                                    ? 'currentMission.done'
                                    : 'currentMission.next'
                            }
                        />
                    </SquareButton>
                </StyledStepButtonsBox>
            )}
        </>
    );
});

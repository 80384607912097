import { dialogClasses, DialogProps } from '@mui/material/Dialog';
import { dialogActionsClasses, dialogContentClasses, DialogTitleProps } from '@mui/material';

export const BusUnavailabilityPeriodDialogTitleProps: Partial<DialogTitleProps> = {
    color: '#082940',
    sx: { textAlign: 'center', fontFamily: 'Roboto', fontSize: '18px', lineHeight: '28px' },
};

export const BusUnavailabilityPeriodDialogDialogProps: Partial<DialogProps> = {
    sx: {
        [`& .${dialogClasses.paper}`]: {
            background: 'white',
            padding: '50px',
            height: 'fit-content',
            width: '540px',
            borderRadius: '20px',
            backgroundColor: '#FFFFFF',
            boxShadow: '0 3px 75px 0 rgba(0,0,0,0.5)',
            color: '#082940',
            fontFamily: 'Roboto',
            fontSize: '18px',
        },
        [`& .${dialogContentClasses.root}`]: {
            padding: '0 0 20px 0',
            textAlign: 'center',
            whiteSpace: 'pre-line',
        },
        [`& .${dialogActionsClasses.root}`]: {
            display: 'flex',
            justifyContent: 'center',
        },
    },
};

/*
 * Copyright Innov'ATM all rights reserved.
 * This software is the property of Innov'ATM and may not be used in any manner except under a
 * license agreement signed with Innov'ATM.
 */

import { styled } from '@mui/material';
import { ReactComponent as ArrStepCompleteIcon } from '../../../../../assets/icons/icon-step-complete-purple.svg';
import { ReactComponent as DepStepCompleteIcon } from '../../../../../assets/icons/icon-step-complete-green.svg';

export const StyledToursContainer = styled('div')`
    display: flex;
    & > div:not(:last-child) {
        border-right: 1px solid rgba(172, 172, 172, 1);
        padding-right: 10px;
        margin-right: 10px;
    }
`;

export const StyledStepContainer = styled('div', {
    shouldForwardProp: prop => prop !== 'stepdone' && prop !== 'steptextcolor',
})<{ stepdone: boolean; steptextcolor: string }>`
    display: flex;
    align-items: center;
    gap: 5px;
    color: ${({ stepdone }) => (stepdone ? 'black' : '#ACACAC')};
    & > span {
        color: ${({ steptextcolor }) => steptextcolor};
    }
`;

export const StyledStepPendingCheckbox = styled('div')`
    height: 12px;
    width: 12px;
    border-radius: 50%;
    background-color: #d5dde3;
`;

export const StyledArrStepCompleteIcon = styled(ArrStepCompleteIcon)`
    width: 12px;
    height: 12px;
`;

export const StyledDepStepCompleteIcon = styled(DepStepCompleteIcon)`
    width: 12px;
    height: 12px;
`;

export const StyledStepCompletionTime = styled('span')`
    font-weight: normal;
`;

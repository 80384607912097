/*
 * Copyright Innov'ATM all rights reserved.
 * This software is the property of Innov'ATM and may not be used in any manner except under a
 * license agreement signed with Innov'ATM.
 */

import { useCallback, useEffect, useState } from 'react';

export const useAutoScroll = (currentTimeIndicatorElement: HTMLDivElement) => {
    const [shallAutoScroll, setShallAutoScroll] = useState(true);

    const disableAutoScroll = useCallback(() => {
        setShallAutoScroll(false);
    }, []);

    const enableAutoScroll = useCallback(() => {
        setShallAutoScroll(true);
    }, []);

    useEffect(() => {
        window.addEventListener('wheel', disableAutoScroll, { capture: true });
        return () => {
            window.removeEventListener('wheel', disableAutoScroll, { capture: true });
        };
    }, [disableAutoScroll]);

    // if shallAutoScroll, scrollIntoView on current time indicator element whenever currentTimeInPx is updated
    useEffect(() => {
        if (currentTimeIndicatorElement && shallAutoScroll) {
            currentTimeIndicatorElement.scrollIntoView({
                behavior: 'smooth',
                block: 'center',
                inline: 'center',
            });
        }
    }, [shallAutoScroll, currentTimeIndicatorElement]);

    return { shallAutoScroll, enableAutoScroll, disableAutoScroll };
};

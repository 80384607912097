import React, { memo } from 'react';
import { StyledArrStepDot, StyledDepStepDot, StyledMissionSteps, StyledPendingStepDot } from './StepsIndicator.style';
import { FlightFlow } from '../../../../../backend/gen';

export const StepsIndicator = memo(function StepsIndicator({
    tour,
    nbTours,
    stepsTotal,
    stepsDone,
    flow,
}: {
    tour: number;
    nbTours: number;
    stepsTotal: number;
    stepsDone: number;
    flow: FlightFlow;
}) {
    const iterationSteps = stepsTotal / nbTours;
    const iterationStepsDone =
        stepsDone >= iterationSteps * tour
            ? iterationSteps
            : stepsDone < iterationSteps * (tour - 1)
            ? 0
            : stepsDone % (stepsTotal / nbTours);

    return (
        <StyledMissionSteps>
            {iterationStepsDone > 0 &&
                [...Array(iterationStepsDone)].map((step, i) =>
                    flow === FlightFlow.ARR ? <StyledArrStepDot key={i} /> : <StyledDepStepDot key={i} />,
                )}
            {[...Array(iterationSteps - iterationStepsDone)].map((step, i) => (
                <StyledPendingStepDot key={i} />
            ))}
        </StyledMissionSteps>
    );
});

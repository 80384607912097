import { Box, styled } from '@mui/material';
import { DatePicker } from '../../../../../../../components/form/DatePicker/DatePicker';
import { theme } from '../../../../../../../theme';
import { TimeField } from '../../../../../../../components/form/TimeField/TimeField';

export const StyledBusUnavailabilityPeriodDatePicker = styled(DatePicker)`
    min-height: 0 !important;
    height: 50px;
    & .MuiInputLabel-formControl {
        color: ${theme.palette.blue.darkest} !important;
    }
    & .MuiInputBase-input {
        color: ${theme.palette.blue.darkest} !important;
    }
    & .MuiButtonBase-root {
        color: ${theme.palette.blue.darkest} !important;
    }
    & .MuiInputBase-root {
        background-color: #f1f7fb !important;
    }
`;

export const StyledBusUnavailabilityPeriodTimePicker = styled(TimeField)`
    margin-top: 5px;
    & .MuiFilledInput-root {
        background-color: #f1f7fb !important;
    }
    & .MuiInputBase-input {
        color: ${theme.palette.blue.darkest} !important;
        ::-webkit-calendar-picker-indicator {
            filter: invert(0) !important;
        }
    }
`;

export const StyledBusUnavailabilityPeriodDialogContentContainer = styled(Box)`
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 15px;
    & .MuiFormControl-root {
        min-height: 0 !important;
    }
    & .MuiInputLabel-root {
        display: none !important;
    }
`;

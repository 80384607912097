import { DatePicker as MuiDatePicker, pickersDayClasses } from '@mui/lab';
import { DatePickerProps } from '@mui/lab/DatePicker/DatePicker';
import { formHelperTextClasses, styled, TextFieldProps, ThemeProvider, useTheme } from '@mui/material';
import * as React from 'react';
import { memo, ReactNode } from 'react';
import { ReactComponent as IconCalendar } from '../../../assets/icons/Icon-Calendar.svg';
import { lightTheme } from '../../../theme';
import { defaultLocale } from '../../../utils/constants';
import { TextField } from '../TextField/TextField';
import { useIntlValue } from '@innovatm/js-iam';
import { localeToDatePlaceholder } from '../../time/time.utils';
import { pickersYearClasses } from '@mui/lab/YearPicker/PickersYear';

const componentsProps: DatePickerProps<Date>['components'] = {
    OpenPickerIcon: () => <IconCalendar />,
};

const StyledTextField = styled(TextField)`
    height: 90px;
    width: 100%;
    & .${formHelperTextClasses.root} {
        color: ${({ theme }) => theme.palette.error.main};
    }
`;

export const DatePicker = memo(function DatePicker({
    label,
    value,
    required,
    className,
    renderInput,
    error,
    helperText,
    ...rest
}: {
    label?: ReactNode | string;
    className?: string;
    renderInput?: any;
    required?: boolean;
} & Omit<DatePickerProps<Date>, 'renderInput' | 'label' | 'className'> &
    Pick<TextFieldProps, 'error' | 'helperText'>) {
    const locale = useIntlValue('locale');
    // @mui DatePicker doesn't yet allow customizing the dialog or the calendar
    const originalTheme = useTheme();

    return (
        <ThemeProvider theme={lightTheme}>
            <MuiDatePicker
                {...rest}
                label={label}
                value={value || null}
                PaperProps={{
                    sx: {
                        [`& .${pickersDayClasses.root}.Mui-selected:focus, & .${pickersDayClasses.root}.Mui-selected:hover, & .${pickersYearClasses.yearButton}.Mui-selected:focus, & .${pickersYearClasses.yearButton}.Mui-selected:hover`]:
                            {
                                backgroundColor: theme => theme.palette.primary.light,
                                color: theme => theme.palette.secondary.light,
                            },
                    },
                }}
                OpenPickerButtonProps={{
                    sx: { marginRight: 0 },
                }}
                renderInput={(params: any) => (
                    <ThemeProvider theme={originalTheme}>
                        {renderInput || (
                            <StyledTextField
                                className={className}
                                required={required}
                                error={error}
                                helperText={helperText}
                                {...params}
                                inputProps={{
                                    ...params.inputProps,
                                    placeholder:
                                        localeToDatePlaceholder[locale] || localeToDatePlaceholder[defaultLocale],
                                }}
                            />
                        )}
                    </ThemeProvider>
                )}
                components={componentsProps}
            />
        </ThemeProvider>
    );
});

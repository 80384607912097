/*
 * Copyright Innov'ATM all rights reserved.
 * This software is the property of Innov'ATM and may not be used in any manner except under a
 * license agreement signed with Innov'ATM.
 */

import { GanttPeriodChangeDialogActions } from './GanttPeriodChangeDialogActions';
import React, { useCallback, useMemo, useState } from 'react';
import { GanttPeriodChangeDialogContent } from './GanttPeriodChangeDialogContent';
import { endOfDay, startOfDay, subDays } from 'date-fns';
import { FormattedMessage } from 'react-intl';
import { Box, styled } from '@mui/material';
import { theme } from '../../../../../theme';
import { ReactComponent as InfoIcon } from '../../../../../assets/icons/Icon-Information.svg';

interface GanttPeriodChangeDialogContentAndActionsContainerProps {
    closeDialog: () => void;
    from: number;
    to: number;
    setTactical: () => void;
    setCustom: (from: number, to: number) => void;
}

export function GanttPeriodChangeDialogContentAndActionsContainer({
    closeDialog,
    from,
    to,
    setTactical,
    setCustom,
}: GanttPeriodChangeDialogContentAndActionsContainerProps) {
    const today = new Date();
    const yesterday = subDays(today, 1).getTime();
    const [formFrom, setFormFrom] = useState<number>(from);
    const [formTo, setFormTo] = useState<number>(to > yesterday ? yesterday : to);
    const onClickSetTactical = useCallback(() => {
        setTactical();
        closeDialog();
    }, [closeDialog, setTactical]);
    const onSubmitSetCustom = useCallback(() => {
        setCustom(startOfDay(formFrom).getTime(), endOfDay(formTo).getTime());
        closeDialog();
    }, [closeDialog, formFrom, formTo, setCustom]);

    const datesIncoherent = useMemo(
        () => startOfDay(formFrom) > startOfDay(formTo) || !formFrom || !formTo,
        [formFrom, formTo],
    );

    return (
        <>
            <GanttPeriodChangeDialogContent
                formFrom={formFrom}
                formTo={formTo}
                setFormFrom={setFormFrom}
                setFormTo={setFormTo}
                onClickSetTactical={onClickSetTactical}
                today={today}
            />
            <GanttPeriodChangeDialogActions
                closeDialog={closeDialog}
                onSubmitSetCustom={onSubmitSetCustom}
                disabled={datesIncoherent}
            />
            {datesIncoherent && (
                <StyledPeriodErrorInfo className="invalid">
                    <StyledInfoIcon />
                    <FormattedMessage id={'dispatcher.ganttPeriod.incoherentDates'} />
                </StyledPeriodErrorInfo>
            )}
        </>
    );
}

const StyledPeriodErrorInfo = styled(Box)`
    font-size: 12px;
    margin-top: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 5px;
    &.invalid {
        color: ${theme.palette.error.main};
        svg {
            stroke: ${theme.palette.error.main};
        }
    }
`;

const StyledInfoIcon = styled(InfoIcon)`
    stroke: gray;
    width: 30px;
    height: 30px;
`;

import { Box, styled } from '@mui/material';
import { headerHeightInPx } from '../../../utils/constants';

export const LeftComponentsHeader = styled(Box)`
    display: flex;
    justify-content: flex-start;
    flex: 40%;
`;

export const MiddleComponentsHeader = styled(Box)`
    display: flex;
    justify-content: center;
    flex: 20%;
`;

export const RightComponentsHeader = styled(Box)`
    display: flex;
    justify-content: flex-end;
    flex: 40%;
`;

export const Header = styled('header')`
    background-color: ${({ theme }) => theme.palette.blue.darkest};
    padding: 0 30px;
    height: ${headerHeightInPx}px;
    width: 100%;
    color: #ffffff;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-shrink: 0;
`;

import { memo } from 'react';
import { useFormattedDate } from './useFormattedDate';

export const FormattedDate = memo(function FormattedDate({
    children,
    format,
}: {
    children: number | Date;
    format?: string;
}) {
    const formatDate = useFormattedDate();

    return <>{formatDate(children, format)}</>;
});

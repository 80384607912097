import { Box, styled } from '@mui/material';

export const StyledNextMissionsTitle = styled(Box)`
    color: white;
    font-family: Roboto, serif;
    font-size: 30px;
    font-weight: bold;
    text-align: center;
    margin: 30px 0;
`;

export const StyledNextMissionsList = styled(Box)`
    display: flex;
    flex-direction: column;
    gap: 30px;
    height: 615px;
    overflow-y: auto;
`;

export const StyledNoMissionsContainer = styled(Box)`
    display: flex;
    height: 100%;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    gap: 10px;
    color: white;
    padding-bottom: 125px;
`;

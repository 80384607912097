import React, { memo } from 'react';
import { PeriodType } from '../../BusUnavailabilityPeriodDialog';
import { StyledInfoIcon, StyledUnavailabilityPeriodInfo } from './UnavailabilityPeriodInfo.style';
import { FormattedMessage } from 'react-intl';

export const UnavailabilityPeriodInfo = memo(function UnavailabilityPeriodInfo({ period }: { period: PeriodType }) {
    if (!period.start && !period.end) {
        // VALID -> Bus will become available
        return (
            <StyledUnavailabilityPeriodInfo>
                <StyledInfoIcon />
                <FormattedMessage id={'dispatcher.busUnavailabilityPeriod.busAvailableInfo'} />
            </StyledUnavailabilityPeriodInfo>
        );
    } else if (!!(period.start && !period.end) || !!(!period.start && period.end)) {
        // INVALID
        return (
            <StyledUnavailabilityPeriodInfo className="invalid">
                <StyledInfoIcon />
                <FormattedMessage id={'dispatcher.busUnavailabilityPeriod.missingDateInfo'} />
            </StyledUnavailabilityPeriodInfo>
        );
    } else if (period.end < period.start || period.start > period.end) {
        // INVALID
        return (
            <StyledUnavailabilityPeriodInfo className="invalid">
                <StyledInfoIcon />
                <FormattedMessage id={'dispatcher.busUnavailabilityPeriod.datesIncoherent'} />
            </StyledUnavailabilityPeriodInfo>
        );
    } else {
        // VALID -> Bus will become unavailable & flights in period will be unallocated
        return (
            <StyledUnavailabilityPeriodInfo>
                <StyledInfoIcon />
                <FormattedMessage id={'dispatcher.busUnavailabilityPeriod.flightsUnallocationInfo'} />
            </StyledUnavailabilityPeriodInfo>
        );
    }
});

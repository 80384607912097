import React, { memo, useCallback } from 'react';
import { CustomMenuButton } from '../../../../../components/CustomMenu/CustomMenuButton';
import { CustomMenuStyles } from '../../../../../components/CustomMenu/CustomMenu.style';
import { PopoverMenu } from '../../../../../components/navigation/PopoverMenu/PopoverMenu';
import { FormattedMessage } from 'react-intl';
import { MenuItem } from '@mui/material';

export const BusGanttMenu = memo(function BusGanttMenu({
    onClickOpenUnavailabilityPeriodDialog,
    onClickOpenDefineVacationDialog,
}: {
    onClickOpenUnavailabilityPeriodDialog: () => void;
    onClickOpenDefineVacationDialog: () => void;
}) {
    const onClickDefineUnavailability = useCallback(
        (close: () => void) => {
            onClickOpenUnavailabilityPeriodDialog();
            close();
        },
        [onClickOpenUnavailabilityPeriodDialog],
    );
    const onClickDefineVacation = useCallback(
        (close: () => void) => {
            onClickOpenDefineVacationDialog();
            close();
        },
        [onClickOpenDefineVacationDialog],
    );

    return (
        <>
            <PopoverMenu
                renderButton={({ onClick }) => <CustomMenuButton onClick={onClick} />}
                menuProps={{
                    anchorOrigin: { vertical: 'top', horizontal: 'right' },
                    transformOrigin: { vertical: 'top', horizontal: 'left' },
                    disableAutoFocusItem: true,
                }}
                menuStyles={CustomMenuStyles}
                disablePortal={false}
            >
                {({ close }) => [
                    <MenuItem key={1} onClick={() => onClickDefineUnavailability(close)}>
                        <FormattedMessage id={'dispatcher.ganttBusMenu.defineUnavailability'} />
                    </MenuItem>,
                    <MenuItem key={2} onClick={() => onClickDefineVacation(close)}>
                        <FormattedMessage id={'dispatcher.ganttBusMenu.defineVacation'} />
                    </MenuItem>,
                ]}
            </PopoverMenu>
        </>
    );
});

/*
 * Copyright Innov'ATM all rights reserved.
 * This software is the property of Innov'ATM and may not be used in any manner except under a
 * license agreement signed with Innov'ATM.
 */

import { Box, styled } from '@mui/material';
import { FormattedMessage } from 'react-intl';
import { useUiValue } from '../../../../contexts/UiContext';
import { FormattedDate } from '../../../../components/time/FormattedDate';
import { useIntlValue } from '@innovatm/js-iam';
import { defaultLocale } from '../../../../utils/constants';
import { localeToDateFormat } from '../../../../components/time/time.utils';

export function GanttPeriod({
    setGanttPeriodDialogOpen,
    from,
    to,
}: {
    setGanttPeriodDialogOpen?: () => void;
    from?: number;
    to?: number;
}) {
    const isTactical = useUiValue('isTacticalView');
    const locale = useIntlValue('locale');
    const dateFormat = localeToDateFormat[locale || defaultLocale];

    return (
        <>
            <StyledPeriodDisplayContainer onClick={setGanttPeriodDialogOpen}>
                {isTactical ? (
                    <FormattedMessage id={'dispatcher.ganttPeriod.tacticalView'} />
                ) : !!from && !!to ? (
                    <StyledDateContainer>
                        <div>
                            <FormattedDate format={dateFormat}>{from}</FormattedDate>
                        </div>
                        <div>-</div>
                        <div>
                            <FormattedDate format={dateFormat}>{to}</FormattedDate>
                        </div>
                    </StyledDateContainer>
                ) : (
                    <FormattedMessage id={'dispatcher.ganttPeriod.historicalView'} />
                )}
            </StyledPeriodDisplayContainer>
        </>
    );
}

const StyledPeriodDisplayContainer = styled(Box)`
    height: 50px;
    min-width: 100px;
    border-radius: 25px;
    background-color: #18537b;
    color: #ffffff;
    padding: 7px 7px 6px;
    text-align: center;
    font-family: 'Saira Condensed', sans-serif;
    font-weight: bold;
    font-size: 18px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: ${({ onClick }) => (onClick ? 'pointer' : '')};
`;

const StyledDateContainer = styled('div')`
    display: flex;
    gap: 2px;
`;

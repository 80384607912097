import React, { memo, useState } from 'react';
import { BusUnavailabilityPeriodDialogContent } from './BusUnavailabilityPeriodDialogContent/BusUnavailabilityPeriodDialogContent';
import { BusUnavailabilityPeriodDialogActions } from './BusUnavailabilityPeriodDialogActions/BusUnavailabilityPeriodDialogActions';
import { PeriodType } from '../BusUnavailabilityPeriodDialog';
import { DispatcherBusDto } from '../../../../../../backend/gen';

export const BusUnavailabilityPeriodDialogContentAndActionsContainer = memo(
    function BusUnavailabilityPeriodDialogContentAndActionsContainer({
        bus,
        closeDialog,
    }: {
        bus: DispatcherBusDto;
        closeDialog: () => void;
    }) {
        const [period, setPeriod] = useState<PeriodType>({
            start: bus.unavailability?.start ? bus.unavailability.start : Date.now(),
            end: bus.unavailability?.end ? bus.unavailability.end : null,
        });

        return (
            <>
                <BusUnavailabilityPeriodDialogContent period={period} setPeriod={setPeriod} />
                <BusUnavailabilityPeriodDialogActions
                    closeDialog={closeDialog}
                    period={period}
                    setPeriod={setPeriod}
                    bus={bus}
                />
            </>
        );
    },
);

import React, { memo } from 'react';
import { CustomMenuButton } from '../../../../../components/CustomMenu/CustomMenuButton';
import { PopoverMenu } from '../../../../../components/navigation/PopoverMenu/PopoverMenu';
import { StripFlightMenuContents } from './StripFlightMenuContents/StripFlightMenuContents';
import { FlightFlow } from '../../../../../backend/gen';
import { CustomMenuStyles } from '../../../../../components/CustomMenu/CustomMenu.style';

export const StripFlightMenu = memo(function StripFlightMenu({
    showMenuButton,
    flightId,
    missionId,
    flightFlow,
    flightNbTours,
    flightIsManual,
    onClickOpenSpecifyDropoffLocationDialog,
    isMissionTerminated,
}: {
    showMenuButton: {
        value: boolean;
        setValue: React.Dispatch<React.SetStateAction<boolean>>;
        toggle: () => void;
        setTrue: () => void;
        setFalse: () => void;
    };
    flightId: string;
    missionId: string;
    flightFlow: FlightFlow;
    flightNbTours?: number;
    flightIsManual: boolean;
    onClickOpenSpecifyDropoffLocationDialog: () => void;
    isMissionTerminated: boolean;
}) {
    return (
        <PopoverMenu
            renderButton={({ onClick }) => showMenuButton.value && <CustomMenuButton onClick={onClick} />}
            menuProps={{
                anchorOrigin: { vertical: 'top', horizontal: 'right' },
                transformOrigin: { vertical: 'top', horizontal: 'left' },
            }}
            onClose={showMenuButton.setFalse}
            menuStyles={CustomMenuStyles}
            disablePortal={false}
        >
            {({ close }) => (
                <StripFlightMenuContents
                    close={close}
                    flightId={flightId}
                    flightFlow={flightFlow}
                    flightNbTours={flightNbTours}
                    missionId={missionId}
                    flightIsManual={flightIsManual}
                    onClickOpenSpecifyDropoffLocationDialog={onClickOpenSpecifyDropoffLocationDialog}
                    isMissionTerminated={isMissionTerminated}
                />
            )}
        </PopoverMenu>
    );
});

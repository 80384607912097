import React, { memo } from 'react';
import { FlightFlow, useQueryGetGetFlightInfo } from '../../../backend/gen';
import { StyledBusDisplayContainer, StyledBusDisplayContentContainer, StyledNoActiveFlight } from './BusDisplay.style';
import { FormattedMessage } from 'react-intl';
import { BusDisplayHeader } from './BusDisplayHeader/BusDisplayHeader';
import { BusDisplayFooter } from './BusDisplayFooter/BusDisplayFooter';
import { BusDisplayContentArr } from './BusDisplayContentArrival/BusDisplayContentArr';
import { BusDisplayContentDep } from './BusDisplayContentDeparture/BusDisplayContentDep';

const queryString = require('query-string');

export const BusDisplay = memo(function BusDisplay() {
    const token = queryString.parse(window.location.search).token;

    const { data, status } = useQueryGetGetFlightInfo(
        { token: token },
        {
            refetchInterval: 4000,
        },
    );

    if (!token || status === 'error') return <div>Invalid token</div>;

    return (
        <StyledBusDisplayContainer>
            <BusDisplayHeader />
            <StyledBusDisplayContentContainer>
                {!data?.flow ? (
                    <StyledNoActiveFlight>
                        <FormattedMessage id={'busDisplay.noActiveFlight'} />
                    </StyledNoActiveFlight>
                ) : data.flow === FlightFlow.ARR ? (
                    <BusDisplayContentArr flightInfo={data} />
                ) : (
                    <BusDisplayContentDep flightInfo={data} />
                )}
            </StyledBusDisplayContentContainer>
            <BusDisplayFooter flow={data?.flow} />
        </StyledBusDisplayContainer>
    );
});

import { ButtonProps } from '@mui/material/Button';
import { buttonClasses } from '@mui/material';
import { theme } from '../../../../../../../theme';

export const SpecifyDropoffLocationDialogButtonProps: Partial<ButtonProps> = {
    size: 'medium',
    sx: {
        borderRadius: '25px',
        padding: '16px 30px',
        letterSpacing: '0',
        textTransform: 'none',
        height: '50px',
        fontSize: '15px',
        lineHeight: '18px',
        whiteSpace: 'nowrap',
        [`&.${buttonClasses.text}`]: {
            border: '1px solid rgba(12, 69, 108, 0.5)',
        },
        [`&.${buttonClasses.outlined}`]: {
            color: theme => theme.palette.blue.lightest,
            borderColor: '#245f88',
        },
    },
};

export const CancellationButtonProps: Partial<ButtonProps> = {
    ...SpecifyDropoffLocationDialogButtonProps,
    sx: {
        ...SpecifyDropoffLocationDialogButtonProps.sx,
        color: theme.palette.blue.dark,
    },
    variant: 'text',
};

export const ConfirmationButtonProps: Partial<ButtonProps> = {
    ...SpecifyDropoffLocationDialogButtonProps,
    sx: {
        ...SpecifyDropoffLocationDialogButtonProps.sx,
        [`&.Mui-disabled`]: {
            backgroundColor: theme.palette.blue.lightest,
        },
    },
    variant: 'contained',
};

import React, { memo, useCallback, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import {
    FlightDto,
    KeyGetGetAllMissions,
    useMutationPostConsiderFlightForBusAllocation,
} from '../../../../backend/gen';
import { useDateFnsFormat } from '../../../../hooks/useDateFnsFormat';
import { Autocomplete } from '@mui/material';
import {
    createMissionAutocompleteComponentProps,
    createMissionAutocompleteTextFieldStyles,
    StyledCreateMissionFormContainer,
    StyledCreateMissionFormSubmitButtonContainer,
} from './CreateMissionForm.style';
import { RoundButton } from '../../../../components/Button/RoundButton/RoundButton';
import { toast } from 'react-toastify';
import { errorToastConfig } from '../../../../utils/constants';
import { useQueryClient } from 'react-query';
import { TextField } from '../../../../components/form/TextField/TextField';

type FlightOptionType = {
    label: string;
    id: string;
    flightNumber: string;
};

export const CreateMissionForm = memo(function CreateMissionForm({ data }: { data: Array<FlightDto> }) {
    const [selectedFlight, setSelectedFlight] = useState<FlightOptionType>(null);
    const format = useDateFnsFormat();
    const queryClient = useQueryClient();
    const intl = useIntl();
    const flightList: Array<FlightOptionType> = data?.map(flight => ({
        label: `${flight.flightNumber} ${format(flight.refTime, 'P').substring(0, 5)} ${format(
            flight.refTime,
            'HH:mm',
        )}`,
        id: flight.flightId,
        flightNumber: flight.flightNumber,
    }));

    const { mutateAsync: createMissionFromFlight } = useMutationPostConsiderFlightForBusAllocation();
    const onClickCreateMissionFromFlight = useCallback(() => {
        if (selectedFlight) {
            createMissionFromFlight({ flight: selectedFlight.id })
                .then(() => {
                    setSelectedFlight(null);
                    queryClient.invalidateQueries(KeyGetGetAllMissions);
                })
                .catch(() => toast(<FormattedMessage id={'dispatcher.createMissionRequestError'} />, errorToastConfig));
        }
    }, [createMissionFromFlight, queryClient, selectedFlight]);

    return (
        <StyledCreateMissionFormContainer>
            <Autocomplete
                componentsProps={createMissionAutocompleteComponentProps}
                renderInput={params => (
                    <TextField
                        {...params}
                        formControlProps={createMissionAutocompleteTextFieldStyles}
                        name={'createMissionFormField'}
                        placeholder={intl.formatMessage({ id: 'dispatcher.createMissionLabel' })}
                        required
                    />
                )}
                options={flightList}
                noOptionsText={intl.formatMessage({ id: 'dispatcher.createMissionNoOptionsText' })}
                renderOption={(props, option) => (
                    <li {...props} key={option.id}>
                        {option.label}
                    </li>
                )}
                value={selectedFlight}
                onChange={(event: any, newValue: FlightOptionType) => setSelectedFlight(newValue)}
                isOptionEqualToValue={(option: FlightOptionType, value: FlightOptionType) => option.id === value.id}
                autoHighlight
                clearOnEscape
            />
            <StyledCreateMissionFormSubmitButtonContainer>
                <RoundButton
                    size={'small'}
                    variant={'contained'}
                    color={'primary'}
                    disabled={!selectedFlight}
                    onClick={onClickCreateMissionFromFlight}
                >
                    OK
                </RoundButton>
            </StyledCreateMissionFormSubmitButtonContainer>
        </StyledCreateMissionFormContainer>
    );
});

import React, { memo, useCallback, useState } from 'react';
import { DriverMissionSlotDto, useQueryGetGetNextMissions } from '../../../backend/gen';
import { useParams } from 'react-router-dom';
import { ProfileType, useAuthorizeProfile } from '../../../hooks/useAuthorizeProfile';
import { useCurrentUser } from '../../../contexts/AuthContext.selectors';
import {
    StyledCurrentMissionContainer,
    StyledDriverMissionsPageContainer,
    StyledNextMissionsContainer,
    StyledNoMissionsContainer,
} from './DriverMissionsPage.styles';
import { DriverNextMissions } from './DriverNextMissions/DriverNextMissions';
import { Typography } from '@mui/material';
import { DriverCurrentMission } from './DriverCurrentMission/DriverCurrentMission';
import { ReactComponent as MissionListArrow } from '../../../assets/images/arrow-mission-list.svg';
import { DriverMissionsPageHeader } from './DriverMissionsPageHeader/DriverMissionsPageHeader';
import { FormattedMessage } from 'react-intl';
import { toast } from 'react-toastify';
import { errorToastConfig } from '../../../utils/constants';

const sleepyBusImg = require('../../../assets/images/Bus_Sleepy_Large.png');

export const DriverMissionsPage = memo(function DriverMissionsPage() {
    const currentUser = useCurrentUser();
    useAuthorizeProfile(currentUser, ProfileType.DRIVER);
    const busName = useParams<{ busNumber?: string }>()?.busNumber;

    const [selectedMission, setSelectedMission] = useState<DriverMissionSlotDto>();
    const onClickSetSelectedMission = useCallback((mission: DriverMissionSlotDto | null) => {
        setSelectedMission(mission);
    }, []);

    const { data, status } = useQueryGetGetNextMissions(
        {
            bus: busName,
        },
        {
            onSuccess: data => {
                if (data.data.current) setSelectedMission(data.data.current);
            },
            onError: () => toast(<FormattedMessage id={'bus.error.getMissions'} />, errorToastConfig),
            refetchInterval: 4000,
        },
    );

    if (status === 'error' || !data) {
        return <div> Failed to fetch bus missions </div>;
    }

    return (
        <>
            <DriverMissionsPageHeader bus={busName} activeDrivers={data?.activeVacations || []} />
            <StyledDriverMissionsPageContainer>
                {!data.current && data.next.length === 0 ? (
                    <StyledNoMissionsContainer>
                        <img alt={'No missions available'} src={sleepyBusImg} />
                        <Typography variant={'h4'}>
                            <FormattedMessage id="DriverMissionsPage.noMissionsForNow" />
                        </Typography>
                    </StyledNoMissionsContainer>
                ) : (
                    <>
                        {!selectedMission && !data.current ? (
                            <StyledNoMissionsContainer>
                                <Typography variant={'h1'}>
                                    <FormattedMessage id="DriverMissionsPage.selectMission" />
                                </Typography>
                                <MissionListArrow />
                            </StyledNoMissionsContainer>
                        ) : (
                            <StyledCurrentMissionContainer>
                                <DriverCurrentMission
                                    driverCurrentMission={data.current ? data.current : selectedMission}
                                    onClickSetSelectedMission={onClickSetSelectedMission}
                                />
                            </StyledCurrentMissionContainer>
                        )}
                        <StyledNextMissionsContainer>
                            <DriverNextMissions
                                driverNextMissions={data.next}
                                onClickMission={data.current ? null : onClickSetSelectedMission}
                            />
                        </StyledNextMissionsContainer>
                    </>
                )}
            </StyledDriverMissionsPageContainer>
        </>
    );
});

import React, { memo, useState } from 'react';
import { SpecifyDropoffLocationDialogContent } from './SpecifyDropoffLocationDialogContent/SpecifyDropoffLocationDialogContent';
import { SpecifyDropoffLocationDialogActions } from './SpecifyDropoffLocationDialogActions/SpecifyDropoffLocationDialogActions';

export const SpecifyDropoffLocationDialogContentAndActionsContainer = memo(
    function SpecifyDropoffLocationDialogContentAndActionsContainer({
        flightId,
        dropoffLocation,
        closeDialog,
    }: {
        flightId: string;
        dropoffLocation?: string | null;
        closeDialog: () => void;
    }) {
        const [updatedDropoffLocation, setUpdatedDropoffLocation] = useState<string>(
            dropoffLocation ? dropoffLocation : '',
        );

        return (
            <>
                <SpecifyDropoffLocationDialogContent
                    dropoffLocation={updatedDropoffLocation}
                    setDropoffLocation={setUpdatedDropoffLocation}
                />
                <SpecifyDropoffLocationDialogActions
                    flightId={flightId}
                    dropoffLocation={updatedDropoffLocation}
                    closeDialog={closeDialog}
                />
            </>
        );
    },
);

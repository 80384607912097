import React, { memo } from 'react';
import { FlightSlotDto } from '../../../../backend/gen';
import { StripAircraftPaxInfos } from './StripBusSegments/StripAircraftPaxInfos/StripAircraftPaxInfos';
import { StripDirection } from './StripBusSegments/StripDirection/StripDirection';
import { StripHeader } from './StripBusSegments/StripHeader/StripHeader';
import { StripTimeFlow } from './StripBusSegments/StripTimeFlowInfo/StripTimeFlowInfo';
import { StyledStrip, StyledStripContainer } from './StripBus.style';

export const StripBus = memo(function StripBus({ flightSlotDto }: { flightSlotDto: FlightSlotDto }) {
    if (!flightSlotDto) {
        return null;
    }

    return (
        <StyledStripContainer>
            <StripHeader flight={flightSlotDto} />
            <StyledStrip>
                <StripTimeFlow time={flightSlotDto.refTime} flow={flightSlotDto.flow} />
                <StripAircraftPaxInfos flight={flightSlotDto} />
                <StripDirection flight={flightSlotDto} />
            </StyledStrip>
        </StyledStripContainer>
    );
});

import { Box, styled } from '@mui/material';
import { STRIP_BUS_HEIGHT } from './stripBus.constants';

export const StyledStrip = styled(Box)(({ theme }) => ({
    backgroundColor: theme.palette.primary.light,
    height: STRIP_BUS_HEIGHT,
    borderRadius: '20px',
    filter: 'drop-shadow(0 3px 6px rgba(0,0,0,0.3))',
    display: 'flex',
    alignItems: 'center',
    color: theme.palette.text.primary,
}));

export const StyledStripContainer = styled(Box)`
    display: flex;
    align-items: center;
    flex-direction: column;
`;

export const StyledStripSegment = styled(Box)`
    border-right: 1px dashed #14517b;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`;

/*
 * Copyright Innov'ATM all rights reserved.
 * This software is the property of Innov'ATM and may not be used in any manner except under a
 * license agreement signed with Innov'ATM.
 */

import { useEffect, useState } from 'react';
import { calculateGanttWidth } from '../DispatcherPage.utils';
import { ganttContentPaddingLeft, ganttPxPerMs, ganttTitleWidth } from '../Gantt/gantt.constants';
import { addDays, differenceInMilliseconds, startOfDay } from 'date-fns';

export const useGanttDimensions = (from: number, to: number) => {
    const [ganttWidth, setGanttWidth] = useState(0);
    const [dayDelimiters, setDayDelimiters] = useState<number[]>([]);

    useEffect(() => {
        setGanttWidth(calculateGanttWidth(from, to, ganttPxPerMs, ganttTitleWidth, ganttContentPaddingLeft));

        const delimiters = [];
        let day = startOfDay(from);
        const toAsDate = new Date(to);
        while (day.getTime() <= toAsDate.getTime()) {
            delimiters.push(
                (
                    differenceInMilliseconds(day, from) * ganttPxPerMs +
                    ganttTitleWidth +
                    ganttContentPaddingLeft
                ).toFixed(0),
            );
            day = addDays(day, 1);
        }
        setDayDelimiters(delimiters);
    }, [from, to]);

    return { ganttWidth, dayDelimiters };
};

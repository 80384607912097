import { Box, styled } from '@mui/material';

export const StyledCurrentMissionContainer = styled(Box)`
    position: relative;
    height: 690px;
    width: 830px;
    border-radius: 20px;
    background-color: white;
    padding: 60px 40px 0 40px;
    display: flex;
    flex-direction: column;
`;

export const StyledDiscardPopupContainer = styled(Box)`
    position: absolute;
    z-index: 1;
    top: 10px;
    right: 10px;
    cursor: pointer;
`;

export const StyledMissionContainer = styled(Box)`
    display: flex;
    align-items: flex-start;
`;

import React, { memo, useCallback } from 'react';
import { Button, DialogActions } from '@mui/material';
import { FormattedMessage } from 'react-intl';
import { useMutationPostUpdateDropOffLocation } from '../../../../../../../backend/gen';
import { CancellationButtonProps, ConfirmationButtonProps } from './SpecifyDropoffLocationDialogActions.style';
import { toast } from 'react-toastify';
import { errorToastConfig } from '../../../../../../../utils/constants';

export const SpecifyDropoffLocationDialogActions = memo(function SpecifyDropoffLocationDialogActions({
    closeDialog,
    dropoffLocation,
    flightId,
}: {
    closeDialog: () => void;
    dropoffLocation?: string | null;
    flightId: string;
}) {
    const onClickCloseDialog = useCallback(() => {
        closeDialog();
    }, [closeDialog]);

    const { mutateAsync: updateDropoffLocation } = useMutationPostUpdateDropOffLocation();
    const onClickUpdateDropoffLocation = useCallback(() => {
        updateDropoffLocation({ flight: flightId, dropOffLocation: dropoffLocation || '' })
            .then(() => closeDialog())
            .catch(() => toast(<FormattedMessage id={'bus.error.specifyDropoff'} />, errorToastConfig));
    }, [closeDialog, dropoffLocation, flightId, updateDropoffLocation]);

    return (
        <DialogActions>
            <Button {...CancellationButtonProps} onClick={onClickCloseDialog}>
                <FormattedMessage id="confirm.default.cancel" />
            </Button>
            <Button {...ConfirmationButtonProps} onClick={onClickUpdateDropoffLocation}>
                <FormattedMessage id="confirm.default.confirm" />
            </Button>
        </DialogActions>
    );
});

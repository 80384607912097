import { Box } from '@mui/material';
import { FlightFlow, FlightSlotDto } from '../../../../../../backend/gen';
import { IcaoIata } from '../../../../../../components/IcaoIata';
import { FormattedMessage } from 'react-intl';
import { StyledStripBusHeaderContainer, StyledStripBusHeaderFlightNumber } from './StripHeader.style';

export const StripHeader = function StripHeader({ flight }: { flight?: FlightSlotDto | null }) {
    return (
        <StyledStripBusHeaderContainer>
            <StyledStripBusHeaderFlightNumber>{flight?.flightNumber}</StyledStripBusHeaderFlightNumber>
            <Box>
                {flight?.flow === FlightFlow.ARR ? <FormattedMessage id="from" /> : <FormattedMessage id="to" />}{' '}
                <IcaoIata {...flight?.adepAdes} />
            </Box>
        </StyledStripBusHeaderContainer>
    );
};

import { Box } from '@mui/material';
import React, { memo } from 'react';
import { ReactComponent as SchengenIcon } from '../../../../../../assets/icons/icon-schengen-small.svg';
import { ReactComponent as GateIcon } from '../../../../../../assets/icons/icon-gate-small.svg';
import { ReactComponent as ParkingIcon } from '../../../../../../assets/icons/icon-parking-small.svg';
import { FlightFlow, FlightSlotDto } from '../../../../../../backend/gen';
import { getFlowColor } from '../../../../../../utils/data.utils';
import {
    StyledStripDirectionContainer,
    StyledStripDirectionIconContainer,
    StyledStripDirectionIconsContainer,
} from './StripDirection.style';

export const StripDirection = memo(({ flight }: { flight: FlightSlotDto }) => {
    const iconFillColor = getFlowColor(flight.flow);

    return (
        <StyledStripDirectionContainer>
            <StyledStripDirectionIconsContainer>
                {flight.flow === FlightFlow.ARR ? (
                    <StyledStripDirectionIconContainer>
                        <ParkingIcon fill={iconFillColor} width={'45px'} height={'45px'} />
                        <Box>{flight?.parking || '-'}</Box>
                    </StyledStripDirectionIconContainer>
                ) : (
                    <StyledStripDirectionIconContainer>
                        <GateIcon fill={iconFillColor} width={'45px'} height={'45px'} />
                        <Box>{flight?.gate || '-'}</Box>
                    </StyledStripDirectionIconContainer>
                )}
                {flight.flow === FlightFlow.ARR ? (
                    <StyledStripDirectionIconContainer>
                        <SchengenIcon fill={iconFillColor} width={'45px'} height={'45px'} />
                        <Box>{flight?.zone || '-'}</Box>
                    </StyledStripDirectionIconContainer>
                ) : (
                    <StyledStripDirectionIconContainer>
                        <ParkingIcon fill={iconFillColor} width={'45px'} height={'45px'} />
                        <Box>{flight?.parking || '-'}</Box>
                    </StyledStripDirectionIconContainer>
                )}
            </StyledStripDirectionIconsContainer>
        </StyledStripDirectionContainer>
    );
});

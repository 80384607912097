import React, { memo } from 'react';
import { CustomDialog } from '../../../../../components/CustomDialog/CustomDialog';
import {
    BusUnavailabilityPeriodDialogDialogProps,
    BusUnavailabilityPeriodDialogTitleProps,
} from './BusUnavailabilityPeriodDialog.style';
import { FormattedMessage } from 'react-intl';
import { DispatcherBusDto } from '../../../../../backend/gen';
import { BusUnavailabilityPeriodDialogContentAndActionsContainer } from './BusUnavailabilityPeriodDialogContentAndActionsContainer/BusUnavailabilityPeriodDialogContentAndActionsContainer';

export type PeriodType = {
    start?: number;
    end?: number;
};

export const BusUnavailabilityPeriodDialog = memo(function BusUnavailabilityPeriodDialog({
    bus,
    open,
    closeDialog,
}: {
    bus: DispatcherBusDto;
    open: boolean;
    closeDialog: () => void;
}) {
    return (
        <CustomDialog
            open={open}
            customDialogProps={BusUnavailabilityPeriodDialogDialogProps}
            title={<FormattedMessage id={'dispatcher.ganttBusMenu.defineUnavailability'} />}
            customTitleProps={BusUnavailabilityPeriodDialogTitleProps}
            contentAndActionsCombined={
                <BusUnavailabilityPeriodDialogContentAndActionsContainer closeDialog={closeDialog} bus={bus} />
            }
        ></CustomDialog>
    );
});

import { buttonClasses, styled } from '@mui/material';
import { RoundButton } from '../RoundButton/RoundButton';

export const SquareButton = styled(RoundButton)`
    border-radius: 10px;
    font-family: Roboto, serif;
    font-weight: 500;
    text-transform: uppercase;
    padding-left: 10px;
    padding-right: 10px;
    &.${buttonClasses.sizeSmall} {
        color: ${({ theme }) => theme.palette.blue.lightest};
        font-size: ${({ theme }) => theme.typography.fontSize}px;
    }
`;

import React, { memo, useCallback } from 'react';
import {
    StyledBusUnavailabilityPeriodDatePicker,
    StyledBusUnavailabilityPeriodDialogContentContainer,
    StyledBusUnavailabilityPeriodTimePicker,
} from './BusUnavailabilityPeriodDialogContent.style';
import { FormattedMessage } from 'react-intl';
import { PeriodType } from '../../BusUnavailabilityPeriodDialog';
import { Box, DialogContent } from '@mui/material';
import { format } from 'date-fns';
import { UnavailabilityPeriodInfo } from './UnavailabilityPeriodInfo';

export const BusUnavailabilityPeriodDialogContent = memo(function BusUnavailabilityPeriodDialogContent({
    period,
    setPeriod,
}: {
    period: PeriodType;
    setPeriod: React.Dispatch<React.SetStateAction<PeriodType>>;
}) {
    const onChangeUpdatePeriodStart = useCallback(
        newStartTime => setPeriod({ ...period, start: newStartTime }),
        [period, setPeriod],
    );
    const onChangeUpdatePeriodEnd = useCallback(
        newEndTime => setPeriod({ ...period, end: newEndTime }),
        [period, setPeriod],
    );

    return (
        <DialogContent>
            <StyledBusUnavailabilityPeriodDialogContentContainer>
                <Box>
                    <StyledBusUnavailabilityPeriodDatePicker
                        value={period.start}
                        onChange={e => setPeriod({ ...period, start: e?.getTime() })}
                        minDate={new Date()}
                        maxDate={period.end ? new Date(period.end) : undefined}
                    />
                    <StyledBusUnavailabilityPeriodTimePicker
                        // Key is used to circumvent TimeField internal state, forcing component to re-render
                        // with defaultValue = period value when period changes
                        key={period.start && format(period.start, 'yyyy-MM-dd')}
                        name={'periodStartTime'}
                        date={period.start ? period.start : new Date()}
                        value={period.start}
                        onChange={onChangeUpdatePeriodStart}
                    />
                </Box>
                <FormattedMessage id={'dispatcher.busUnavailabilityPeriod.separatorText'} />
                <Box>
                    <StyledBusUnavailabilityPeriodDatePicker
                        value={period.end}
                        onChange={e => setPeriod({ ...period, end: e?.getTime() })}
                        minDate={period.start ? new Date(period.start) : new Date()}
                    />
                    <StyledBusUnavailabilityPeriodTimePicker
                        // Key is used to circumvent TimeField internal state, forcing component to re-render
                        // with defaultValue = period value when period changes
                        key={period.end && format(period.end, 'yyyy-MM-dd')}
                        name={'periodEndTime'}
                        date={period.end ? period.end : period.start ? period.start : new Date()}
                        value={period.end}
                        onChange={onChangeUpdatePeriodEnd}
                    />
                </Box>
            </StyledBusUnavailabilityPeriodDialogContentContainer>
            <UnavailabilityPeriodInfo period={period} />
        </DialogContent>
    );
});

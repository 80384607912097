import React, { memo, useCallback, useMemo } from 'react';
import {
    FlightFlow,
    KeyGetGetAllMissions,
    useMutationDeleteDiscardFlightFromBusAllocation,
    useMutationDeleteUnallocateBus,
    useMutationPatchUpdateNumberOfTours,
    useMutationPostAllocateBus,
    useMutationPostDuplicateToBus,
    useMutationPostReallocateBus,
} from '../../../../../../backend/gen';
import { useCurrentUser } from '../../../../../../contexts/AuthContext.selectors';
import { MenuItem } from '@mui/material';
import { useQueryClient } from 'react-query';
import { StripFlightMenuContentsToursMenuItem } from './StripFlightMenuContentsToursMenuItem';
import { PopoverMenu } from '../../../../../../components/navigation/PopoverMenu/PopoverMenu';
import { CustomMenuStyles } from '../../../../../../components/CustomMenu/CustomMenu.style';
import { FormattedMessage } from 'react-intl';
import { toast } from 'react-toastify';
import { errorToastConfig } from '../../../../../../utils/constants';

export const StripFlightMenuContents = memo(function StripFlightMenuContents({
    close,
    flightId,
    missionId,
    flightFlow,
    flightNbTours,
    flightIsManual,
    onClickOpenSpecifyDropoffLocationDialog,
    isMissionTerminated,
}: {
    close: () => void;
    flightId: string;
    missionId?: string;
    flightFlow: FlightFlow;
    flightNbTours?: number;
    flightIsManual: boolean;
    onClickOpenSpecifyDropoffLocationDialog: () => void;
    isMissionTerminated: boolean;
}) {
    const currentUser = useCurrentUser();
    const queryClient = useQueryClient();

    // Route to allocate mission to bus
    const { mutateAsync: allocateBus } = useMutationPostAllocateBus();
    const onClickAllocateBus = useCallback(
        (bus: string) => {
            allocateBus({ flight: flightId, bus: bus })
                .then(() => {
                    close();
                    queryClient.invalidateQueries(KeyGetGetAllMissions);
                })
                .catch(() => {
                    close();
                    toast(<FormattedMessage id={'bus.error.allocateBus'} values={{ bus: bus }} />, errorToastConfig);
                });
        },
        [allocateBus, close, flightId, queryClient],
    );

    // Route to delete manually created mission
    const { mutateAsync: deleteMission } = useMutationDeleteDiscardFlightFromBusAllocation();
    const onClickDeleteMission = useCallback(() => {
        deleteMission({ flight: flightId })
            .then(() => {
                close();
                queryClient.invalidateQueries(KeyGetGetAllMissions);
            })
            .catch(() => {
                close();
                toast(<FormattedMessage id={'bus.error.deleteManual'} />, errorToastConfig);
            });
    }, [close, deleteMission, flightId, queryClient]);

    // Route to reallocate mission to different bus
    const { mutateAsync: reallocateBus } = useMutationPostReallocateBus();
    const onClickReallocateBus = useCallback(
        (bus: string) => {
            reallocateBus({ bus: bus, mission: missionId })
                .then(() => {
                    close();
                    queryClient.invalidateQueries(KeyGetGetAllMissions);
                })
                .catch(() => {
                    close();
                    toast(<FormattedMessage id={'bus.error.reallocateBus'} values={{ bus: bus }} />, errorToastConfig);
                });
        },
        [close, missionId, queryClient, reallocateBus],
    );

    // Route to unallocate mission from bus
    const { mutateAsync: unallocateMission } = useMutationDeleteUnallocateBus();
    const onClickUnallocateMission = useCallback(() => {
        unallocateMission({ mission: missionId })
            .then(() => {
                close();
                queryClient.invalidateQueries(KeyGetGetAllMissions);
            })
            .catch(() => {
                close();
                toast(<FormattedMessage id={'bus.error.unallocateBus'} />, errorToastConfig);
            });
    }, [close, missionId, queryClient, unallocateMission]);

    // Route to update mission's number of tours
    const { mutateAsync: updateMissionNbTours } = useMutationPatchUpdateNumberOfTours();
    const onConfirmUpdateMissionNbTours = useCallback(
        (tours: number) => {
            updateMissionNbTours({ tours: tours, mission: missionId })
                .then(() => {
                    close();
                    queryClient.invalidateQueries(KeyGetGetAllMissions);
                })
                .catch(() => {
                    close();
                    toast(<FormattedMessage id={'bus.error.updateNbTours'} />, errorToastConfig);
                });
        },
        [close, missionId, queryClient, updateMissionNbTours],
    );

    // Route to duplicate mission to bus
    const { mutateAsync: duplicateMissionToBus } = useMutationPostDuplicateToBus();
    const onClickDuplicateMissionToBus = useCallback(
        (bus: string) => {
            duplicateMissionToBus({ bus: bus, mission: missionId })
                .then(() => {
                    close();
                    queryClient.invalidateQueries(KeyGetGetAllMissions);
                })
                .catch(() => {
                    close();
                    toast(
                        <FormattedMessage id={'bus.error.duplicateMissionToBus'} values={{ bus: bus }} />,
                        errorToastConfig,
                    );
                });
        },
        [close, duplicateMissionToBus, missionId, queryClient],
    );

    const menuItems = useMemo(() => {
        const list = [];
        const isArrival = flightFlow === FlightFlow.ARR;

        // Open drop-off modal
        if (isArrival && !isMissionTerminated)
            list.push(
                <MenuItem
                    onClick={() => {
                        onClickOpenSpecifyDropoffLocationDialog();
                        close();
                    }}
                >
                    <FormattedMessage id={'stripFlight.menu.specifyDropOff'} />
                </MenuItem>,
            );

        // List all buses & allocate onClick
        if (!flightNbTours)
            currentUser.client.buses.map((bus, index) =>
                list.push(
                    <MenuItem onClick={() => onClickAllocateBus(bus)} key={index}>
                        <FormattedMessage id={'stripFlight.menu.allocate'} values={{ bus: bus }} />
                    </MenuItem>,
                ),
            );

        // If mission was manually created from a flight & is unallocated, allow user to delete
        if (!flightNbTours && flightIsManual && !isMissionTerminated) {
            list.push(
                <MenuItem onClick={onClickDeleteMission}>
                    <FormattedMessage id={'stripFlight.menu.deleteManual'} />
                </MenuItem>,
            );
        }

        // List all buses in new popover & duplicate onClick
        if (flightNbTours)
            list.push(
                <PopoverMenu
                    renderButton={({ onClick }) => (
                        <MenuItem onClick={onClick}>
                            <FormattedMessage id={'stripFlight.menu.duplicate'} />
                        </MenuItem>
                    )}
                    menuProps={{
                        anchorOrigin: { vertical: 'top', horizontal: 'right' },
                        transformOrigin: { vertical: 'top', horizontal: 'left' },
                    }}
                    menuStyles={CustomMenuStyles}
                >
                    {currentUser.client.buses.map((bus, index) => (
                        <MenuItem onClick={() => onClickDuplicateMissionToBus(bus)} key={index}>
                            {bus}
                        </MenuItem>
                    ))}
                </PopoverMenu>,
            );

        // List all buses in new popover & reallocate onClick
        if (flightNbTours && !isMissionTerminated)
            list.push(
                <PopoverMenu
                    renderButton={({ onClick }) => (
                        <MenuItem onClick={onClick}>
                            <FormattedMessage id={'stripFlight.menu.reallocate'} />
                        </MenuItem>
                    )}
                    menuProps={{
                        anchorOrigin: { vertical: 'top', horizontal: 'right' },
                        transformOrigin: { vertical: 'top', horizontal: 'left' },
                    }}
                    menuStyles={CustomMenuStyles}
                >
                    {currentUser.client.buses.map((bus, index) => (
                        <MenuItem onClick={() => onClickReallocateBus(bus)} key={index}>
                            {bus}
                        </MenuItem>
                    ))}
                </PopoverMenu>,
            );

        // Show nb of tours & allow + or - with a confirm button to call update route
        if (flightNbTours)
            list.push(
                <StripFlightMenuContentsToursMenuItem
                    tours={flightNbTours}
                    onConfirm={onConfirmUpdateMissionNbTours}
                    isMissionTerminated={isMissionTerminated}
                />,
            );

        // Unallocate onClick
        if (flightNbTours && !isMissionTerminated)
            list.push(
                <MenuItem onClick={onClickUnallocateMission}>
                    <FormattedMessage id={'stripFlight.menu.unallocate'} />
                </MenuItem>,
            );

        return list;
    }, [
        close,
        currentUser.client.buses,
        flightFlow,
        flightIsManual,
        flightNbTours,
        onClickAllocateBus,
        onClickDeleteMission,
        onClickDuplicateMissionToBus,
        onClickOpenSpecifyDropoffLocationDialog,
        onClickReallocateBus,
        onClickUnallocateMission,
        onConfirmUpdateMissionNbTours,
        isMissionTerminated,
    ]);

    return (
        <>
            {menuItems.map((item, i) => (
                <React.Fragment key={i}>{item}</React.Fragment>
            ))}
        </>
    );
});

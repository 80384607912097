import React, { memo, useCallback } from 'react';
import { ProfileType, useAuthorizeProfile } from '../../../hooks/useAuthorizeProfile';
import { useCurrentUser } from '../../../contexts/AuthContext.selectors';
import { StyledBusBox, StyledBusListBox, StyledDriverPageContainer } from './DriverPage.styles';
import { Box, styled, Tooltip, tooltipClasses, TooltipProps, Zoom } from '@mui/material';
import { ReactComponent as BusIcon } from '../../../assets/icons/icon-bus.svg';
import { useNavigate } from 'react-router-dom';
import { ROUTE_DRIVER_MISSIONS } from '../../../routes';
import { HeaderComponent } from '../../../components/HeaderComponent/HeaderComponent';
import { FormattedMessage } from 'react-intl';
import { useQueryGetGetAllBuses } from '../../../backend/gen';
import { toast } from 'react-toastify';
import { errorToastConfig } from '../../../utils/constants';
import { theme } from '../../../theme';

const StyledTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} />
))(() => ({
    [`& .${tooltipClasses.tooltip}`]: {
        color: 'black',
        backgroundColor: 'white',
        borderRadius: '5px',
        boxShadow: '0 3px 4px 0 rgba(0,0,0,0.14), 0 3px 3px -2px rgba(0,0,0,0.12), 0 1px 8px 0 rgba(0,0,0,0.2)',
        fontSize: '21px',
        fontWeight: 'bold',
        letterSpacing: 0,
        padding: '10px',
    },
    [`& .${tooltipClasses.arrow}`]: {
        color: theme.palette.arrival.main,
    },
}));

export const DriverPage = memo(function DriverPage() {
    const currentUser = useCurrentUser();
    const navigate = useNavigate();
    useAuthorizeProfile(currentUser, ProfileType.DRIVER);

    const navigateToMission = useCallback(
        (busName: string) => {
            navigate(ROUTE_DRIVER_MISSIONS.replace(':busNumber', busName));
        },
        [navigate],
    );

    const { data } = useQueryGetGetAllBuses({
        onError: () => toast(<FormattedMessage id={'bus.error.getBuses'} />, errorToastConfig),
        refetchInterval: 4000,
    });
    return (
        <>
            <HeaderComponent isDispatcher={false} />
            <StyledDriverPageContainer>
                <Box>
                    <FormattedMessage id="driverPage.selectBus" />
                </Box>
                <StyledBusListBox>
                    {data &&
                        data.map((bus, i) => (
                            <StyledTooltip
                                title={bus.unavailable ? <FormattedMessage id={'driverPage.busUnavailable'} /> : ''}
                                TransitionComponent={Zoom}
                                key={i}
                            >
                                <StyledBusBox
                                    className={bus.unavailable && 'unavailable'}
                                    onClick={() => (bus.unavailable ? null : navigateToMission(bus.name))}
                                >
                                    <BusIcon height={'60px'} width={'60px'} />
                                    {bus.name}
                                </StyledBusBox>
                            </StyledTooltip>
                        ))}
                </StyledBusListBox>
            </StyledDriverPageContainer>
        </>
    );
});

import { ReactComponent as BaggageIcon } from '../../../assets/icons/icon-bagages.svg';
import { ReactComponent as ControlledIcon } from '../../../assets/icons/icon-douane.svg';
import { Box, styled } from '@mui/material';

export enum LanguageMode {
    'English' = 'en',
    'French' = 'fr',
    'German' = 'ge',
}

export enum ArrivalInfoMode {
    'DEFAULT' = 'DEFAULT',
    'OVERSIZED' = 'OVERSIZED',
    'CONTROLLED' = 'CONTROLLED',
}

export enum DepartureInfoMode {
    'DEFAULT' = 'DEFAULT',
    'DELAYED' = 'DELAYED',
}

export const busDisplayTranslations = {
    arrivalControlled: {
        en: 'Passport control',
        fr: 'Contrôle passeports',
        ge: 'Passkontrolle',
    },
    arrivalOversized: {
        en: 'Oversized baggage',
        fr: 'Bagages hors format',
        ge: 'Sperrgepäck',
    },
    arrivalDefault: {
        en: 'Baggage claim',
        fr: 'Livraison bagages',
        ge: 'Gepäckausgabe',
    },
    departureTimeAtDestination: {
        en: 'Time at destination',
        fr: 'Heure à destination',
        ge: 'Ankunftszeit am Zielort',
    },
    departureFlightDelayed: {
        en: 'delayed',
        fr: 'retardé',
        ge: 'verzögert',
    },
};

export const generateArrivalInformation = ({
    controlled,
    oversizedBelt,
    belt,
    language,
}: {
    controlled?: boolean;
    oversizedBelt?: string;
    belt: string;
    language: string;
}) => {
    const StyledBelt = styled(Box)`
        font-weight: bold;
        font-size: 220px;
    `;

    const StyledOversized = styled(Box)`
        font-weight: bold;
        font-size: 90px;
    `;

    const StyledDescription = styled(Box)`
        font-size: 35px;
        letter-spacing: 3.5px;
    `;

    if (controlled) {
        return (
            <>
                <ControlledIcon />
                <StyledDescription>{busDisplayTranslations.arrivalControlled[language]}</StyledDescription>
            </>
        );
    } else if (oversizedBelt) {
        return (
            <>
                <StyledOversized>XL</StyledOversized>
                <BaggageIcon />
                <StyledDescription>{busDisplayTranslations.arrivalOversized[language]}</StyledDescription>
                <StyledBelt>{oversizedBelt}</StyledBelt>
            </>
        );
    } else {
        return (
            <>
                <BaggageIcon />
                <StyledDescription>{busDisplayTranslations.arrivalDefault[language]}</StyledDescription>
                <StyledBelt>{belt}</StyledBelt>
            </>
        );
    }
};

export const cycleObject = ({ obj, current, skipKeys }: { obj: Object; current: string; skipKeys?: Array<string> }) => {
    // Deep copy provided object to avoid referencing it
    const filteredObject = JSON.parse(JSON.stringify(obj));
    if (skipKeys && skipKeys.length > 0) {
        skipKeys.forEach(key => {
            delete filteredObject[key];
        });
    }
    const ObjectValues = Object.values(filteredObject);
    const ObjectKeys = Object.keys(filteredObject);
    const currentIndex = ObjectValues.findIndex(value => value === current);
    return filteredObject[ObjectKeys[(currentIndex + 1) % ObjectKeys.length]];
};

export const checkIfAirlineLogoExists = (airline: string, onExists: () => void, onError: () => void) => {
    const logo = new Image();
    logo.src = `images/airlines/${airline}.png`;
    logo.onload = onExists;
    logo.onerror = onError;
};

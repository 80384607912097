import React, { memo, useCallback } from 'react';
import {
    DriverMissionSlotDto,
    KeyGetGetNextMissions,
    useMutationPatchConfirmNextMissionStep,
    useMutationPatchDiscardCurrentMission,
    useMutationPatchUndoCurrentMissionStep,
} from '../../../../backend/gen';
import {
    StyledCurrentMissionContainer,
    StyledDiscardPopupContainer,
    StyledMissionContainer,
} from './DriverCurrentMission.style';
import { CurrentMissionFlightInfo } from './DriverCurrentMissionContents/CurrentMissionFlightInfo';
import { CurrentMissionSteps } from './DriverCurrentMissionContents/CurrentMissionSteps';
import { FormattedMessage } from 'react-intl';
import { useQueryClient } from 'react-query';
import { ReactComponent as DiscardPopup } from '../../../../assets/icons/close-popup.svg';
import { useConfirmDialog } from '../../../../components/confirm/useConfirmDialog';
import { toast } from 'react-toastify';
import { errorToastConfig } from '../../../../utils/constants';
import { DriverCurrentMissionControls } from './DriverCurrentMissionContents/DriverCurrentMissionControls';
import {
    driverDialogCancellationButtonProps,
    driverDialogConfirmationButtonProps,
    driverDialogDialogProps,
    driverDialogTitleProps,
} from '../../driver.utils';

export const DriverCurrentMission = memo(function DriverCurrentMission({
    driverCurrentMission,
    onClickSetSelectedMission,
}: {
    driverCurrentMission: DriverMissionSlotDto;
    onClickSetSelectedMission: (mission: DriverMissionSlotDto | null) => void;
}) {
    const queryClient = useQueryClient();
    // If at least one step is done and final step is not done, discard button will open discard dialog
    // else, discard button will simply set currently selected mission to null
    const discardDialogEnabled =
        driverCurrentMission.steps[0].done && !driverCurrentMission.steps[driverCurrentMission.steps.length - 1].done;

    const { mutateAsync: discardCurrentMission } = useMutationPatchDiscardCurrentMission();
    const openDiscardMissionDialog = useConfirmDialog();
    const onClickOpenDiscardMissionDialog = useCallback(() => {
        openDiscardMissionDialog({
            title: <FormattedMessage id={'currentMission.discard.title'} />,
            content: <FormattedMessage id={'currentMission.discard.content'} />,
            titleProps: driverDialogTitleProps,
            dialogProps: driverDialogDialogProps,
            cancellationButtonProps: driverDialogCancellationButtonProps,
            confirmationButtonProps: driverDialogConfirmationButtonProps,
        }).then(() =>
            discardCurrentMission({ mission: driverCurrentMission.missionId })
                .then(() => {
                    onClickSetSelectedMission(null);
                    queryClient.invalidateQueries(KeyGetGetNextMissions);
                })
                .catch(() => toast(<FormattedMessage id={'bus.error.currentMission.discard'} />, errorToastConfig)),
        );
    }, [
        discardCurrentMission,
        driverCurrentMission.missionId,
        onClickSetSelectedMission,
        openDiscardMissionDialog,
        queryClient,
    ]);

    const { mutateAsync: goToNextStep } = useMutationPatchConfirmNextMissionStep();
    const onClickGoToNextStep = useCallback(() => {
        goToNextStep({ mission: driverCurrentMission.missionId })
            .then(res => {
                if (res.data.steps[res.data.steps.length - 1].done) {
                    onClickSetSelectedMission(res.data);
                }
                queryClient.invalidateQueries(KeyGetGetNextMissions);
            })
            .catch(() => toast(<FormattedMessage id={'bus.error.currentMission.next'} />, errorToastConfig));
    }, [driverCurrentMission.missionId, goToNextStep, onClickSetSelectedMission, queryClient]);

    const { mutateAsync: goToPreviousStep } = useMutationPatchUndoCurrentMissionStep();
    const onClickGoToPreviousStep = useCallback(() => {
        goToPreviousStep({ mission: driverCurrentMission.missionId })
            .then(res => {
                if (!res.data.steps[0].done) {
                    onClickSetSelectedMission(res.data);
                }
                queryClient.invalidateQueries(KeyGetGetNextMissions);
            })
            .catch(() => toast(<FormattedMessage id={'bus.error.currentMission.previous'} />, errorToastConfig));
    }, [driverCurrentMission.missionId, goToPreviousStep, onClickSetSelectedMission, queryClient]);

    return (
        <StyledCurrentMissionContainer>
            <StyledDiscardPopupContainer>
                <DiscardPopup
                    width={'50px'}
                    height={'50px'}
                    stroke={'#ACACAC'}
                    onClick={
                        discardDialogEnabled ? onClickOpenDiscardMissionDialog : () => onClickSetSelectedMission(null)
                    }
                />
            </StyledDiscardPopupContainer>
            <StyledMissionContainer>
                <CurrentMissionFlightInfo driverCurrentMission={driverCurrentMission} />
                <CurrentMissionSteps driverCurrentMission={driverCurrentMission} />
            </StyledMissionContainer>

            <DriverCurrentMissionControls
                currentMissionSteps={driverCurrentMission.steps}
                onClickGoToNextStep={onClickGoToNextStep}
                onClickGoToPreviousStep={onClickGoToPreviousStep}
            />
        </StyledCurrentMissionContainer>
    );
});

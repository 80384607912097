import React, { memo, useState } from 'react';
import { DialogContent } from '@mui/material';
import { StyledDropoffFormHelperText, StyledDropoffFormInput } from './SpecifyDropoffLocationDialogContent.style';
import { useIntl } from 'react-intl';

export const SpecifyDropoffLocationDialogContent = memo(function SpecifyDropoffLocationDialogContent({
    dropoffLocation,
    setDropoffLocation,
}: {
    dropoffLocation?: string | null;
    setDropoffLocation: React.Dispatch<React.SetStateAction<string>>;
}) {
    const intl = useIntl();
    const maxChars = 45;
    const [charsRemaining, setCharsRemaining] = useState(
        dropoffLocation ? maxChars - dropoffLocation.length : maxChars,
    );

    return (
        <DialogContent>
            <StyledDropoffFormInput
                placeholder={intl.formatMessage({ id: 'stripFlight.menu.specifyDropOff' })}
                defaultValue={dropoffLocation}
                onChange={event => {
                    if (event.target.value.length <= maxChars) {
                        setDropoffLocation(event.target.value);
                        setCharsRemaining(maxChars - event.target.value.length || 0);
                    } else {
                        event.preventDefault();
                    }
                }}
                componentsProps={{
                    input: {
                        maxLength: maxChars,
                    },
                }}
                disableUnderline
            />
            <StyledDropoffFormHelperText>{`${charsRemaining} ${intl.formatMessage({
                id: 'specifyDropOffForm.charactersRemaining',
            })}`}</StyledDropoffFormHelperText>
        </DialogContent>
    );
});

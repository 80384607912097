import React, { memo } from 'react';
import { FormattedMessage } from 'react-intl';
import { GanttTimeline } from './GanttTimeline';
import { GanttGrid } from './GanttGrid';
import { DispatcherBusDto, DispatcherMissionSlotDto, FlightDto, FlightSlotDto } from '../../../../backend/gen';
import {
    GanttContentBox,
    GanttFlightRow,
    StyledFlightVerticalLine,
    StyledGanttContent,
    StyledGanttTitle,
} from './Gantt.style';
import { bounded, generateGanttFlightRows } from './gantt.utils';
import { Box } from '@mui/material';
import { StripFlight } from '../StripFlight/StripFlight';
import { ganttFlightRowHeight, ganttFlightRowMargin, ganttPxPerMs } from './gantt.constants';
import { HALF_HOUR } from '../../../../utils/constants';
import { CreateMissionForm } from '../CreateMissionForm/CreateMissionForm';
import { getFlowColor } from '../../../../utils/data.utils';
import { BusGanttMenu } from './BusGanttMenu/BusGanttMenu';
import { BusUnavailabilityPeriodDialog } from './BusUnavailabilityPeriodDialog/BusUnavailabilityPeriodDialog';
import { useBoolean } from '../../../../hooks/useBoolean';
import { BusUnavailabilityPeriodGanttRow } from './BusUnavailabilityPeriodGanttRow/BusUnavailabilityPeriodGanttRow';
import { useUiValue } from '../../../../contexts/UiContext';
import { BusDefineVacationDialog } from './BusDefineVacationDialog/BusDefineVacationDialog';
import { BusVacationGanttRow } from './BusVacationGanttRow/BusVacationGanttRow';

export const Gantt = memo(function Gantt({
    bus,
    titleBgColor,
    data,
    doNotNeedBus,
    from,
    to,
}: {
    bus?: DispatcherBusDto;
    titleBgColor: string;
    data: FlightSlotDto[] | DispatcherMissionSlotDto[];
    doNotNeedBus?: Array<FlightDto>;
    from: number;
    to: number;
}) {
    const isTactical = useUiValue('isTacticalView');
    const selectedVacationId = useUiValue('selectedVacationId');
    const rows = generateGanttFlightRows(data).filter(row => row.length > 0);
    const unavailabilityPeriodDialogOpen = useBoolean(false);
    const defineVacationDialogOpen = useBoolean(false);

    return (
        <>
            <StyledGanttTitle bgcolor={titleBgColor} sx={{ paddingTop: bus ? '60px' : '15px' }}>
                <span className="busName">{bus ? bus.name : <FormattedMessage id="unallocated" />}</span>
                {isTactical &&
                    (bus ? (
                        <>
                            <BusUnavailabilityPeriodDialog
                                bus={bus}
                                open={unavailabilityPeriodDialogOpen.value}
                                closeDialog={unavailabilityPeriodDialogOpen.setFalse}
                            />
                            <BusDefineVacationDialog
                                bus={bus}
                                open={defineVacationDialogOpen.value}
                                closeDialog={defineVacationDialogOpen.setFalse}
                                vacationId={selectedVacationId}
                            />
                            <BusGanttMenu
                                onClickOpenUnavailabilityPeriodDialog={unavailabilityPeriodDialogOpen.setTrue}
                                onClickOpenDefineVacationDialog={defineVacationDialogOpen.setTrue}
                            />
                        </>
                    ) : (
                        <CreateMissionForm data={doNotNeedBus} />
                    ))}
            </StyledGanttTitle>
            <StyledGanttContent>
                <GanttTimeline from={from} to={to} />
                <GanttContentBox>
                    <GanttGrid from={from} to={to} />
                    {bus?.unavailability && (
                        <BusUnavailabilityPeriodGanttRow
                            ganttFrom={from}
                            ganttTo={to}
                            unavailabilityStartTime={bus.unavailability.start}
                            unavailabilityEndTime={bus.unavailability.end}
                        />
                    )}
                    {rows?.length ? (
                        bus?.vacations?.map(vacation => (
                            <BusVacationGanttRow
                                key={vacation.id}
                                ganttFrom={from}
                                ganttTo={to}
                                vacation={vacation}
                                onClickOpenDefineVacationDialog={defineVacationDialogOpen.setTrue}
                            />
                        ))
                    ) : (
                        <GanttFlightRow>
                            {bus?.vacations?.map(vacation => (
                                <BusVacationGanttRow
                                    key={vacation.id}
                                    ganttFrom={from}
                                    ganttTo={to}
                                    vacation={vacation}
                                    onClickOpenDefineVacationDialog={defineVacationDialogOpen.setTrue}
                                />
                            ))}
                        </GanttFlightRow>
                    )}
                    {rows?.map((row, i) => (
                        <GanttFlightRow key={i}>
                            {row?.map((flight: FlightSlotDto | DispatcherMissionSlotDto, j: React.Key) => {
                                const boundedStartTime = bounded(flight.refTime, from - HALF_HOUR, to + HALF_HOUR);
                                const left = (boundedStartTime - from) * ganttPxPerMs;
                                const lineColor = getFlowColor(flight.flow);
                                // Vertical line element height must equal distance from top of GanttGrid
                                // all the way to StripFlight. The calculation for this distance is as follows:
                                // height of gantt grid (20) +
                                // space around rows * # of rows +1 to avoid multiply by 0 first row (25 * (i + 1)) +
                                // height of a single row multiplied by amount of rows (25 * i)
                                const lineHeight = 20 + ganttFlightRowMargin * (i + 1) + ganttFlightRowHeight * i;

                                return (
                                    <React.Fragment key={j}>
                                        <StyledFlightVerticalLine
                                            bgcolor={lineColor}
                                            height={lineHeight}
                                            left={`${left}px`}
                                        />
                                        <Box left={`${left}px`} position={'absolute'}>
                                            <StripFlight flight={flight} />
                                        </Box>
                                    </React.Fragment>
                                );
                            })}
                        </GanttFlightRow>
                    ))}
                </GanttContentBox>
            </StyledGanttContent>
        </>
    );
});

import { DispatcherMissionSlotDto, FlightSlotDto } from '../../../../backend/gen';
import { isWithinInterval } from 'date-fns';
import { ganttPxPerMs } from './gantt.constants';

export const bounded = (value: number, min: number, max: number) => Math.min(Math.max(value, min), max);

const isFlightOverlappingCurrentRowFlight = (flightTime: number, rowFlightTime: number) =>
    isWithinInterval(flightTime, {
        start: rowFlightTime,
        end: rowFlightTime + 175 / ganttPxPerMs,
    });

export const generateGanttFlightRows = (data: FlightSlotDto[] | DispatcherMissionSlotDto[]) => {
    const rows: Array<FlightSlotDto[] | DispatcherMissionSlotDto[]> = [
        [],
        [],
        [],
        [],
        [],
        [],
        [],
        [],
        [],
        [],
        [],
        [],
        [],
    ];

    if (!data) return [];

    for (const flight of data) {
        for (const row of rows) {
            let rowContainsOverlappingFlight = false;
            for (const rowF of row) {
                if (isFlightOverlappingCurrentRowFlight(flight.refTime, rowF.refTime)) {
                    rowContainsOverlappingFlight = true;
                    break;
                }
            }
            if (!rowContainsOverlappingFlight) {
                // @ts-ignore
                row.push(flight);
                break;
            }
        }
    }

    return rows;
};

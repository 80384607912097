// Custom dialog properties to be passed to useConfirmDialog calls in driver pages, mostly style changes
// to fit larger tablet resolution

import { dialogClasses, DialogProps } from '@mui/material/Dialog';
import { buttonClasses, dialogActionsClasses, dialogContentClasses } from '@mui/material';
import { ButtonProps } from '@mui/material/Button';

export const driverNextMissionsWidthInPx = 425;

export const driverDialogTitleProps = {
    color: '#082940',
    sx: { textAlign: 'center', fontFamily: 'Roboto', fontSize: '36px', lineHeight: '56px' },
};

export const driverDialogDialogProps: Omit<DialogProps, 'open'> = {
    sx: {
        [`& .${dialogClasses.paper}`]: {
            background: 'white',
            padding: '50px',
            height: 'fit-content',
            width: '920px',
            borderRadius: '20px',
            backgroundColor: '#FFFFFF',
            boxShadow: '0 3px 75px 0 rgba(0,0,0,0.5)',
            color: '#082940',
            fontFamily: 'Roboto',
            fontSize: '28px',
        },
        [`& .${dialogContentClasses.root}`]: {
            padding: '0 0 20px 0',
            textAlign: 'center',
            whiteSpace: 'pre-line',
        },
        [`& .${dialogActionsClasses.root}`]: {
            display: 'flex',
            justifyContent: 'center',
        },
    },
    disableEscapeKeyDown: true,
};

const buttonStyles = {
    borderRadius: '50px',
    padding: '20px 36px',
    letterSpacing: '0',
    textTransform: 'none',
    fontSize: '21px',
    lineHeight: '26px',
    whiteSpace: 'nowrap',
    [`&.${buttonClasses.text}`]: {
        border: '1px solid rgba(12, 69, 108, 0.5)',
    },
    [`&.${buttonClasses.outlined}`]: {
        color: theme => theme.palette.blue.lightest,
        borderColor: '#245f88',
    },
};

export const driverDialogCancellationButtonProps: ButtonProps = {
    size: 'medium',
    sx: { ...buttonStyles, color: '#082940' },
    variant: 'text',
};

export const driverDialogConfirmationButtonProps: ButtonProps = {
    size: 'medium',
    sx: { ...buttonStyles, marginLeft: '15px !important' },
    variant: 'contained',
    autoFocus: true,
};
